import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '@entities/dashboard/dashboard.service';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { CourseObjective } from '@shared/models/course-objective.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';

@Component({
  selector: 'eh-course-objectives',
  templateUrl: './course-objectives.component.html',
  styleUrls: ['./course-objectives.component.scss']
})
export class CourseObjectivesComponent extends SflBaseComponent implements OnInit {
  course: CourseObjective = new CourseObjective();
  constructor(
    public readonly dialogRef: MatDialogRef<CourseObjectivesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CourseObjective,
    protected readonly layoutUtilsService: LayoutUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.course = this.data;
    this.loading$.next(false);
  }

  onclickNext() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close();
  }

  isAllChecked() {
    if (!this.course || !this.course.objectives) { return }
    for (let i in this.course.objectives) {
      if (!this.course.objectives[i].isChecked) {
        return false;
      }
    }
    return true;
  }

  onError(error) {
    this.onError(error);
    this.loading$.next(false);
  }

}
