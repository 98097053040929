<div class="message">
  <div class="section-card mt-0 pb-0">
    <div class="chat-head p-3">
      <p class="user-name" *ngIf="selectedConversation">{{selectedConversation?.displayName}}</p>
      <ng-container *ngTemplateOutlet="selectAlly"></ng-container>
    </div>
    <div class="chat-box p-4 scroller" #chatBox [scrollTop]="chatBox.scrollHeight">
      <ng-container *isFetchingData="loading$">
        <div [ngClass]="isSender(message) ? 'chat-student' : 'chat-ally'" *ngFor="let message of messages; trackBy: trackById">
          <div class="chat-card d-flex mb-2">
            <div class="user pr-2 pt-2" *ngIf="!isSender(message)">
              <img [src]="message?.senderImageUrl" (error)="onImgError($event); onChatImgError(message)" class="avatar rounded-circle" alt="User profile picture">
              <span class="status online" userAvailability [userId]="message?.senderId"></span>
            </div>
            <div class="chat-info px-2 py-1 pr-4">
              <span class="user-name" *ngIf="!isSender(message)">{{message?.senderName}}</span>
              <span class="time" [ngClass]="{'pl-2': !isSender(message)}">{{message?.timestamp | chatdate}}</span>
              <p class="chat-message mb-0" [innerHTML]="message?.content | safeHTML"></p>
            </div>
            <div class="user pl-2 pt-2" *ngIf="isSender(message)">
              <img [src]="message?.senderImageUrl" (error)="onImgError($event); onChatImgError(message)" class="avatar rounded-circle" alt="User profile picture">
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="message-box">
      <div class="input-group">
        <textarea type="text" [disabled]="loading$ | async" class="form-control pl-4" placeholder="Start your typing here..." aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="message"
          (keyup.enter)="sendMessage()" (click)="updateReadFlagForChat()" tabIndex="-1" #sendChatMessageInput></textarea>
        <div class="input-group-append">
          <button class="send-message p-2" [disabled]="loading$ | async" (click)="sendMessage()" (keydown.enter)="sendMessage()">Send <fa-icon class="envelope" [icon]="['far', 'paper-plane']"></fa-icon></button>
          <button *ngIf="showCalendarBtn && !isFromQuickChat" class="btn btn-save btn-all confirm-session m-2" type="button" (click)="navigateToNewSchedule()">put confirmed session on
            calendar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectAlly>
  <div ngbDropdown #selectAllyDropdown="ngbDropdown" container="body" class="select-ally-wrapper">
    <a href="javascript:;" class="add" ngbDropdownToggle id="selectallyDropdown" *ngIf="showCalendarBtn" [ngClass]="{'mr-3': !showCalendarBtn}">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>
    </a>
    <div ngbDropdownMenu aria-labelledby="selectallyDropdown">
      <div class="p-3">
        <p>Add New Ally</p>
        <input type="search" class="form-control search-ally" id="allyName" placeholder="Enter Ally name here" (input)="onSearchAlly($event.target.value)">
        <div class="ally-list">
          <div class="ally-item" *ngFor="let ally of filteredAvailableAllies; index as allyUserIndex">
            <div class="ally-profile">
              <img [src]="ally?.profileImage" alt="Profile" (error)="onImgError($event)">
              <span>{{ally?.nickName ? ally?.nickName : ally?.firstName}}</span>
            </div>
            <ng-container *ngIf="allyUserIndex === addingAllyUserIndex">
              <ng-container *isFetchingData="addingAllyToChat$">
                <fa-icon [icon]="['far', 'comment']" (click)="$event.stopPropagation(); addAllyToChat(ally, selectAllyDropdown, allyUserIndex)"></fa-icon>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="allyUserIndex !== addingAllyUserIndex">
              <fa-icon [icon]="['far', 'comment']" (click)="$event.stopPropagation(); addAllyToChat(ally, selectAllyDropdown, allyUserIndex)"></fa-icon>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
