/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
import { Injectable } from '@angular/core';

import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * This service checks for new updates in the application.
 */
@Injectable({ providedIn: 'root' })
export class CheckUpdateService {
  constructor(public readonly updates: SwUpdate, public readonly snackBar: MatSnackBar) { }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser(event));

    if (this.updates.isEnabled) {
      this.updates.activateUpdate();
      interval(2000 * 60).subscribe(() => { // 2 minutes, check update every 2 minute
        this.updates.checkForUpdate().then();
      });
    }
  }

  // If there is an update, promt the user
  private promptUser(e): void {
    if (e.available) {
      // change the position and check style of this popup
      const snackBarRef = this.snackBar.open('A new version of the application is available.', 'Update', { horizontalPosition: 'left', panelClass: ['custom-update-prompt'] });
      snackBarRef.onAction().subscribe(() => window.location.reload(true));
    }
  }
}
