import { ElementRef, Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { AppConstants } from "@shared/constants";
import { CallStatus, VideoCallAnswer } from "@shared/models/video-call.model";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { PeerMessage } from '@entities/course-management/courses/practice-m2m/practice-m2m.model';

@Injectable({ providedIn: 'root' })
export class VideoService {
  // subjects to manage the video call
  private incomingVideoCall$: Subject<any> = new Subject();
  incomingVideoCall: Observable<any> = this.incomingVideoCall$.asObservable();

  // subjects to manage the status of user's response to video call
  private videoCallAnswerStatus$: BehaviorSubject<VideoCallAnswer> = new BehaviorSubject(null);
  videoCallAnswer: Observable<VideoCallAnswer> = this.videoCallAnswerStatus$.asObservable();

  private callStatus$: Subject<CallStatus> = new Subject();
  callStatus: Observable<CallStatus> = this.callStatus$.asObservable();

  private peerClosed$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  peerClosed: Observable<boolean> = this.peerClosed$.asObservable();

  private peerMuted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  peerMuted: Observable<boolean> = this.peerMuted$.asObservable();

  private participantJoined$: Subject<boolean> = new Subject();
  perticipantJoined: Observable<boolean> = this.participantJoined$.asObservable();

  // video related params begins
  currentCall;
  peer;
  currentConnetion;
  videoConfig = { video: true, audio: true };
  myStream;
  globalRemoteStream;
  peerServerConfig = environment.peerJsConfig;
  loggedOut = false;
  private audio = new Audio();

  // video related params ends
  constructor() { }

  /**
   * this transmits the new video call
   * @param call the video cal
   */
  triggerIncomingVideoCall(call) {
    this.incomingVideoCall$.next(call);
  }

  /**
   * @param answerStatus whether the call was accepted(true) or rejected(False)
   */
  answerVideoCall(answer: boolean, call) {
    this.videoCallAnswerStatus$.next({ answer, call });
    const peerMessage = {
      muted: false
    };
    this.sendMessageToParticipant(peerMessage);
    this.stopNotificationSound();
  }

  resetAnswerVideoCallSubject() {
    this.videoCallAnswerStatus$ = new BehaviorSubject(null);
    this.videoCallAnswer = this.videoCallAnswerStatus$.asObservable();
  }

  declineCall() {
    this.performVideoCleanup({ destroy: true });
    this.stopNotificationSound();
  }

  /**
   * returns the available media player from user's browser
   */
  get mediaPlayer() {
    const n = <any>navigator;
    const mediaDevice = n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia;
    return mediaDevice;
  }

  handleMediaError(error: DOMException) {
    //handle error here
    console.error({ error })
    switch (error.name) {
      case 'NotAllowedError':
        this.updateCallStatus(CallStatus.PERMISSION_DENIED);
        break;
      case 'NotReadableError':
        this.updateCallStatus(CallStatus.CAMERA_ALREADY_OCCUPIED);
        break;
    }
  }


  // clean up the media stream once the call is ended
  cleanUpForMediaStreams() {
    this.myStream?.getVideoTracks()[0]?.stop();
    this.myStream?.getAudioTracks()[0]?.stop();
    this.globalRemoteStream?.getVideoTracks()[0]?.stop();
    this.globalRemoteStream?.getAudioTracks()[0]?.stop();
    this.globalRemoteStream = null;
  }

  performVideoCleanup(videoParams?: { currentUserVideoRef?: ElementRef, participantVideoRef?: ElementRef, destroy?: boolean }) {
    const participantVideo = videoParams?.participantVideoRef?.nativeElement;
    const currentUserVideo = videoParams?.currentUserVideoRef?.nativeElement;
    this.cleanUpForMediaStreams();
    if (videoParams?.destroy) {
      this.peer?.destroy();
    }
    if (currentUserVideo) {
      currentUserVideo.srcObject = null;
    }
    if (participantVideo) {
      participantVideo.srcObject = null;
    }
    this.currentConnetion?.close();
    this.currentCall?.close();
  }

  updateCallStatus(status: CallStatus) {
    this.callStatus$.next(status);
  }

  initializeVideoStreamListener(videoParams: { call, participantVideoRef: ElementRef, currentUserVideoRef: ElementRef }) {
    const participantVideo = videoParams?.participantVideoRef?.nativeElement;
    videoParams.call?.on('stream', (remoteStream) => {
      this.globalRemoteStream = remoteStream;
      this.participantJoined$.next(true);
      if (participantVideo) {
        participantVideo.srcObject = remoteStream;
        const playRemotePromise = participantVideo.play();
        const peerMessage = {
          muted: false
        };
        this.sendMessageToParticipant(peerMessage);
        if (playRemotePromise !== undefined) {
          playRemotePromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
            .catch(error => {
              console.error({ error })
              // Auto-play was prevented
              // Show paused UI.
            });
        }
        this.updateCallStatus(CallStatus.IN_PROGRESS);
      }
    });
  }

  initializeDisconnectionListener(videoParams: { call }) {
    videoParams?.call?.on('close', (event) => {
      this.performVideoCleanup({ destroy: false });
    });

    this.peer?.on('close', (event) => {
      this.peerClosed$.next(true);
    });
  }

  initializeVideoConnectionListener(videoParams: { participantPeerId: string, currentUserVideoRef?: ElementRef, participantVideoRef?: ElementRef, options?, destroy?: boolean }) {
    this.currentConnetion = this.peer.connect(videoParams?.participantPeerId, videoParams?.options);
    let declined = false;
    this.peer.on('connection', (conn) => {
      conn.on('data', (data: PeerMessage) => {
        this.peerMuted$.next(data?.muted);
        if (data.declined) {
          this.callStatus$.next(CallStatus.DECLINED);
          declined = true;
        }
      });
    })
    this.currentConnetion?.on('close', () => {
      if (!declined) {
        this.callStatus$.next(CallStatus.DISCONNECTED);
      }
      this.performVideoCleanup({ currentUserVideoRef: videoParams.currentUserVideoRef, participantVideoRef: videoParams.participantVideoRef, destroy: videoParams.destroy })
    });
  }

  initializePeerErrorListener() {
    this.peer?.on('error', (err) => {
      switch (err?.type) {
        case 'disconnected':
          this.performVideoCleanup({ destroy: false });
          if (!this.loggedOut) {
            this.peerClosed$.next(true);
          }
          break;
        case 'peer-unavailable':
          this.callStatus$.next(CallStatus.PEER_OFFLINE);
          // TODO peer is unavailable. so send a notification when call is started. when the other person joins, trigger the call again.
          break;
        case 'unavailable-id':
          this.callStatus$.next(CallStatus.ID_TAKEN);
          // TODO, attempt reinitialization of peer here.
          break;
      }
      console.error({ err });
    });
  }

  playNotificationSound(isCaller?: boolean) {
    this.audio.src = AppConstants.NOTIFICATION_SOUND;
    this.audio.load();
    this.audio.loop = true;
    if (isCaller) {
      this.audio.volume = 0.1;
    }
    this.audio.play();
  }

  stopNotificationSound() {
    this.audio.pause();
  }

  // used to send message, utilizing this to show if peer is muted or not
  sendMessageToParticipant(peerMessage: PeerMessage) {
    this.currentConnetion?.send(peerMessage);
  }
}
