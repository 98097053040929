<ng-container *ngIf="components?.length">
<div class="available-course scroller p-4 tour-my-components">
  <h4>my components</h4>
  <ng-container *isFetchingData="loading$">
      <div class="component" *ngFor="let component of components">
        <div class="component-schedule mb-2">
          <div class="component-left-content">
            <img id="component" src="{{imagesURLs.component}}" alt="Progress" *ngIf="component.yetToStart && !component?.isContinue && !component?.completed">
            <img src="{{imagesURLs.greenCheckUrl}}" alt="Session" *ngIf="component.completed">
            <div class="progress" [attr.data-percentage]="getProgress(component?.progress)" *ngIf="component?.isContinue && !component?.completed">
              <span class="progress-left">
                <span class="progress-bar"></span>
              </span>
              <span class="progress-right">
                <span class="progress-bar"></span>
              </span>
              <div class="progress-value">
                <div>
                  {{component?.progress | number : '1.0-0'}}<span id="percent">%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="component-middle-content">
            <h6>
              Component {{component?.courseComponent?.sequence}}: {{component?.courseComponent?.name}}
            </h6>
          </div>
          <div class="component-right-content">
            <a href="javascript:void(0);" class="tour-schedule" (click)="navigateToSchedule(component?.courseComponent?.courseId, component?.courseComponent?.id)" id="schedule" data-toggle="tooltip" data-placement="top" title="Schedule a session
        with an Ally!" *ngIf="component?.courseComponent?.sessionTime" (mouseenter)="component.calenderFocus=true"
              (mouseleave)="component.calenderFocus=false">
              <img id="calendar-dark" src="{{imagesURLs.calendar}}" alt="Calendar" [hidden]="component.calenderFocus">
              <img id="calendar-light" src="{{imagesURLs.calendarLight}}" alt="Calendar" [hidden]="!component.calenderFocus">
            </a>
            <button class="btn btn-success btn-all" (click)="goClickNext(component)" *ngIf="component?.completed">
              completed
            </button>
            <button class="btn btn-primary btn-all tour-continue-component" *ngIf="(component?.isContinue && !component?.completed) || (!env?.sequentialComponent && !component?.completed)" (click)="goClickNext(component)">
              continue
            </button>

          </div>
        </div>
        <div class="view-my-feedback">
          <div class="feedback-link">
            <a id="feedback" href="javascript:;" *ngIf="component?.progress > 33" (click)="navigateToFeedbackStation(component?.courseComponent?.courseId, component?.courseComponent?.id)">view feedback <img [src]="imagesURLs.arrowNext" alt="Next"></a>
          </div>
          <div class="component-due-date" *ngIf="!component?.completed">
            <span [ngClass]="getTargetFontColor(component?.targetDateTime | dateToUsersTimezone : appConstants.dateFormatWithTZ) ? 'target-date-missed' : ''">
              <strong>Target: {{component?.targetDateTime | dateToUsersTimezone : appConstants.dateFormatWithTZ}}</strong>
            </span>
          </div>
        </div>
        <div class="component-progress" *ngIf="component.isContinue && !component?.completed && !component?.stepAheadComponent">
          <ng-container [ngTemplateOutlet]="componentProgressBars" [ngTemplateOutletContext]="{compActions: actionsOfInProgressComponent, component: component}"></ng-container>
        </div>
        <div class="component-progress" *ngIf="component.isContinue && !component?.completed && component?.stepAheadComponent">
          <ng-container [ngTemplateOutlet]="componentProgressBars" [ngTemplateOutletContext]="{compActions: actionsOfStepAheadComponent, component: component}"></ng-container>
        </div>
        <div class="video">
          <ng-container *ngIf="component?.pendingReviews?.length">
            <span><fa-icon class="nav-bell" [icon]="['far', 'comment-alt']"></fa-icon></span>
            <h6>Your feedback awaited for:</h6>
          </ng-container>
        </div>
        <div class="review-requests scroller">
          <ng-container *ngFor="let pendingReview of component?.pendingReviews; index as i first as f; trackBy:trackById">
            <div class="video p-0">
              <a href="javascript:;" id="view" (click)="navigateToReviewThisVideo(component, i)">submit feedback <img [src]="imagesURLs.arrowNext" alt="Next"></a>
            </div>
            <div class="video-content">
              <div class="video-left-content">
                <img [src]="pendingReview?.user?.imageUrl" (error)="onImgError($event)" [alt]="pendingReview?.user?.nickName ? pendingReview?.user?.nickName : pendingReview?.user?.firstName">
              </div>
              <div class="video-right-content">
                <h5>{{pendingReview?.user?.nickName ? pendingReview?.user?.nickName : pendingReview?.user?.firstName}} requests your feedback</h5><br>
                <ng-container *ngIf="component?.courseComponent?.practiceVideoSubmitType === practiceSubmitTypes.RECORD">
                  <ng-container *ngFor="let uploads of pendingReview?.practiceDocument?.uploadedDocumentURL; index as uploadIndex; trackBy:trackById">
                    <span class="pr-3" *ngIf="uploads?.length > 3600"><img [src]="imagesURLs.timeIconDark" alt="Time"> {{uploads?.length * 1000 | date:'hh:mm:ss':'UTC'}} </span>
                    <span *ngIf="uploads?.length < 3600"><img [src]="imagesURLs.timeIconDark" alt="Time"> {{uploads?.length * 1000 | date:'mm:ss':'UTC'}} </span>
                  </ng-container>
                </ng-container>
                <span><img [src]="imagesURLs.userIconDark" alt="User"> {{pendingReview?.user?.nickName ? pendingReview?.user?.nickName : pendingReview?.user?.firstName}}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
</div>
</ng-container>

<ng-template #componentProgressBars let-compActions="compActions" let-component="component">
  <div class="component-progress-content">
    <h6>component progress</h6>
  </div>
  <div class="learn-practice-apply">
    <ng-container *ngIf="compActions">
      <ng-container *ngFor="let action of compActions">
        <div [class]="action?.action | lowercase" [ngClass]="getStatusClass(component, action?.action === 'LEARN' ? appConstants.componentSteps.LEARN : action?.action === 'PRACTICE' ? appConstants.componentSteps.PRACTICE : action?.action === 'APPLY' ? appConstants.componentSteps.APPLY : '', compActions)">
          <span [id]="action?.action | lowercase">{{action?.action}}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
