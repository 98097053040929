import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrl } from "@shared/constants";
import { interval, Observable, Subscription } from "rxjs";
import { AppConstants } from "@shared/constants/app.constant";

@Injectable({ providedIn: 'root' })
export class UserAvailabilityService {

  refreshUserAvailabilitySubscription: Subscription = new Subscription();

  constructor(private readonly httpClient: HttpClient) { }

  changeUserAvailabilityStatus(online = true): Observable<void> {
    return this.httpClient.put<void>(`${ApiUrl.userStatus}/${online}`, null).pipe(res => {
      this.refreshUserAvailabilitySubscription = interval(AppConstants.USER_ONLINE_TIMER).subscribe(() => {
        this.httpClient.put<void>(`${ApiUrl.userStatus}/${online}`, null).subscribe();
      })
      return res;
    });
  }

}
