import { BaseCourseComponentDetail } from '@shared/models/course-component.model';
import { User } from '@shared/models/user.model';

export enum ComponentProgress {
  NONE = 'NONE',
  LEARN = 'LEARN',
  PRACTICE = 'PRACTICE',
  APPLY = 'APPLY'
}

export interface PracticeVideUpload {
  courseComponentId: number;
  courseComponentActionId: number;
  files?: Blob[];
  learnerId?: number;
  submittedOn: string;
}

export interface UploadedDocumentURL {
  attachmentName: string;
  attachmentPath: string;
  attachmentType: string;
  fileSize: number;
  id: number;
  practiceDocumentId: number;
  length: number;
  playing?: boolean;
}
export interface PracticeVideos {
  active: boolean;
  courseComponentId: number;
  id: number;
  isActive: boolean;
  learnerId: number;
  submittedOn: string;
  uploadedDocumentURL: UploadedDocumentURL[];
  reviewRequested: boolean;
  courseComponentActionId: number;
}

export interface PracticeVideosInsturctions {
  id: number,
  text: string,
  parentId: number,
  practiceSkillFeedbackId: number,
  sequence: number,
  courseComponentId: number,
  childList: PracticeVideosInsturctions[]
}

export interface FeedbackRequest {
  reviewerUserId: number,
  practiceDocumentId: number
  id?: number,
  reviewDateTime?: string,
  reviewed?: boolean
}


export interface UserProgress {
  progressParam: string;
  learnerCourseComponentId: number;
  userId?: number;
}

export interface PracticeDocument {
  id: number,
  submittedOn: string,
  isActive: boolean,
  learnerId: number,
  courseComponentId: number,
  uploadedDocumentURL: UploadedDocumentURL[],
  courseComponentActionId: number
}

interface Learner {
  id: number,
  learnerId: number,
  userId: number,
  firstName: string,
  lastName: string,
  email: string,
  imageUrl: string,
  nickName?: string;
}
export interface RequestedFeedbacks {
  id: number,
  reviewed: boolean,
  reviewDateTime: Date,
  requestedDateTime: string,
  allyId: number,
  practiceDocument: PracticeDocument,
  user: User,
  allowDocumentReview: boolean,
  reviewerUserId: number
}

export interface AlliesRequestedFeedback {
  id: number,
  reviewed: boolean,
  reviewDateTime: string,
  requestedDateTime: string,
  allyId: number,
  practiceDocument: PracticeDocument
  user: User,
  reviewerUserId: number
}

export interface GiveVideoFeedback {
  feedback: string,
  id?: number,
  practiceDocumentReviewId: number,
  submittedOn: string,
  time: number,
  commentedAtPretty: string
}

export interface GiveVideoRating {
  id?: number,
  practiceDocumentAllyId: number,
  practiceSkillRatingFeedbackQuestionId: number,
  rating: number
}

export interface ComponentResult {
  image: string,
  points: number,
  animationPath: string
}

export interface RiskEncouregement {
  id: number,
  text: string
  type: string,
  courseComponentId: number
}
export interface ActionList {
  id: number,
  action: string,
  actionType: string,
  sequence: number,
  optional: boolean,
  courseComponentId: number,
  actionCompleted: boolean
}
export interface ComponentActions {
  action: string,
  actionList: ActionList[]
}

export enum ComponentAvailableAction {
  LEARN = 'LEARN',
  PRACTICE = 'PRACTICE',
  APPLY = 'APPLY',
  NONE = 'NONE' // this will get all the above 3
}

export enum ComponentActionTyple {
  M2M = 'MEMBER_TO_MEMBER',
  VIDEO = 'VIDEO_RECORD',
  FILEUPLOAD = 'FILE_UPLOAD',
  FILLABLEFIELDS = 'FILLABLE_FIELDS'
}

export interface PracticeProgresses {
  completed: boolean,
  courseComponentActionId: number,
  courseComponentId: number
}

export interface RequestFeedbackModal {
  upload: UploadedDocumentURL,
  selectedPractice?: ActionList
}

export interface RequestFeedbackForFileUploadModal {
  upload: PracticeVideos,
  selectedPractice: ActionList
}

export enum CourseComponentSteps {
  LEARN = 'LEARN',
  PRACTICE = 'PRACTICE',
  APPLY = 'APPLY'
}
