import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatUser } from '@entities/course-management/courses/chat/chat.model';
import { ChatService } from '@entities/course-management/courses/chat/chat.service';
import { Learner, ProfileQuestion, User, UserProfile, UserRatings } from '@shared/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { first, share } from 'rxjs/operators';
import { ApiUrl } from '../../@shared/constants';
import { Availability, AvailableTimeZones } from './my-account.model';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  private user: User;
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private getImagesFor: number[] = [];
  userDataObservable: Observable<User>;

  constructor(private readonly http: HttpClient, private readonly chatService: ChatService) { }

  changePassword(data): Observable<HttpResponse<void>> {
    return this.http.post<HttpResponse<void>>(ApiUrl.changePassword, data);
  }

  getProfile(userId?: number): Observable<User> {
    if (userId) {
      return this.http.get<User>(`${ApiUrl.users}/${userId}`);
    }
    this.userDataObservable = this.http.get<User>(ApiUrl.getProfile).pipe(share());
    return this.userDataObservable;
  }

  async getProfileByLearnerId(learnerId: number): Promise<User> {
    const learner: Learner = await this.http.get<Learner>(`${ApiUrl.learner}/${learnerId}`).toPromise();
    if (learner) {
      return this.getProfile(learner.sflUserId).toPromise();
    }
  }

  updateProfile(user: User): Observable<User> {
    return this.http.put<User>(ApiUrl.updateProfile, user);
  }

  uploadProfilePic(file): Observable<string> {
    return this.http.post<string>(ApiUrl.uploadFile, file, { responseType: 'text' as 'json' });
  }

  setCurrentUser(user: User) {
    this.user = user;
    this.currentUser$.next(user);
  }

  getCurrentUserSubject(fromDb = false): Observable<User> {
    if (fromDb) {
      return this.getProfile();
    }
    return this.currentUser$.asObservable();
  }

  getCurrentUser(fromDb = false): User | Promise<User> {
    if (fromDb) {
      return new Promise(resolve => {
        this.getProfile().subscribe(res => resolve(res));
      });
    } else {
      return this.user || this.getCurrentUser(true);
    }
  }

  async updateUserOnFirebase(currentUser: User) {
    this.chatService.getCurrentUserFromFS(currentUser?.id).pipe(first()).subscribe(async userResponse => {
      if (userResponse) {
        const firebaseUser: ChatUser = userResponse[0];
        if (firebaseUser) {
          const chatUser: ChatUser = new ChatUser(currentUser);
          chatUser.conversations = firebaseUser.conversations;
          await this.chatService.updateUser(Object.assign({}, chatUser));
          this.chatService.currentUser = chatUser;
          this.chatService.updateConversationUser(chatUser);
        }
      }
    });
  }

  // this will update user's login attempt to true only, afterwards we will stop showing tour guide spotligth to the user
  updateUserLoginAttempt() {
    return this.http.put(ApiUrl.updateUserLoginAttempt, null);
  }

  getQuestions(): Observable<ProfileQuestion[]> {
    return this.http.get<ProfileQuestion[]>(ApiUrl.profileQuestions);
  }

  updateProfileQuestions(data: ProfileQuestion[]) {
    return this.http.post(ApiUrl.profileQuestionsSubmit, data);
  }

  getSelectedUserProfile(userId?: number): Observable<UserProfile> {
    const user = userId ? `?userId=${userId}` : '';
    return this.http.get<UserProfile>(`${ApiUrl.selectedUserProfile}${user}`);
  }

  // this will update user's profile updated flag to true
  updateUserProfileUpdated() {
    return this.http.put(ApiUrl.updateUserProfileUpdate, null);
  }

  getUserChatImage(id: number) {
    if (this.getImagesFor.indexOf(id) === -1) {
      this.getImagesFor.push(id);
      this.setUserImageToChat(id);
    }
  }

  setUserImageToChat(id: number) {
    this.getSelectedUserProfile(id).subscribe((res: UserProfile) => {
      this.updateUserOnFirebase(res?.userDetail);
    })
  }
  getSelectedUserRatings(userId?: number): Observable<UserRatings> {
    const user = userId ? `?userId=${userId}` : '';
    return this.http.get<UserRatings>(`${ApiUrl.learnerRatings}${user}`);
  }

  getAvailableTimeZones(): Observable<AvailableTimeZones[]> {
    return this.http.get<AvailableTimeZones[]>(`${ApiUrl.timeZonesList}`);
  }

  // save user's availability
  saveUsersAvailability(availabilityPreferences: Availability[]): Observable<Availability[]> {
    return this.http.post<Availability[]>(`${ApiUrl.usersAvailability}`, availabilityPreferences);
  }
  // get user's availability
  getUsersAvailability(): Observable<Availability[]> {
    return this.http.get<Availability[]>(`${ApiUrl.usersAvailability}`);
  }
}
