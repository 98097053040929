import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MyAccountModule } from '@entities/my-account/my-account.module';
import { environment } from '@environment/environment';
import { ThemeModule } from '@layout/theme/theme.module';
import { SflErrorHandler } from '@shared/error-handler/sfl-erro-handler';
import { UserAvailabilityService } from '@shared/services/user-availability.service';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { rxStompConfig } from './rx-stomp.config';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';

declare global {
  interface Window {
    dataLayer: any;
  }
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    ThemeModule,
    MyAccountModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableAutoUpdateCheck }),
    NgxHotjarModule.forRoot(environment.hotjarTrackingCode),
    NgxHotjarRouterModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SflErrorHandler },
    {
      provide: InjectableRxStompConfig,
      useValue: rxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [UserAvailabilityService],
      multi: true
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerCode }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
