<div class="container-fluid" *ngIf="componentId; else onDashboardSchedule">
  <h4 id="title">Scheduled Sessions with Your Allies</h4>
  <div class="section-card ally-session mt-4 mb-md-3">
    <div class="row">
      <div class="col-xl-4 col-lg-5 col-md-12 mb-md-4">
        <div class="schedule">
          <img src="assets/media/avatars/Ally.png" alt="User Profile Image" class="img-fluid">
          <div class="schedule-content">
            <h3>Reach Out to Your Allies</h3>
            <h6>Schedule {{component?.courseComponent?.sessionTime}} minutes with an Ally</h6>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 co-md-12">
        <div class="your-allies my-4">
          <h4>My schedule</h4>
          <button class="btn btn-save btn-all" (click)="navigateBack()">
            <fa-icon [icon]="['fas', 'user-friends']"></fa-icon> find available allies
          </button>
        </div>
        <ng-container *isFetchingData="loading$">
          <div class="schedule-wrapper scroller">
            <div class="session p-3" *ngFor="let schedule of schedules" [ngClass]="{'highlight-section': highlightId === schedule.id}">
              <div class="component-box">
                <div class="component">
                  <ng-container *ngIf="!componentId">
                    <img src="assets/media/icons/Calendar.svg" id="calendar" alt="Calendar">
                    <p id="component-name">{{getCourseName(schedule)}}</p>
                  </ng-container>
                </div>
                <div class="session-right-content">
                  <button class="btn btn-all" [ngClass]="startBtnClass(schedule)" [disabled]="isSubmitting" (click)="showRespectReminders(schedule)"> {{sessionActionBtn}} </button>
                </div>
              </div>
              <div class="participant">
                <div class="participant-left-content">
                  <p id="participant-title">participant:</p>
                  <div class="participant-mem">
                    <span *ngFor="let ally of schedule?.participantList">
                      <img [src]="ally?.imageUrl" alt="User Profile Image" (error)="onImgError($event)">
                      {{ally?.nickName ? ally?.nickName : ally?.firstName}}
                    </span>
                    <span>
                      <img [src]="schedule?.user?.imageUrl" alt="User Profile Image" (error)="onImgError($event)">
                      {{schedule?.user?.nickName ? schedule?.user?.nickName : schedule?.user?.firstName}}
                      <img id="initiator" src="assets/media/icons/Initiator.svg" alt="Initiator" data-toggle="tooltip" data-placement="top" title="Initiator">
                    </span>
                  </div>
                  <ng-container *ngIf="!schedule?.completed">
                    <div class="actions" *ngIf="!schedule?.cancelled">
                      <a href="javascript:;" (click)="cancelSchedule(schedule)">
                        <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
                        Cancel
                      </a>
                      <a href="javascript:;" (click)="rescheduleSession(schedule)">
                        <fa-icon [icon]="['far', 'clock']"></fa-icon>
                        Reschedule
                      </a>
                    </div>
                  </ng-container>
                  <div class="schedule-info">
                    <div *ngIf="schedule?.completed" class="date-time">
                      <p id="date-time-title">Completed On</p>
                      <p id="datetime">{{schedule?.completedOn | dateToUsersTimezone: appConstants.scheduleDateFormatWithZoneDateTime }} </p>
                    </div>
                    <div *ngIf="schedule?.cancelled" class="date-time">
                      <p id="date-time-title">Cancelled On</p>
                      <p id="datetime">{{schedule?.cancelledOn | dateToUsersTimezone: appConstants.scheduleDateFormatWithZoneDateTime }} </p>
                    </div>
                  </div>
                </div>
                <div class="participant-right-content">
                  <div class="date-time">
                    <p id="date-time-title">Scheduled On</p>
                    <p id="datetime">{{schedule?.scheduledFrom | dateToUsersTimezone: appConstants.scheduleDateFormatWithZoneDateTime }} ({{currentUser?.timeZone?.abbr}}) </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="skip-section text-right" *ngIf="!currentPractice?.actionCompleted && currentPractice?.optional">
    <button class="btn btn-save" (click)="skipPractice()">Skip This Practice</button>
  </div>
  <div class="next-prev">
    <a href="javascript:;" (click)="navigateBack()" class="previous" id="prev-screen">
      <fa-icon [icon]="['fas', 'angle-left']"></fa-icon> Practice with Allies
    </a>
    <a href="javascript:;" *ngIf="showApplyNavigation && ((isShowNextPractice && currentPractice?.actionCompleted) || (nextPractice && currentPractice?.optional))" (click)="showNextPractice()" class="next" id="next-screen" >Next Practice  <fa-icon [icon]="['fas', 'angle-right']"></fa-icon></a>
    <a href="javascript:;" *ngIf="!currentPractice || showApplyNavigation && (!isShowNextPractice && currentPractice?.actionCompleted)" class="next" id="next-screen" (click)="navigateToApply()">{{nextButtonText}} <fa-icon [icon]="['fas', 'angle-right']"></fa-icon></a>
    <div class="clearfix"></div>
  </div>
</div>

<ng-template #onDashboardSchedule>
  <div class="dashboard-schedule-wrapper tour-my-schedules">
    <div class="section-card ally-session">
      <h4 id="title">My Schedule</h4>
      <div class="row">
        <div class="col-xl-12 col-lg-12 co-md-12">
          <ng-container *isFetchingData="loading$">
            <div class="session-wrapper scroller" *ngIf="schedules?.length;else noSchedules">
              <div class="session p-tb-3" *ngFor="let schedule of schedules">
                <div class="component-box">
                  <div class="component">
                    <img src="assets/media/icons/Calendar.svg" id="calendar" alt="Calendar">
                    <p id="component-name">{{getCourseName(schedule)}}</p>
                  </div>
                  <div class="session-right-content">
                    <button class="btn btn-primary btn-all" [ngClass]="startBtnClass(schedule)" [disabled]="isSubmitting" (click)="showRespectReminders(schedule, schedule?.courseComponentId)">
                      {{sessionActionBtn}} </button>
                  </div>
                </div>
                <div class="participant">
                  <div class="participant-left-content">
                    <p id="participant-title">participant:</p>
                    <div class="participant-mem">
                      <span *ngFor="let ally of schedule?.participantList">
                        <img [src]="ally?.imageUrl" alt="User Profile Image" (error)="onImgError($event)">
                        {{ally?.nickName ? ally?.nickName : ally?.firstName}}
                      </span>
                      <span>
                        <img [src]="schedule?.user?.imageUrl" alt="User Profile Image" (error)="onImgError($event)">
                        {{schedule?.user?.nickName ? schedule?.user?.nickName : schedule?.user?.firstName}}
                        <img id="initiator" src="assets/media/icons/Initiator.svg" alt="Initiator" data-toggle="tooltip" data-placement="top" title="Initiator">
                      </span>
                    </div>
                    <div class="actions">
                      <a href="javascript:;" (click)="cancelSchedule(schedule)">
                        <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
                        Cancel
                      </a>
                      <a href="javascript:;" (click)="rescheduleSession(schedule, true)">
                        <fa-icon [icon]="['far', 'clock']"></fa-icon>
                        Reschedule
                      </a>
                    </div>
                  </div>
                  <div class="participant-right-content">
                    <div class="date-time">
                      <p id="date-time-title">Scheduled On</p>
                      <p id="datetime">{{schedule?.scheduledFrom | dateToUsersTimezone: appConstants.scheduleDateFormatWithZoneDateTime }} ({{currentUser?.timeZone?.abbr}})</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noSchedules>
              <div class="no-schedule text-center">
                <span><img src="assets/media/icons/Calendar.svg" width="30" id="calendar" alt="Calendar"></span>
                <span>Create an Ally meeting!</span>
                <span>Reach out to one of your Allies.</span>
                <span>Remember, you earn <strong>50 RISK POINTS</strong> each time you initiate an Ally practice session!</span>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
