<div class="d-flex flex-column-fluid flex-center mt-lg-0">
  <div class="login-form login-signin container">
    <div class="eh-login-box card">
      <div class="card-body">
        <form class="form" [formGroup]="changePasswordForm" autocomplete="off" novalidate="novalidate" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="form-label">Current Password</label>
            <input type="password" formControlName="password" class="form-control custom" placeholder="Enter your current password" sflPasswordEye required />
            <app-form-error [validation]="'required'" [form]="changePasswordForm" [controlName]="'password'" [fieldLabel]="'Current Password'"></app-form-error>
          </div>
          <div class="form-group">
            <label class="form-label">New Password</label>
            <input type="password" formControlName="newpassword" class="form-control custom" placeholder="Enter new password" sflPasswordEye required />
            <app-form-error [validation]="'required'" [form]="changePasswordForm" [controlName]="'newpassword'" [fieldLabel]="'New Password'"></app-form-error>
            <small *ngIf="isControlHasError(changePasswordForm, 'newpassword', 'pattern')" class="form-text text-danger" [innerHTML]="appMessages.passwordSuggestion"></small>
          </div>
          <div class="form-group">
            <label class="form-label">Confirm Password</label>
            <input type="password" formControlName="confirmpassword" class="form-control custom" placeholder="Enter confirm password" sflPasswordEye required />
            <app-form-error [validation]="'required'" [form]="changePasswordForm" [controlName]="'confirmpassword'" [fieldLabel]="'Confirm Password'"></app-form-error>
            <small *ngIf="isControlHasError(changePasswordForm, 'confirmpassword', 'notSame')" class="form-text text-danger">
              New and confirm password must be same
            </small>
          </div>
          <div class="form-group">
            <button type="submit" name="submit" class="btn btn-primary btn-all cust-btn float-right" [isSubmitting]="isSubmitting">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
