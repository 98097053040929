import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html'
})
export class FormErrorComponent extends SflBaseComponent {

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() validation: string;
  @Input() fieldLabel: string;
  @Input() length?: string;
  constructor() {
    super();
  }

  // used to set the error message for forms
  get errorMessage(): string {
    switch (this.validation) {
      case 'required':
        return `${this.fieldLabel} is required`;
      case 'minlength':
        return `${this.fieldLabel} must be at least ${this.length || '5'} characters in length`;
      case 'maxlength':
        return `${this.fieldLabel} cannot be more than ${this.length || '255'} characters in length`;
      case 'email':
        return `${this.fieldLabel} is invalid`;
      case 'pattern':
        return `${this.fieldLabel}`;
    }
  }


}
