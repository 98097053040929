<div class="video-notification-wrapper" *ngIf="isNewVideoComing">
  <fa-icon class="close-notification" [icon]="['fas', 'times']" (click)="isNewVideoComing = false"></fa-icon>
  <div class="container-fluid notification-bar">
    <div class="notif-header">
      <span>Incoming M2M Call</span>
    </div>
    <div class="notif-body">
      <span class="text-center">{{newCallMsg}}</span>
    </div>
    <div class="notif-footer">
      <div class="btn-wrapper">
        <button class="btn btn-save join" (click)="joinCall()">Join</button>
        <button class="btn btn-danger" (click)="declineCall()">Decline</button>
      </div>
    </div>
  </div>
</div>
