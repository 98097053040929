<div class="start-course-instaructions">
  <div class="header">
    <h3>{{course?.title}}</h3>
    <mat-icon (click)="onClose()">clear</mat-icon>
  </div>
  <hr />
  <div class="content" *isFetchingData="loading$">
    <h3>{{course?.subTitle}}</h3>
    <div class="custom-control custom-checkbox cust-checkbox" *ngFor="let objective of course?.objectives">
      <input type="checkbox" (change)="objective.isChecked=!objective.isChecked" [checked]="objective?.isChecked" class="custom-control-input" id="{{objective?.id}}">
      <label class="custom-control-label" for="{{objective?.id}}">
        {{objective?.text}}
      </label>
    </div>
  </div>
  <div>
    <div class="footer">
      <button type="button" name="submit" class="btn btn-primary cust-btn-outline" (click)="onclickNext()" [disabled]="!isAllChecked()">I'M ALL IN</button>
    </div>
  </div>
</div>
