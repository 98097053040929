/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AppConstants, ErrorMessages } from '../constants';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AlertType } from '@shared/models/alert-type.enum';
import { AuthService } from '@auth/auth.service';
import { LayoutUtilsService } from './layout-utils.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private readonly authService: AuthService, protected readonly layoutUtilsService: LayoutUtilsService) { }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req || !req.url || (/^http/.test(req.url) && !(environment.serverUrl && req.url.startsWith(environment.serverUrl)))) {
      return next.handle(req);
    }
    const authToken = localStorage.getItem(AppConstants.authenticationToken) || sessionStorage.getItem(AppConstants.authenticationToken);
    let authRequest;
    if (authToken) {
      authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    } else {
      authRequest = req.clone();
    }
    authRequest = authRequest.clone({
      url: environment.serverUrl + authRequest.url
    });
    return next
      .handle(authRequest)
      .pipe(
        tap((event: HttpEvent<string>) => {
          this.errorFunction(event);
        }, (error) => {
          return throwError(error);
        }),
        catchError((error) => {
          if (error.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
          } else {
            if (error.status === 0) {
              this.layoutUtilsService.showActionNotification(ErrorMessages.serverNotAvailable, AlertType.Error);
            } else {
              /*
                Generic error from backend
                {
                    private message: string;
                    private applicationStatusCode: number;
                    private httpStatus: number;
                }
              */
              console.error(error.error.message);
              !error.error.message.includes('recipient_token_entity') && this.layoutUtilsService.showActionNotification(error.error.message, AlertType.Error);
              if (error.error.httpStatus === 401) {
                localStorage.removeItem(AppConstants.authenticationToken);
                this.authService.logout();
              }
            }
          }
          return throwError(error);
        })
      );
  }
  errorFunction(event) {
    if (event instanceof HttpResponse) {
      return event;
    }
  }
}
