import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AlertType } from '@shared/models/alert-type.enum';
import { ProfileAnswer, ProfileQuestion, User, UserProfile } from '@shared/models/user.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-profile-questions',
  templateUrl: './profile-questions.component.html',
  styleUrls: ['./profile-questions.component.scss']
})
export class ProfileQuestionsComponent extends SflBaseComponent implements OnInit {

  minAnsRequiredError = false;
  currentUser: User = new User();
  questions: ProfileQuestion[] = [];
  preSelected: ProfileQuestion[] = [];
  newSelected: ProfileQuestion[] = [];
  profileQuestionFormArray: FormArray = new FormArray([]);
  retrieving = true;

  constructor(private readonly myAccountService: MyAccountService,
    protected readonly layoutUtilsService: LayoutUtilsService,
    private readonly router: Router) {
      super();
    }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.myAccountService.getCurrentUser();
    this.getAllQuestions();
  }

  getAllQuestions() {
    this.subscriptionManager.add(
      this.myAccountService.getSelectedUserProfile().subscribe((res: UserProfile) => {
        this.questions = res.profileAnswers;
        this.preSelected = this.questions.filter(q => q?.answer !== null);
        this.newSelected = [...this.preSelected];
        this.prepareProfileQuestions(this.preSelected);
        this.loading$.next(false);
        this.retrieving = false;
      })
    );
  }
  async prepareProfileQuestions(selectedQuestions: ProfileQuestion[]) {
    await this.resetTheFormGroup(selectedQuestions);
    this.preSelected = [...selectedQuestions];
    for (const question of selectedQuestions) {
      if (!this.checkIfQuestionAlreadyExist(question)) {
        this.initQuestionForm(question);
      }
    }
  }

  resetTheFormGroup(selectedQuestions): Promise<void> {
    return new Promise(resolve => {
      const differenceOfQuestion = this.preSelected.filter(x => selectedQuestions.indexOf(x) === -1);
      for (const que of differenceOfQuestion) {
        for (const control of this.profileQuestionFormArray?.controls) {
          if (control?.get('userProfileQuestionId')?.value === que?.userProfileQuestionId) {
            const indexToBeRemoved = this.profileQuestionFormArray?.controls?.indexOf(control);
            this.profileQuestionFormArray?.removeAt(indexToBeRemoved);
            this.preSelected?.splice(indexToBeRemoved, 1)
          }
        }
      }
      resolve();
    });
  }

  // used to check if the selected questions are already in the formArray then skip adding those
  checkIfQuestionAlreadyExist(selectedQuestions: ProfileQuestion): boolean {
    let exists = false;
    for (const control of this.profileQuestionFormArray?.controls) {
      if (control?.get('userProfileQuestionId')?.value === selectedQuestions?.userProfileQuestionId) {
        exists = true;
        break;
      }
    }
    return exists;
  }

  // create a form for profile question answers
  initQuestionForm(proQuestion: ProfileQuestion) {
    const profileQuestionFormGroup: FormGroup = new FormGroup({
      'answer': new FormControl(proQuestion?.answer),
      'question': new FormControl(proQuestion?.question),
      'userProfileQuestionId': new FormControl(proQuestion?.userProfileQuestionId),
    });
    this.profileQuestionFormArray.push(profileQuestionFormGroup);
  }

  getFormArrayByQuestionId(userProfileQuestionId: number) {
    return this.profileQuestionFormArray.controls.filter(formControl => formControl.get('userProfileQuestionId').value === userProfileQuestionId);
  }

  // save users profile questions
  saveProfileQuestions() {
    this.isSubmitting = true;
    // check if at least 4 answer have been given
    const questionsList = this.validateGivenAnswer()
    if (questionsList?.length >= 4) {
      this.minAnsRequiredError = false;
      this.subscriptionManager.add(
        this.myAccountService.updateProfileQuestions(questionsList).subscribe((res: ProfileQuestion[]) => {
          this.isSubmitting = false;
          this.minAnsRequiredError = false;
          this.layoutUtilsService.showActionNotification(this.appMessages.updateProfileSuccess, AlertType.Success);
          this.updateProfileUpdate();
        }, () => this.isSubmitting = false)
      );
    } else {
      this.minAnsRequiredError = true;
      this.isSubmitting = false;
    }
  }
  validateGivenAnswer(): ProfileAnswer[] {
    const questionsList: ProfileAnswer[] = [];
    for (const [index, questionSelectedToAnswer] of this.profileQuestionFormArray?.controls?.entries()) {
      if (questionSelectedToAnswer?.get('answer')?.value) {
        questionsList.push(this.profileQuestionFormArray?.value[index]);
      }
    }
    return questionsList;
  }

  // update user's profile updated flag
  updateProfileUpdate() {
    if (!this.currentUser?.profileUpdated) {
      this.subscriptionManager.add(this.myAccountService.updateUserProfileUpdated().subscribe(() => {
        this.currentUser.profileUpdated = true;
        this.gotoDashboard();
      }));
    } else {
      this.gotoDashboard();
    }
  }
  // redirect user to dashboard once they're done with saving their profile questions
  gotoDashboard() {
    this.router.navigate([this.appRoutes.DASHBOARD]);
  }
}
