import { AppConstants } from '@shared/constants';
import { UserAvailabilityService } from '@shared/services/user-availability.service';

export function appInitializer(userAvailabilityService: UserAvailabilityService) {
  return () => new Promise<void>(resolve => {
    const token = localStorage.getItem(AppConstants.authenticationToken);
    if (token) {
      userAvailabilityService.changeUserAvailabilityStatus().subscribe().add(resolve);
    } else {
      resolve();
    }
  });
}
