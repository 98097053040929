import { AvailableTimeZones } from '@entities/my-account/my-account.model';

export class User {
  public email: string;
  public firstName: string;
  public id: number;
  public userId: number;
  public authorities?: string[] = [];
  public imageUrl?: string;
  public lastName?: string;
  public phoneNumber?: string;
  public status?: string;
  public userName?: string;
  timeZoneId?: string;
  timeZone?: AvailableTimeZones;
  loginAttempted?: boolean;
  public nickName?: string;
  public position?: string;
  public profileUpdated?: boolean;
}

export interface Learner {
  id: number;
  sflUserId: number;
  respectRatingScore: number;
}

export interface ProfileQuestion {
  id: number,
  question: string,
  answer: string,
  userProfileQuestionId: number
}

export interface ProfileSidePanel {
  currentAppUser: User
}

export interface UserProfile {
  profileAnswers: ProfileAnswer[],
  userDetail: User
}

export interface ProfileAnswer {
  answer: string,
  id: number,
  question: string,
  userProfileQuestionId: number
}

export interface Ratings {
  averageSkillRating : number,
  averageRespectRating : number
}

export interface UserOnGoingCourseComponent {
  courseName : string,
  courseSequence : number,
  componentName : string,
  componentSequence : number
}

export interface UserRatings {
  ratings : Ratings,
  earnedPoints : number,
  onGoingCourseComponents : UserOnGoingCourseComponent[]
}
