import { ElementRef, Injectable } from "@angular/core";
import { MyAccountService } from "@entities/my-account/my-account.service";
import { AppConstants } from "@shared/constants";
import { debounceTime } from "rxjs/operators";
import { Util } from "../utils";
import { VideoService } from "./video.service";
declare var Peer: any;
@Injectable({ providedIn: 'root' })
export class VideoListenerService {
  constructor(private readonly videoService: VideoService, private readonly accountService: MyAccountService) {
    this.videoService.peerClosed.pipe(debounceTime(200)).subscribe(res => {
      if (res) {
        this.initializePeer(true);
      }
    });
  }

  /**
   * This initializes the peer on app startup.
   */
  async initializePeer(initializeDisconnectionListener = false) {
    if (!this.videoService.peer?.open) {
      const authToken = localStorage.getItem(AppConstants.authenticationToken) || sessionStorage.getItem(AppConstants.authenticationToken);
      if (authToken) {
        const currentUser = await this.accountService.getCurrentUser(true);
        this.videoService.peer = new Peer(Util.GetPeerId(currentUser?.id), this.videoService.peerServerConfig);
        this.videoService.initializePeerErrorListener();
        this.videoService.peer.on('open', () => {
          if (initializeDisconnectionListener) {
            this.videoService.initializeDisconnectionListener(null);
          }
        });
        this.initializeVideoCallListener();
      }
    }
  }

  /**
   * This method initiates the call listener. whenever a new call is received, this method is required.
   * The call is then emitted using a subject to show prompt.
   * This method is for the reciever.
   */
  initializeVideoCallListener() {
    this.videoService.peer.on('call', (call) => {
      this.videoService.currentCall = call;
      this.videoService.triggerIncomingVideoCall(this.videoService.currentCall);
    });
  }

  /**
   * This accepts the video call. It sets the steaming and automatically plays the video.
   * This also enables the steaming event listener and steam disconnection listener.
   * @param videoParams contains the initiated call, participant and current video template reference.
   */
  acceptVideoCall(videoParams: { call, participantVideoRef: ElementRef, currentUserVideoRef: ElementRef }) {
    const currentUserVideo = videoParams.currentUserVideoRef.nativeElement;
    let n = <any>navigator;
    n.getUserMedia = this.videoService.mediaPlayer;
    this.videoService.cleanUpForMediaStreams();
    n.getUserMedia(this.videoService.videoConfig, (stream) => {
      videoParams.call.answer(stream);
      this.videoService.myStream = stream;
      currentUserVideo.srcObject = stream;
      const playRemotePromise = currentUserVideo.play();
      if (playRemotePromise !== undefined) {
        playRemotePromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
          .catch(error => {
            console.error({ error });
            // Auto-play was prevented
            // Show paused UI.
          });
      }
      this.videoService.initializeVideoStreamListener(videoParams);
      this.videoService.initializeDisconnectionListener({ call: videoParams.call });
    }, (error) => {
      console.error('accept video call media error', error);
      this.videoService.handleMediaError(error);
    })
  }

  declineCall() {
    this.videoService.declineCall();
  }
}
