import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AppConstants, APP_ROUTES } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem(AppConstants.authenticationToken)) {
      return true;
    }
    const url = location.href.split(location.host)[1] === '/' ? APP_ROUTES.LOGIN : `${APP_ROUTES.LOGIN}?returnUrl=${encodeURIComponent(location.href.split(location.host)[1])}`;
    this.router.navigateByUrl(url);
    return false;
  }

}
