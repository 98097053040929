import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { User } from '@shared/models/user.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent extends SflBaseComponent implements OnInit {

  termsOfUseAccept: boolean;
  proceed = false;
  currentUser: User = new User();
  constructor(public readonly dialogRef: MatDialogRef<TermsOfUseComponent>, protected readonly layoutUtilsService: LayoutUtilsService, private readonly accountService: MyAccountService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.accountService.getCurrentUser(true);
    this.loading$.next(false);
  }

  proceedToApplication() {
    this.proceed = true;
    if (!this.termsOfUseAccept && !this.currentUser?.loginAttempted) {
      return;
    }
    // accepted proceed to application now.
    this.dialogRef.close(true);
  }
}
