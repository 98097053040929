/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
export class ApiUrl {
  public static readonly login: string = '/authenticate';
  public static readonly account: string = '/account';
  public static readonly users: string = '/sfl-users';
  public static readonly register = '/register';
  public static readonly forgetPassword = {
    init: '/account/reset-password/init',
    finish: '/account/reset-password/finish'
  };
  public static readonly changePassword: string = '/account/change-password';
  public static readonly getProfile: string = '/account';
  public static readonly updateProfile: string = '/sfl-users';
  public static readonly uploadFile: string = '/upload-file';
  public static readonly myOngoingCourses: string = '/learner-course';
  public static readonly courseObjectives: string = '/component-objective/course-component';
  public static readonly myGroup: string = '/learner-group';
  public static readonly myTeam: string = '/learner-team';
  public static readonly learnReEnforcementMessages: string = '/course-components/:id/reinforcement-message';
  public static readonly quizByComponentId: string = '/quiz-by-course-component-id';
  public static readonly saveLearnerQuiz: string = '/save-learner-quiz';
  public static readonly learnerCourseComponent: string = '/learner-course-components/course-components';
  public static readonly courseComponent: string = '/course-components';
  public static readonly component: string = '/learner-course-components/components';
  public static readonly updateVideoProgress: string = '/video-progress';
  public static readonly courseProgress: string = '/learner-course-components/progress';
  public static readonly learnerPracticeSessions: string = '/learner-practice-sessions';
  public static readonly practiceSession: string = '/practice-sessions';
  public static readonly practiceSessionProgress: string = '/practice-session/progress';
  public static readonly respectReminders: string = '/practice-respect-reminder-messages';
  public static readonly firebaseToken: string = '/sfl/util/notification/recipient';
  public static readonly feedbackForAllyTemplate: string = '/practice-respect-rating-feedback-questions/course-component/';
  public static readonly submitAllyFeedback: string = '/practice-respect-rating-feedback-answers/submit';
  public static readonly uploadPracticeVideo: string = '/practice-documents/upload';
  public static readonly getUploadVideosForComponent: string = '/practice-documents/components/';
  public static readonly getUploadVideosInstuctions: string = '/practice-instructions/component/';
  public static readonly practiceDocumentById: string = '/practice-documents/';
  public static readonly practiceVideoFeedbackRequest: string = '/practice-document-review/review-request';
  public static readonly getMyRespectAndSkillsFeedback: string = '/component';
  public static readonly m2mInstructions: string = '/practice/instructions/skill-rating/practice-sessions';
  public static readonly m2mFeedback: string = '/practice-skill-rating-feedback-questions/component';
  public static readonly m2mFeedbackSubmit: string = '/practice-skill-rating-feedback-answers/submit';
  public static readonly feedbackSubmitTypes: string = '/input-type-by-category/SELECTIVE';
  public static readonly userProgress: string = '/user-progress';
  public static readonly getRequestedFeedback: string = '/practice-document-review';
  public static readonly getListOfAlliesRequestedForFeedback: string = '/practice-document-review/practice-document';
  public static readonly applyRiskOptions: string = '/apply-risks/course-component';
  public static readonly applyRiskQuestions: string = '/apply-risk';
  public static readonly applyRiskQuestionAnswers: string = '/apply-risk/apply-risk-question-answer-users';
  public static readonly submitRequestedRatingFeedback: string = '/practice-document-skill-rating-feedback-answers';
  public static readonly submitRequestedFeedback: string = '/practice-document-review-feedback/submit';
  public static readonly learner: string = '/learners';
  public static readonly feedbackStationFeedbackByComponentPracticeType: string = '/feedback-stations/components';
  public static readonly availableScheduleSlots = '/practice-sessions/available-slot';
  public static readonly scheduleSession = '/practice-session/schedule';
  public static readonly rescheduleSession = '/practice-sessions';
  public static readonly usersRiskPoints = '/learners/risks';
  public static readonly userAverageRating = '/user/average-rating';
  public static readonly userStatus = '/account/update-user-status';
  public static readonly userStatusSocket = '/user/user-status/online';
  public static readonly updateUserLoginAttempt = '/account/update-login-attempt/';
  public static readonly updateUserProfileUpdate = '/account/update-profile-updated/';
  public static readonly isUserAllowedToStartSession = '/practice-sessions/allow-session-start';
  public static readonly getRiskEncouregementForComponent = '/risk-encouragements/course-component';
  public static readonly getCourseComponentActions = '/learner-course-components-actions/component';
  public static readonly getNotificationCounts = '/sfl/util/notification/count';
  public static readonly markNotificationRead = '/sfl/util/notification/read';
  public static readonly getAllNotification = '/sfl/util/notification/history';
  public static readonly getLearnerCourseComponentActions = '/learner-course-components-actions/course';
  public static readonly getFillablePractice = '/practice-fillable/component';
  public static readonly saveFillablePractice = '/practice-fillable-answers/submit';
  public static readonly learnerPracticeProgresses = '/learner-practice-progresses';
  public static readonly profileQuestions = '/user-profile-questions';
  public static readonly profileQuestionsSubmit = '/user-profile-question-answers/submit';
  public static readonly selectedUserProfile = '/user-profile';
  public static readonly chackUserCanReview = '/practice-documents/allow-document-review';
  public static readonly chackUserCanReviewM2M = '/practice-sessions/allow-session-start';
  public static readonly learnerRatings = '/learner/rating-with-progress';
  public static readonly canUserStartComponent = '/learner-course-components/allow-start/course'; // optionally takes `?action=APPLY` to check if user can access the apply or not
  public static readonly riskStationRiskLevels = '/risk-levels'; // first card
  public static readonly riskStationCards = '/risk-level-cards'; // second and third cards
  public static readonly getNextORPreviousComponentDetails = '/learner-course-component/course/'; // course id:1 /component-sequence/ 15
  public static readonly giveAllyGoldenEggPoints = '/give-user-golden-points'; // takes courseComponentId and userId
  public static readonly getCountOfGivenGoldenEggPoints = '/given-golden-points/count/course/'; // takes CourseId
  public static readonly successStorySubmitTypes = '/general-categories/SUCCESS_STORY';
  public static readonly successStoryInstructions = '/input-type-by-category/SUCCESS_STORY';
  public static readonly submitSuccessStory = '/user-success-stories/submit';
  public static readonly mySubmittedStories = '/user-success-stories';
  public static readonly uploadSuccessVideo = '/attachments/upload'; // ?length=2&uploadFolderName=successStory
  public static readonly timeZonesList = '/time-zones';
  public static readonly cashInPoints = '/cash-in-point-items/group-by-points';
  public static readonly redeemPoints = '/spend-earned-points'; // ?cashInPointItemId={id}
  public static readonly cashInPointsCategoryDetails = '/input-type-by-category/CASH_IN_POINT';
  public static readonly practiceSessionStatusByUser = '/practice-session-status'; // ?courseComponentId=2&userId=46
  public static readonly usersAvailability = '/user-time-availabilities';
}
