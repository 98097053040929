import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AppConstants } from '@shared/constants/app.constant';
import { TimezoneNoticeService } from '@shared/components/timezone-notice/timezone-notice.service';
@Pipe({
  name: 'dateToUsersTimezone'
})

/**
 * @whatitdoes it accepts date in UTC and converts it to the user's timezone which is set in their profile
 * @howtouse  <p>{{today | dateToUsersTimezone: 'hh:mm A'}}</p>
  * @brief     by default browser will convert the date to user's local, so we need to override that behaviour
 * @returns   :string time converted to the user's timezone
 */

export class DateToUsersTimezonePipe implements PipeTransform {
  constructor(private readonly timeZoneNoticeService: TimezoneNoticeService) {
    this.timeZoneNoticeService.timezoneChanged$.subscribe(res => {
      // localStorage.setItem('timezone', res);
    });
  }
  transform(date: Date | string, timeFormat: string, timeZone?: string): string {
    let localStorageTimeZone = localStorage.getItem('timezone');
    timeZone = timeZone || localStorageTimeZone || AppConstants.DEFAULT_TIME_ZONE;
    if (date) {
      return moment(date).tz(timeZone).format(timeFormat);
    }
    return '';
  }
}
