// Angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AppConstants } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'eh-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent extends SflBaseComponent implements OnInit {

  changePasswordForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly myAccountService: MyAccountService,
    private readonly chRef: ChangeDetectorRef,
    private readonly router: Router,
    protected readonly layoutUtilsService: LayoutUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.compose([
          Validators.required
        ])
      ],
      newpassword: ['', Validators.compose([
          Validators.required,
          Validators.minLength(AppConstants.passwordMinLength),
          Validators.maxLength(AppConstants.passwordMaxLength),
          Validators.pattern(AppConstants.passwordStrengthPattern)
        ])
      ],
      confirmpassword: ['', Validators.compose([
          Validators.required
        ])
      ]
    }, {validator: this.checkPasswords('newpassword', 'confirmpassword') });
  }

  onSubmit() {
    const controls = this.changePasswordForm.controls;
    if (this.changePasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.isSubmitting = true;

    const changePasswordData = {
      currentPassword: controls.password.value,
      newPassword: controls.newpassword.value
    }

    this.subscriptionManager.add(this.myAccountService.changePassword(changePasswordData).subscribe(res => {
      this.isSubmitting = false;
      this.router.navigate([this.appRoutes.LOGIN]);
    }, (error) => this.handleError(error)));

  }

  handleError(error) {
    this.onError(error);
    this.isSubmitting = false;
    this.chRef.detectChanges();
  }

  checkPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.notSame) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ notSame: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
