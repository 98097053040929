// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { ChatService } from '@entities/course-management/courses/chat/chat.service';
import { InboxService } from '@entities/inbox/inbox.service';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { AppConstants } from '@shared/constants';
import { User } from '@shared/models/user.model';
import { CheckUpdateService } from '@shared/services/check-update.service';
import { MessagingService } from '@shared/services/messaging.service';
import { SocketUtilsService } from '@shared/services/socket-utils.service';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { Subscription } from 'rxjs/internal/Subscription';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'eHumanize';
  loader: boolean;
  currentUser: User;
  private readonly unsubscribe: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly messagingService: MessagingService,
    private readonly accountService: MyAccountService,
    private readonly swPush: SwPush,
    private readonly chatService: ChatService,
    private readonly socketUtilsService: SocketUtilsService,
    private readonly checkUpdateService: CheckUpdateService,
    private readonly inboxService: InboxService,
    private readonly gtmService: GoogleTagManagerService) {
  }
  async ngOnInit() {
    // check for updates
    this.checkUpdateService.checkForUpdates();
    this.initializeNotificationMessageListener();
    this.initializeUserAvailabilityListener();
    this.initializeUserLoginGTMEvent();
    const routerSubscription = this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        // send GA page view data
        // let customFields = { user_id: this.currentUser ? this.currentUser.id : undefined }
        // this.$gaService.pageView(event.urlAfterRedirects, undefined, undefined, customFields)
        const gtmTag = {
          event: 'page',
          pageName: event.url
        };
        this.gtmService.pushTag(gtmTag);
        // scroll to top on every route change
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, AppConstants.setTimeOut);
        if (localStorage.getItem(AppConstants.authenticationToken)) {
          const currentUser = await this.accountService.getCurrentUser();
          await this.chatService.setCurrentUser(currentUser);
        }
      }
    });
    this.unsubscribe.push(routerSubscription);
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  async initializeNotificationMessageListener() {
    this.unsubscribe.push(this.accountService.getCurrentUserSubject().subscribe(async currentUser => {
      this.currentUser = currentUser;
      this.messagingService.requestPermission(currentUser?.id);
      this.messagingService.receiveMessage();
      this.messagingService.currentMessage.subscribe(res => {
        if (res) {
          console.log('message recieved', res);
        }
      });
    }));
    if (this.swPush.isEnabled) {
      navigator.serviceWorker
        .ready
        .then((serviceWorker) => {
          firebase.messaging().getToken({ serviceWorkerRegistration: serviceWorker });
        });
      this.swPush.messages.subscribe(msg => {
        console.log('push message', msg);
        this.inboxService.getNotificationCount(this.currentUser.id, true);
      });
      this.swPush.notificationClicks.subscribe(click => console.log('notification click', click));
    }
  }

  async initializeUserAvailabilityListener() {
    this.socketUtilsService.listenToOtherUsersAvailability();
  }

  // adding GTM if user closes the window and visit the application again this will trigger the GTM to track the user
  async initializeUserLoginGTMEvent() {
    const currentUser = await this.accountService.getCurrentUser();
    if (localStorage.getItem(AppConstants.authenticationToken) && currentUser) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'login',
        'userId' : currentUser.id //this number must be replaced with a dynamic actual User ID
      });
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
