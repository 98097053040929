import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationPrompt } from "./confirmation-prompt.model";

@Component({
  selector: 'app-confirmation-prompt',
  templateUrl: './confirmation-prompt.component.html',
  styleUrls: ['confirmation-prompt.component.scss']
})
export class ConfirmationPromptComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<ConfirmationPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationPrompt
  ) { }

  onclickNext() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close();
  }
}
