<div [ngClass]="!isFromQuickChat ? 'container-fluid' : 'quick-chat'">
  <ng-container *ngIf="!isFromQuickChat">
    <input (click)="toggle()" class="showHideUser" id="menu__toggle" type="checkbox" />
    <label class="menu__btn chat-ally-toggle" for="menu__toggle">
      <span></span>
    </label>
  </ng-container>
  <div class="chat-screen" [ngClass]="{'chat-screen-full my-4': !isFromQuickChat}">
    <ng-container *ngIf="showUserList">
      <div class="users" id="userList">
        <div class="section-card p-3 mt-0">
          <ng-container *isFetchingData="loadingChatUsers$">
            <div *ngFor="let conversation of currentUserConversations">
              <div class="select-user" *ngIf="conversation?.lastReceipientMsg || conversation?.chatId === selectedConversation?.chatId"
                [ngClass]="{'selected': conversation?.chatId === selectedConversation?.chatId}">
                <a href="javascript:;" class="p-2 pr-4" (click)="selectConversation(conversation)" [title]="conversation?.displayName">
                  <img [src]="conversation?.imageUrl" class="avatar rounded-circle" alt="DP" (error)="onImgError($event); onChatImgError(conversation)">
                  <span class="status away" userAvailability [userId]="conversation?.id"></span>
                  <span class="user-name pl-2">{{conversation?.displayName}}</span>
                </a>
                <span class="badge badge-primary badge-pill" *ngIf="conversation?.hasUnreadMsg">New</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <app-chat-messages class="w-100" [selectedAllies]="finalGroupTeamChatMembers" (refreshUserChatList)="onRefreshChat()" [isFromQuickChat]="isFromQuickChat"
      [currentUserConversations]="currentUserConversations" [currentAppUser]="currentAppUser">
    </app-chat-messages>
  </div>
  <div class="next-prev" *ngIf="!isFromQuickChat">
    <a href="javascript:;" class="previous" id="prev-screen" (click)="navigateToPracticeWithAllies()">
      <fa-icon [icon]="['fas', 'angle-left']"></fa-icon> Practice with your allies
    </a>
    <a href="javascript:;" class="next" id="next-screen" (click)="navigateToMySchedules()">my schedule
      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
    </a>
    <div class="clearfix"></div>
  </div>
</div>
