import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { Course } from '@shared/models/course.model';


@Component({
  selector: 'eh-ongoing-courses',
  templateUrl: './ongoing-courses.component.html',
  styleUrls: ['./ongoing-courses.component.scss']
})
export class OngoingCoursesComponent extends SflBaseComponent implements OnInit {
  @Input() courses: Array<Course>;
  @Output() onNext: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  getComponentStep(course) {
    if (course && course.currentCourseComponent && course?.currentCourseComponent?.actionComingUp) {
      return this.appConstants.componentSteps[course?.currentCourseComponent?.actionComingUp].display;
    }
    return this.appConstants.componentSteps.LEARN.display;
  }
  getCoursePercent(course) {
    if(course) {
      return parseInt(course.courseCompletedComponents) / parseInt(course.courseTotalComponents) * 100;
    }
    return 0;
  }

  onClickNext(course) {
    this.onNext.emit(course);
  }

}
