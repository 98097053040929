import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrl } from "@shared/constants";
import { Observable } from "rxjs";
import { Schedule, ScheduleProgressUpdateParmas } from "./schedule.model";

@Injectable({ providedIn: 'root' })
export class ScheduleService {
  constructor(private readonly http: HttpClient) { }

  getSchedules(courseComponentId?, componentActionId?): Observable<Schedule[]> {
    if (courseComponentId && componentActionId) {
      return this.http.get<Schedule[]>(ApiUrl.learnerPracticeSessions, { params: { courseComponentId, componentActionId } })
    }
    return this.http.get<Schedule[]>(ApiUrl.learnerPracticeSessions)
  }

  getSchedule(scheduleId: number): Observable<Schedule> {
    return this.http.get<Schedule>(`${ApiUrl.practiceSession}/${scheduleId}`);
  }

  updateScheduleProgress(schedule: ScheduleProgressUpdateParmas): Observable<void> {
    return this.http.put<void>(ApiUrl.practiceSessionProgress, schedule);
  }

  isUserAllowedToStartSession(scheduleId: number): Observable<boolean> {
    return this.http.get<boolean>(`${ApiUrl.isUserAllowedToStartSession}/${scheduleId}`);
  }
}
