import { User } from '@shared/models/user.model';

export interface Schedule {
  participantList: Learner[],
  cancelled: boolean,
  cancelledOn: Date,
  completed: boolean,
  completedOn: Date,
  courseComponentId: number,
  courseComponentName: string,
  courseComponentSequence: number,
  duration: number,
  id: number,
  user: User,
  scheduledFrom: Date,
  startedOn: Date,
  allowSessionStart: boolean
  respectRatingSubmitted: boolean,
  courseComponentActionId: number
}

export interface Learner {
  email: string,
  firstName: string,
  id: number,
  imageUrl: string,
  lastName: string,
  learnerId: number,
  userId: number,
  nickName?: string
}

export enum SessionAction {
  START = 'START',
  END = 'END',
  CANCEL = 'CANCEL'
}

export interface ScheduleProgressUpdateParmas {
  sessionAction: SessionAction,
  sessionId: number
}
