import { NgModule } from "@angular/core";
import { ChatComponent } from "@entities/course-management/courses/chat/chat.component";
import { ChatMessageComponent } from "./components/chat-message/chat-message.component";
import { ChatPanelComponent } from "./components/sidebar-panel/chat-panel/chat-panel.component";
import { SharedModule } from "./shared.module";

@NgModule({
  declarations: [
    ChatMessageComponent,
    ChatComponent,
    ChatPanelComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    ChatMessageComponent,
    ChatComponent,
    ChatPanelComponent
  ]
})
export class ChatModule { }
