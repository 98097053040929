import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from '@shared/constants';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { MyAccountComponent } from './my-account.component';
import { ProfileQuestionsComponent } from './profile-questions/profile-questions.component';
import { SetAvailabilityComponent } from './set-availability/set-availability.component';


const routes: Routes = [
  {
    path: '',
    component: MyAccountComponent,
    children: [
      {
        path: APP_ROUTES.CHANGE_PASSWORD,
        component: ChangePasswordComponent,
      },
      {
        path: APP_ROUTES.EDIT_PROFILE,
        component: EditProfileComponent,
      },
      {
        path: APP_ROUTES.PROFILE_QUESTION,
        component: ProfileQuestionsComponent,
      },
      {
        path: APP_ROUTES.SET_AVAILABILITY,
        component: SetAvailabilityComponent,
      },
      { path: '', redirectTo: APP_ROUTES.EDIT_PROFILE, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule { }
