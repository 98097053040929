import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AppMessages } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { Availability, AvailabilityForm, TimeSlots, WeekDays } from '../my-account.model';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-set-availability',
  templateUrl: './set-availability.component.html',
  styleUrls: ['./set-availability.component.scss']
})

export class SetAvailabilityComponent extends SflBaseComponent implements OnInit {

  availableWeekDays: WeekDays[] = [
    { abbr: 'M', day: 'MONDAY', selected: false },
    { abbr: 'T', day: 'TUESDAY', selected: false },
    { abbr: 'W', day: 'WEDNESDAY', selected: false },
    { abbr: 'T', day: 'THURSDAY', selected: false },
    { abbr: 'F', day: 'FRIDAY', selected: false },
    { abbr: 'S', day: 'SATURDAY', selected: false },
    { abbr: 'S', day: 'SUNDAY', selected: false }
  ];

  selectedDays: string[] = [];
  selectedUnAvailableDays: string[] = [];
  savingAvailability = false;
  allowUnavailability = false;

  myAvailabilityForm: FormGroup;
  usersAvailability: Availability[];
  sortedUsersAvailability: Availability[][][] = [];
  constructor(private fb: FormBuilder, private readonly accountService: MyAccountService, protected readonly layoutUtilsService: LayoutUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.myAvailabilityForm = this.fb.group({
      availabilities: this.fb.array([], Validators.required),
    });

    // get users availability
    this.subscriptionManager.add(this.accountService.getUsersAvailability().subscribe((usersAvailabilityResponse: Availability[]) => {
      this.usersAvailability = usersAvailabilityResponse;
      this.segregateAvailability();
    }));
  }

  multipleGroupByArray(array, f) {
    const groups = {};
    array.forEach(function (o) {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  };

  segregateAvailability() {
    const groupBySlotGroup = this.multipleGroupByArray(this.usersAvailability, (property) => [property.slotGroupId]);
    const finalGroup = [];
    for (const groups of groupBySlotGroup) {
      const groupByTime = this.multipleGroupByArray(groups, (property) => [property.timeFrom, property.timeTo]);
      finalGroup.push(groupByTime);
    }
    this.sortedUsersAvailability = finalGroup;
    this.prePopulateForm();
  }

  prePopulateForm() {
    for (const items of this.sortedUsersAvailability) {
      const temp: AvailabilityForm[] = [];
      for (const item of this.populateUiBlocks(items)) {
        // making selection to true for opted days
        const weekDays = JSON.parse(JSON.stringify(item?.availableDays));
        item.day.forEach(optedDays => item?.availableDays.filter(days => {
          if (days.day === optedDays) {
            days.selected = true;
          }
        }));
        this.availabilitiesFormArr.push(
          this.fb.group({
            days: this.fb.array(item.day, Validators.required),
            timeSlots: this.fb.array(this.populateAvailableTimesFormGroup(item.timeSlots), Validators.required),
            availableDuringThisDayTime: new FormControl(item.availableDuringThisDayTime),
            availableDays: this.fb.array(item?.availableDays),
            slotGroupId: new FormControl(item.slotGroupId),
          })
        );
      }
    }
    this.loading$.next(false);
  }

  // get me day wise time slots from the group
  populateUiBlocks(availability: Availability[][]): AvailabilityForm[] {
    const avail: AvailabilityForm[] = [];
    let availForm: AvailabilityForm = new AvailabilityForm();
    availForm.day = this.daysGetterFromGroup(availability[0]);
    availForm.availableDuringThisDayTime = availability[0][0].available;
    availForm.slotGroupId = availability[0][0].slotGroupId;
    availForm.availableDays = JSON.parse(JSON.stringify(this.availableWeekDays));
    for (const slots of availability) {
      availForm.timeSlots.push(this.timeSlotsGetterFromGroup(slots));
    }
    avail.push(availForm);
    return avail;
  }

  // extracts all selected days from group
  daysGetterFromGroup(slots: Availability[]): string[] {
    let availDays: string[] = [];
    for (const days of slots) {
      availDays.push(days.day);
    }
    return availDays;
  }
  // extracts all selected timeslots from group
  timeSlotsGetterFromGroup(slots: Availability[]): TimeSlots {
    return {
      fromTime: this.getHourMinuteFromTime(slots[0].timeFrom),
      toTime: this.getHourMinuteFromTime(slots[0].timeTo),
    };
  }

  // get me hours/ minute saperately from the time string i.e. '11:00'
  getHourMinuteFromTime(time: string) {
    const splittedTime = time.split(':');
    return { hour: Number(splittedTime[0]), minute: Number(splittedTime[1]) };
  }

  get availabilitiesFormArr(): FormArray {
    return this.myAvailabilityForm.get('availabilities') as FormArray;
  }

  addAvailabilities(availableDuringThisDayTime) {
    this.availabilitiesFormArr.push(
      this.fb.group({
        days: this.fb.array([], Validators.required),
        timeSlots: this.fb.array([this.createAvailableTimesFormGroup()], Validators.required),
        availableDuringThisDayTime: new FormControl(availableDuringThisDayTime),
        availableDays: this.fb.array(JSON.parse(JSON.stringify(this.availableWeekDays))),
        slotGroupId: new FormControl(this.availabilitiesFormArr?.length + 1)
      })
    );
  }

  // used to remove entire availability block
  deleteAvailability(index) {
    this.availabilitiesFormArr.removeAt(index);
  }
  // used to remove specific time slot from availabilty block
  deleteTimeSlot(control, index) {
    control.removeAt(index);
  }

  // event gets triggered when user make any day selection/deselection
  onSelectingDay(event, index, control, day, type: string) {
    if (event.target.checked) {
      control.push(new FormControl(event.target.value));
      day.selected = true;
    } else {
      day.selected = false;
      for (const [i, contr] of control.controls.entries()) {
        if (contr.value === event.target.value) {
          control.removeAt(i);
          return;
        }
      }
    }
  }

  // used to create array of formGroup of all the timeslots given from the time group
  private populateAvailableTimesFormGroup(timeSlots?: TimeSlots[]): FormGroup[] {
    const timeSlotsFormGroup: FormGroup[] = [];
    if (timeSlots?.length) {
      for (const timeSlot of timeSlots) {
        const fromTimeObject = {
          hour: 0,
          minute: 0
        };
        const toTimeObject = {
          hour: 0,
          minute: 0
        };;
        fromTimeObject.hour = timeSlot?.fromTime?.hour;
        fromTimeObject.minute = timeSlot?.fromTime?.minute;

        toTimeObject.hour = timeSlot?.toTime?.hour;
        toTimeObject.minute = timeSlot?.toTime?.minute;

        const timeSlotFormGroup: FormGroup = new FormGroup({
          'fromTime': new FormControl({ hour: fromTimeObject.hour, minute: fromTimeObject.minute }),
          'toTime': new FormControl({ hour: toTimeObject.hour, minute: toTimeObject.minute })
        }, [Validators.required, this.timeFormGroupValidator.bind(this)]);
        timeSlotsFormGroup.push(timeSlotFormGroup);
      }
      return timeSlotsFormGroup;
    }
  }

  // used to append new time slot to availability block
  private createAvailableTimesFormGroup(): FormGroup {
    return new FormGroup({
      'fromTime': new FormControl({ hour: 11, minute: 0 }),
      'toTime': new FormControl({ hour: 15, minute: 0 })
    }, [Validators.required, this.timeFormGroupValidator.bind(this)]);
  }

  // event handler to add new time slot
  public addTimeSlotFormGroup(control) {
    control.push(this.createAvailableTimesFormGroup());
  }

  // used to submit and save user's preferences for h/her availability
  submit() {
    if (this.myAvailabilityForm?.valid) {
      this.isSubmitting = true;
      this.subscriptionManager.add(this.accountService.saveUsersAvailability(this.prepareAvailabilitySaveRequest).subscribe((availabilityResponse) => {
        this.isSubmitting = false;
        this.layoutUtilsService.showActionNotification(AppMessages.availabilitySavedSuccess, AlertType.Success)
      }, ()=> this.isSubmitting = false));
    }
  }

  // used to prepare the final request DTO for submission
  get prepareAvailabilitySaveRequest(): Availability[] {
    const availabilitySelection: Availability[] = [];
    for (const control of this.availabilitiesFormArr.controls) {
      for (const selectedDays of control['controls'].days.value) {
        for (const timeSlotsList of control['controls']?.timeSlots?.value) {
          let tempAvail: Availability = new Availability();
          tempAvail.timeFrom = this.getHours(timeSlotsList?.fromTime?.hour) + ':' + this.getMinutes(timeSlotsList?.fromTime?.minute);
          tempAvail.timeTo = this.getHours(timeSlotsList?.toTime?.hour) + ':' + this.getMinutes(timeSlotsList?.toTime?.minute);
          tempAvail.day = selectedDays;
          tempAvail.available = control['controls']?.availableDuringThisDayTime?.value;
          tempAvail.slotGroupId = control['controls']?.slotGroupId?.value;
          availabilitySelection.push(tempAvail);
        }
      }
    }
    return availabilitySelection;
  }

  // used to return string of hour  from a time slot as backend expect it to be a string i.e. '01' and not just 1
  getHours(hours): string {
    if (hours < 10) {
      return '0' + hours;
    }
    return hours;
  }
  // used to return string of minute  from a time slot as backend expect it to be a string i.e. '01' and not just 1
  getMinutes(minutes): string {
    if (minutes < 10) {
      return '0' + minutes;
    }
    return minutes;
  }

  // custom validation for from and to time
  timeFormGroupValidator(formGroup: FormGroup) {
    let isValid = true;
    if (formGroup.controls) {
      const fromHour = formGroup.controls['fromTime'].value?.hour;
      const toHour = formGroup.controls['toTime'].value?.hour;
      const fromMinute = formGroup.controls['fromTime'].value?.minute;
      const toMinute = formGroup.controls['toTime'].value?.minute;
      if (fromHour > toHour) {
        isValid = false;
      } else if (fromHour === toHour) {
        if (fromMinute >= toMinute) {
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }
    return isValid ? null : { timeInvalid: true };
  }
}
