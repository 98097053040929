import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '@shared/shared.module';
import { UserProfileSidebarComponent } from './user-profile-sidebar.component';



@NgModule({
  declarations: [UserProfileSidebarComponent],
  imports: [
    SharedModule,
    MatCardModule
  ],
  exports: [UserProfileSidebarComponent]
})
export class UserProfileSidebarModule { }
