<div class="user-sidebar scroller">
  <h5>User Profile</h5>
  <button (click)="sibarPanelClose()" class="close">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </button>
  <div class="user-profile-section">
    <div class="row">
      <div class="user-img col-3 d-flex align-items-center justify-content-center">
        <img class="avatar rounded-circle" [src]="userProfile?.userDetail?.imageUrl" (error)="onImgError($event)" alt="user-img" />
      </div>
      <div class="user-profile col-9 row">
        <mat-card>
          <div class="col-12 no-padding">
            <label class="m-r-5">Name: </label>
            <span *ngIf="userProfile?.userDetail?.nickName">{{userProfile?.userDetail?.nickName}}</span>
            <span *ngIf="!userProfile?.userDetail?.nickName">{{userProfile?.userDetail?.firstName}} {{userProfile?.userDetail?.lastName}}</span>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="tab-wrapper">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs">
      <li [ngbNavItem]="1" [destroyOnHide]="true">
        <a ngbNavLink>Profile Answers</a>
        <ng-template ngbNavContent>
          <div class="user-profile-section">
            <div class="profile-question-wrapper" *isFetchingData="loading$">
              <div class="user-questions row" *ngIf="userProfile?.profileAnswers?.length; else noProfileAnswers">
                <div class="col-12">
                  <div *ngFor="let answer of userProfile?.profileAnswers">
                    <mat-card *ngIf="answer?.answer">
                      <label class="q-label col-12 no-padding"><span class="f-w-600">Q: </span>{{answer?.question}}</label>
                      <span class="col-12 no-padding"><span class="f-w-600">A: </span> {{answer?.answer}}</span>
                    </mat-card>
                  </div>
                </div>
              </div>
              <ng-template #noProfileAnswers>
                <h6>{{userProfile?.userDetail?.nickName ? userProfile?.userDetail?.nickName : userProfile?.userDetail?.firstName}} have not provided profile answers yet.</h6>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Course Details</a>
        <ng-template ngbNavContent>
          <div class="user-profile-section">
            <div class="user-course-detail-wrapper" *isFetchingData="loadingCourseDetail$">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="mb-3">Current Course and Component</h6>
                </div>
              </div>
              <mat-card *ngFor="let courses of userRatings?.onGoingCourseComponents">
                <div class="row">
                  <div class="col-md-12">
                    <div>
                      <p>Course: <span>{{courses?.courseSequence}} {{courses?.courseName}}</span></p>
                      <p>Component: <span>{{courses?.componentSequence}} {{courses?.componentName}}</span></p>
                    </div>
                  </div>
                </div>
              </mat-card>

              <div class="row pt-3">
                <div class="col-md-12">
                  <h6 class="mb-3">Average Ratings</h6>
                </div>
              </div>
              <mat-card>
                <div class="row user-ratings">
                  <!-- for initial course we dont want to show skill ratings to end user so commenting it as of now, in future we will have this configurable -->
                  <div *ngIf="showSkillsRatings" class="col-md-12">
                    <p>Average Skill Rating:
                      <span class="star_rate" *ngIf="userRatings?.ratings?.averageSkillRating > 0">
                        <ng-template #t let-fill="fill" let-index="index">
                          <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill">
                              <fa-icon [icon]="['fas', 'star']"></fa-icon>
                            </span>
                            <fa-icon [icon]="['far', 'star']"></fa-icon>
                          </span>
                        </ng-template>
                        <ngb-rating [rate]="userRatings?.ratings?.averageSkillRating" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                      </span>
                      <span class="rate_num">{{userRatings?.ratings?.averageSkillRating | number : '1.2-2'}}</span>
                    </p>
                  </div>
                  <div class="col-md-12">
                    <p class="no-margin">Average Respect Rating:
                      <span class="star_rate" *ngIf="userRatings?.ratings?.averageRespectRating > 0">
                        <ng-template #t let-fill="fill" let-index="index">
                          <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill">
                              <fa-icon [icon]="['fas', 'star']"></fa-icon>
                            </span>
                            <fa-icon [icon]="['far', 'star']"></fa-icon>
                          </span>
                        </ng-template>
                        <ngb-rating [rate]="userRatings?.ratings?.averageRespectRating" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                      </span>
                      <span class="rate_num">{{userRatings?.ratings?.averageRespectRating | number : '1.2-2'}}</span>
                    </p>
                  </div>
                </div>
              </mat-card>

              <div class="row pt-3">
                <div class="col-md-12">
                  <h6 class="mb-3">Points Earned</h6>
                </div>
              </div>
              <mat-card>
                <div class="total-earned-points">
                  <p>Total Earned Points: <span>{{userRatings?.earnedPoints ? userRatings?.earnedPoints : 0}}</span></p>
                </div>
              </mat-card>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
