import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ApiUrl } from '../../@shared/constants';
import { Member } from '@shared/models/member.model';
import { Course, CourseObjectives } from '@shared/models/course.model';
import { AverageRatings, RiskPoints } from './dashboard.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  watchTour$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private readonly http: HttpClient) { }

  getMyOngoingCourses(): Observable<Array<Course>> {
    return this.http.get<Array<Course>>(ApiUrl.myOngoingCourses);
  }

  getCourseObjectives(courseComponentId): Observable<CourseObjectives[]> {
    return this.http.get<CourseObjectives[]>(`${ApiUrl.courseObjectives}/${courseComponentId}`);
  }

  getMyTeam(): Observable<Array<Member>> {
    return this.http.get<Array<Member>>(ApiUrl.myTeam);
  }

  /* provide courseComponentId to get scheduled/ completed session count */
  getMyGroup(courseId?: number, courseComponentId?: number): Observable<Array<Member>> {
    if (courseId && courseComponentId) {
      const params = new HttpParams()
      .append('courseId', courseId.toString())
      .append('courseComponentId', courseComponentId.toString());
      return this.http.get<Array<Member>>(ApiUrl.myGroup, {params: params});
    }
    else if (courseId) {
      const params = new HttpParams().append('courseId', courseId.toString());
      return this.http.get<Array<Member>>(ApiUrl.myGroup, {params: params});
    }
    return this.http.get<Array<Member>>(ApiUrl.myGroup);
  }

  getMyRiskPoints(): Observable<RiskPoints> {
    return this.http.get<RiskPoints>(ApiUrl.usersRiskPoints);
  }

  getMyAverageRating(): Observable<AverageRatings> {
    return this.http.get<AverageRatings>(ApiUrl.userAverageRating);
  }

  getWatchUser(): Observable<boolean> {
    return this.watchTour$.asObservable();
  }
  setWatchTour(watch: boolean) {
    this.watchTour$.next(watch);
  }
}
