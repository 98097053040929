/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * @whatitdoes it injects the spinner on buttons based on submitting status.
 * @howtouse <button [isSubmitting]="loading"></div>
 */
@Directive({
  selector: '[isSubmitting]'
})
export class SflShowSpinnerDirective {
  visible = false;
  loadingSubscription: Subscription = new Subscription();

  constructor(private readonly elem: ElementRef, private readonly renderer: Renderer2) { }

  @Input()
  set isSubmitting(incomingValue: boolean) {
    const submitBtn = this.elem.nativeElement;
    const spinnerClasses = ['spinner', 'spinner-right', 'spinner-md', 'spinner-dark', 'disabled'];
    if (incomingValue) {
      for (const spinnerClass of spinnerClasses) {
        this.renderer.addClass(submitBtn, spinnerClass);
      }
    } else {
      for (const spinnerClass of spinnerClasses) {
        this.renderer.removeClass(submitBtn, spinnerClass);
      }
    }
  }

}
