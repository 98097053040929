<div class="my-community p-4 tour-my-community">
  <h4>my community</h4>
  <div>
    <ng-container *isFetchingData="loadingChat$">
      <ng-container *ngIf="isShowChat(currentUserConversations); else noChat">
        <div class="unread-msg">
          <span id="unread-title">unread message(s)</span>
          <span id="msg-num">{{unreadMsgCount}}</span>
        </div>
        <div class="message-wrapper scroller">
          <div *ngFor="let userConversation of currentUserConversations; trackBy:trackById">
            <div class="message-box p-3" *ngIf="userConversation?.lastReceipientMsg" (click)="openChat(userConversation)">
              <div class="message-left-content">
                <img [src]="userConversation?.imageUrl" alt="User Profile Image" class="img-fluid avatar rounded-circle" (error)="onImgError($event); onChatImgError(userConversation)">
                <span class="status away" userAvailability [userId]="userConversation?.id"></span>
              </div>
              <div class="message-middle-content">
                <span class="name">{{userConversation?.displayName}}</span>
                <span class="time">{{userConversation?.lastUpdatedAt | chatdate}}</span>
                <div class="message"><p class="chat-message no-margin">{{userConversation?.lastReceipientMsg}}</p> </div>
              </div>
              <div class="message-right-content">
                <a href="javascript:;">
                  <img src="{{imagesURLs.quickReply}}" alt="Reply">
                </a>
              </div>
              <span class="badge badge-primary badge-pill" *ngIf="userConversation?.hasUnreadMsg">New</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *isFetchingData="loadingMyTeam && loadingMyGroup">
    <div class="your-team" *ngIf="myTeam && myTeam.length>=0">
      <div class="community-title mb-2">
        <span class="team-title">
          your team
        </span>
        <span class="group-chat">
          <a href="javascript:;" (click)="startTeamChat(myTeam)"> start team chat</a>
        </span>
      </div>
      <div class="team-mem">
        <div class="wrap-img">
          <img class="avatar rounded-circle " [title]="currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName" [src]="currentAppUser?.imageUrl"
            (error)="onImgError($event)" alt="User Profile Image" (error)="onImgError($event)">
          <span class="user-initails" [title]="currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName">{{getInitials(currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName + ' ' + currentAppUser?.lastName)}}</span>
        </div>
        <div class="team-mem-img" *ngFor="let member of myTeam; let userIndex = index" [matMenuTriggerFor]="userMenu" [matMenuTriggerData]="{user:member}">
          <div class="wrap-img">
            <img class="avatar rounded-circle" [title]="member?.nickName ? member?.nickName : member?.firstName" [src]="member?.profileImage" (error)="onImgError($event)"
              alt="User Profile Image" (error)="onImgError($event)">
            <span class="status online" userAvailability [userId]="member?.userId"></span>
            <span class="user-initails" [title]="member?.nickName ? member?.nickName : member?.firstName">{{getInitials(member?.nickName ? member?.nickName : member?.firstName + ' ' + member?.lastName)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="your-entire-group" *ngIf="myGroup && myGroup.length>=0">
      <div class="community-title mb-2">
        <span class="team-title">
          your entire group
        </span>
        <span class="group-chat">
          <a href="javascript:;" (click)="startGroupChat(myGroup)">start group chat</a>
        </span>
      </div>
      <div class="team-mem">
        <div class="wrap-img">
          <img class="avatar rounded-circle " [title]="currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName" [src]="currentAppUser?.imageUrl"
            (error)="onImgError($event)" alt="User Profile Image" (error)="onImgError($event)">
          <span class="user-initails" [title]="currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName">{{getInitials(currentAppUser?.nickName ? currentAppUser?.nickName : currentAppUser?.firstName+ ' ' +currentAppUser?.lastName)}}</span>
        </div>
        <div class="team-mem-img" *ngFor="let member of myGroup; let userIndex = index" [matMenuTriggerFor]="userMenu" [matMenuTriggerData]="{user:member}">
          <div class="wrap-img">
            <img class="avatar rounded-circle" [title]="member?.nickName ? member?.nickName : member?.firstName" [src]="member?.profileImage" (error)="onImgError($event)"
              alt="User Profile Image" (error)="onImgError($event)">
            <span class="status online" userAvailability [userId]="member?.userId"></span>
            <span class="user-initails" [title]="member?.nickName ? member?.nickName : member?.firstName">{{getInitials(member?.nickName ? member?.nickName : member?.firstName + ' ' + member?.lastName)}}</span>
          </div>
        </div>
      </div>
    </div>
    <mat-menu #userMenu="matMenu">
      <ng-template let-user="user" matMenuContent>
        <button mat-menu-item (click)="openUserChat(user)">Open Chat</button>
        <button mat-menu-item (click)="viewUserProfile(user)">View Profile</button>
      </ng-template>
    </mat-menu>
  </div>
</div>

<ng-template #noChat>
  <div class="no-chat text-center">
    <span><strong>Be a leader. Take a Risk!</strong></span>
    <span>Initiate a chat (after all, everyone likes to feel included).</span>
    <span><strong>We're all in this together.</strong></span>
  </div>
</ng-template>
