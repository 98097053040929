export interface VideoCallAnswer {
  answer: boolean,
  call?
}

export interface VideoCallMetadata {
  learnerName: string,
  participantName: string,
  componentName: string,
  componentSequence: number,
  callInitiatorName: string,
  participantPeerId: string,
  initiatorPeerId: string,
  redirectUri: string,
  componentId: number
}

export enum CallStatus {
  CONNECTING,
  IN_PROGRESS,
  DISCONNECTED,
  DECLINED,
  PEER_OFFLINE,
  ID_TAKEN,
  PERMISSION_DENIED,
  CAMERA_ALREADY_OCCUPIED,
  COMPLETED
}
