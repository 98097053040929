import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Member } from "@shared/models/member.model";
import { User } from "@shared/models/user.model";
import { Util } from '@shared/services/utils';
import { BehaviorSubject, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { Chat, ChatUser, Conversation, Message } from './chat.model';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public currentUser: ChatUser = new ChatUser();
  public messages = [];
  public chat: Chat = {
    chatId: '',
    messages: []
  }
  subscription: Subscription = new Subscription();
  conversation$: BehaviorSubject<Conversation> = new BehaviorSubject(null);

  constructor(private readonly afs: AngularFirestore) { }

  async createUser(user: ChatUser, setCurrentUser?: boolean): Promise<void> {
    return new Promise(resolve => {
      if (user?.id) {
        this.afs.collection('users/').doc(user?.id.toString()).get().subscribe(async userRes => {
          if (!userRes.exists) {
            if (setCurrentUser) {
              this.currentUser = user;
            }
            await this.afs.doc('users/' + user?.id).set(Object.assign({}, user));
          } else {
            if (setCurrentUser) {
              const userResponse = await this.getCurrentUserFromFS(user?.id).toPromise();
              this.currentUser = userResponse[0];
            }
          }
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  async updateUser(user: ChatUser): Promise<void> {
    return this.afs.doc('users/' + user?.id).update(user);
  }

  async setCurrentUser(currentAppUser: User) {
    return await this.createUser(new ChatUser(currentAppUser), true);
  }

  getChat(chatId) {
    return this.afs.collection('conversations', ref => ref.where('chatId', '==', chatId)).valueChanges()
  }

  getCurrentUserFromFS(userId: number) {
    return this.afs.collection('users', ref => ref.where('id', '==', userId)).valueChanges();
  }

  getUser(userId: number) {
    return this.afs.collection('users', ref => ref.where('id', '==', userId)).snapshotChanges()
      .pipe(map(actions => actions.map(Util.documentToDomainObject)));
  }

  getLastMsgSenderName(user: ChatUser): string {
    return user.nickName || user.firstName;
  }

  addNewChat(otherChatUser: ChatUser, addToExistingChat = false): Promise<void> {
    return new Promise(async resolve => {
      await this.createUser(otherChatUser);
      if (!addToExistingChat) {
        const chatId = this.afs.createId();
        await this.afs.doc('conversations/' + chatId).set({
          chatId: chatId,
          messages: []
        });
        this.chat = {
          chatId: chatId,
          messages: []
        }
      }
      resolve();
    });
  }

  pushNewMessage(list) {
    return this.updateMessagList(list);
  }

  updateMessagList(list, chatId?: string) {
    return this.afs.doc('conversations/' + (chatId || this.chat?.chatId)).update(
      { messages: list }
    );
  }

  updateConversations(conversations, userId) {
    return this.afs.doc('users/' + userId).update(
      { conversations }
    );
  }

  clearData() {
    this.messages = [];
    this.currentUser = new ChatUser();
    this.chat = null;
    this.subscription.unsubscribe();
  }

  async updateConversationUser(chatUser: ChatUser) {
    for (const currentConversation of chatUser.conversations) {
      this.subscription.add(this.getUser(currentConversation.id).pipe(take(1)).subscribe(async res => {
        const otherUser: ChatUser = res[0];
        const otherUserConversations = otherUser.conversations.filter(convo => convo?.id === chatUser?.id);
        for (const otherUserConvo of otherUserConversations) {
          otherUserConvo.firstName = chatUser.firstName;
          otherUserConvo.lastName = chatUser.lastName;
          otherUserConvo.imageUrl = chatUser.imageUrl;
          otherUserConvo.nickName = chatUser.nickName;
          this.subscription.add(this.getChat(otherUserConvo.chatId).pipe(take(1)).subscribe(chatMessageResponse => {
            const chatMessage: any = chatMessageResponse[0];
            const messages: Message[] = chatMessage?.messages;
            for (const message of messages.filter(msg => msg.senderId === chatUser.id)) {
              message.senderName = chatUser.nickName ? chatUser.nickName : chatUser.firstName;
              message.senderImageUrl = chatUser.imageUrl;
            }
            this.updateMessagList(messages, otherUserConvo.chatId);
          }));
        }
        this.updateConversations(otherUser.conversations, otherUser.id);
      }));
    }
  }

  setCurrentConversationsWithSorting(conversations: Conversation[]) {
    const groupedConversations: Map<string, Conversation[]> = Util.groupBy(conversations, convos => convos?.chatId);
    const tempUserConversations: Conversation[] = [];
    for (const [key, value] of groupedConversations) {
      const currentUserConvo = value[0];
      const conversation: Conversation = {
        chatId: key,
        firstName: currentUserConvo?.firstName,
        lastName: currentUserConvo?.lastName,
        id: currentUserConvo?.id,
        displayName: value?.map(convo => convo.nickName || convo.firstName)?.join(', '),
        imageUrl: currentUserConvo?.imageUrl,
        displayImageUrls: value?.map(convo => convo.imageUrl),
        chatConversation: value,
        email: currentUserConvo.email,
        lastUpdatedAt: currentUserConvo?.lastUpdatedAt,
        hasUnreadMsg: currentUserConvo?.hasUnreadMsg,
        lastReceipientMsg: currentUserConvo?.lastReceipientMsg,
        nickName: currentUserConvo?.nickName,
        lastMsgSenderName: currentUserConvo.lastMsgSenderName
      }
      tempUserConversations.push(conversation);
    }
    return tempUserConversations.sort((a, b) => b?.lastUpdatedAt?.toDate().getTime() - a?.lastUpdatedAt?.toDate().getTime());
  }

  /**
   * Add a new user to chat. If he already has a chat, populate that chat, else create a new chat
   * @param appUser: application user
   */
  async addNewUserToChat(currentUserConversations: Conversation[], chatUser: ChatUser, otherChatUser: ChatUser, addToCurrentChat = false): Promise<void> {
    return new Promise(async resolve => {
      if (!this.currentUser?.conversations) {
        this.currentUser.conversations = [];
      }
      if (this.hasExistingChat(currentUserConversations, chatUser, addToCurrentChat)) { // Conversation Found
        resolve();
      } else {
        await this.addNewChatToFS(chatUser, otherChatUser, addToCurrentChat);
        resolve();
      }
    });
  }

  /**
   * This identifies if the @param chatUser has a chat in common.
   * @param chatUser user which needs to be checked if the current user has chat or not
   * @param addToCurrentChat also checks if the currentUser is already present in existing chat
   */
  hasExistingChat(currentUserConversations: Conversation[], chatUser: ChatUser, addToCurrentChat: boolean): boolean {
    if (addToCurrentChat) {
      return Boolean(currentUserConversations?.find(convo => convo.chatId === this.chat.chatId && convo.chatConversation?.some(chatConvo => chatConvo.id === chatUser.id)));
    }
    const matchedConverstations = currentUserConversations?.find(convo => convo.id === chatUser.id);
    return matchedConverstations?.chatConversation?.length < 2;
  }

  async addNewChatToFS(chatUser: ChatUser, otherChatUser: ChatUser, addToCurrentChat = false): Promise<void> {
    return new Promise(async resolve => {
      await this.addNewChat(chatUser, addToCurrentChat).then(async () => {
        await this.addConversation(chatUser, otherChatUser);
        resolve();
      });
    });
  }



  async addConversation(user: ChatUser, otherUser?: ChatUser) {
    const otherChatUser = otherUser || this.currentUser;
    const basicChatConversation = {
      chatId: this.chat?.chatId,
      lastReceipientMsg: '',
      lastUpdatedAt: new Date(),
      hasUnreadMsg: true
    };
    let userMsg: Conversation = {
      id: user.id, nickName: user.nickName, firstName: user.firstName, lastName: user.lastName,
      imageUrl: user.imageUrl, email: user?.email, ...basicChatConversation
    };
    let otherMsg: Conversation = {
      id: otherChatUser.id, nickName: otherChatUser.nickName, firstName: otherChatUser.firstName, lastName: otherChatUser.lastName,
      imageUrl: otherChatUser.imageUrl, email: otherChatUser.email, ...basicChatConversation
    };

    this.addAndUpdateConversation(otherChatUser, userMsg);
    this.addAndUpdateConversation(user, otherMsg);

    //first set both references.
    let myReference = this.afs.doc('users/' + otherChatUser?.id);
    let otherReference = this.afs.doc('users/' + user?.id);
    // myReference.get().subscribe(d => {
    //   let c: any = d.data();
    //   if (c && !c?.conversations) {
    //     c.conversations = [];
    //   }
    //   if (!c?.converstations?.find(convo => convo?.chatId === userMsg.chatId)) {
    //     c?.conversations?.push(userMsg);
    //     myReference.update({ conversations: c?.conversations });
    //   }
    // })
    // Updating Other User Profile
    // otherReference.get().subscribe(d => {
    //   let c: any = d.data();
    //   if (c) {
    //     if (!c?.conversations) {
    //       c.conversations = [];
    //     }
    //     if (!c?.converstations?.find(convo => convo?.chatId === otherMsg.chatId)) {
    //       c?.conversations.push(otherMsg);
    //       otherReference.update({ conversations: c.conversations });
    //     }
    //   }
    // });
  }

  async addBulkConversations(user: ChatUser, otherUsers: ChatUser[], addToCurrentChat = true) {
    return new Promise<void>(async resolve => {
      await this.addNewChat(user, addToCurrentChat).then(async () => {
        const basicChatConversation = {
          chatId: this.chat?.chatId,
          lastReceipientMsg: '',
          lastUpdatedAt: new Date(),
          hasUnreadMsg: true
        };
        const otherMsgConversations = [];
        for (const otherChatUser of otherUsers) {
          let otherMsg: Conversation = {
            id: otherChatUser.id, nickName: otherChatUser.nickName, firstName: otherChatUser.firstName,
            lastName: otherChatUser.lastName, imageUrl: otherChatUser.imageUrl, email: otherChatUser.email,
            ...basicChatConversation
          };
          otherMsgConversations.push(otherMsg);
        }
        await this.addAndUpdateConversation(user, otherMsgConversations);
        resolve();
      });
    });
  }

  async addAndUpdateConversation(user: ChatUser, msgConversations: any): Promise<void> {
    let myReference = this.afs.doc('users/' + user?.id);
    return new Promise(resolve => {
      let refSubscription = myReference.get().subscribe(async d => {
        let c: any = d.data();
        if (c) {
          if (!c?.conversations) {
            c.conversations = [];
          }
          if (Array.isArray(msgConversations)) {
            c?.conversations?.push(...msgConversations);
          } else {
            c?.conversations?.push(msgConversations);
          }
          await myReference.update({ conversations: c.conversations });
          refSubscription.unsubscribe();
          refSubscription = new Subscription();
          resolve();
        } else {
          resolve();
        }
      });
    })
  }

  getUserFromMember(member: Member): User {
    return {
      id: member.userId,
      firstName: member.firstName,
      lastName: member.lastName || '',
      email: member.email,
      nickName: member.nickName || '',
      userId: member.userId,
      imageUrl: member.profileImage || ''
    }
  }
}
