// Angular
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'eh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  constructor() {

  }

  /**
   * On init
   */
  ngOnInit(): void {

  }

}
