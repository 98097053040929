// Angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AppConstants } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { User } from '@shared/models/user.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AvailableTimeZones } from '../my-account.model';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'eh-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent extends SflBaseComponent implements OnInit {

  editProfileForm: FormGroup;
  currentUser: User;
  PhoneNumberMask = '(000) 000-0000';
  availableTimeZones: AvailableTimeZones[];
  constructor(
    private readonly fb: FormBuilder,
    private readonly myAccountService: MyAccountService,
    private readonly chRef: ChangeDetectorRef,
    protected readonly layoutUtilsService: LayoutUtilsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptionManager.add(this.myAccountService.getCurrentUserSubject().subscribe((userData) => {
      this.initLoginForm(userData);
      this.currentUser = userData;
      this.loading$.next(false);
    }));
    this.getAvailableTimeZones();
  }

  getAvailableTimeZones() {
    this.subscriptionManager.add(this.myAccountService.getAvailableTimeZones().subscribe((res:AvailableTimeZones[]) => {
      this.availableTimeZones = res;
    }));
  }

  initLoginForm(userData: User) {
    this.editProfileForm = this.fb.group({
      email: [userData?.email || ''],
      firstname: [userData?.firstName || '', Validators.compose([
        Validators.required,
        Validators.minLength(AppConstants.requiredMinLength),
        Validators.maxLength(AppConstants.requiredMaxLength)
      ])
      ],
      lastname: [userData?.lastName || ''],
      nickname: [userData?.nickName || ''],
      phone: [userData?.phoneNumber, Validators.pattern(/^[0-9]\d{9}$/)],
      position: [userData?.position || ''],
      timeZoneId: [userData?.timeZone?.id || '']
    });
  }

  onSubmit() {
    const controls = this.editProfileForm.controls;
    if (this.editProfileForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.isSubmitting = true;
    const userData = {
      ...this.currentUser,
      firstName: controls?.firstname?.value,
      lastName: controls?.lastname?.value,
      nickName: controls?.nickname?.value,
      phoneNumber: controls?.phone?.value,
      position: controls?.position?.value,
      timeZoneId: controls?.timeZoneId?.value
    };

    const selectedTimeZone = this.availableTimeZones?.find(x=> x.id === controls?.timeZoneId?.value);
    userData.timeZone = selectedTimeZone;
    localStorage.setItem('timezone', selectedTimeZone.utc);

    this.subscriptionManager.add(this.myAccountService.updateProfile(userData).subscribe(res => {
      this.isSubmitting = false;
      this.currentUser = userData;
      this.myAccountService.setCurrentUser(this.currentUser);
      this.myAccountService.updateUserOnFirebase(this.currentUser);
      this.layoutUtilsService.showActionNotification(this.appMessages.updateProfileSuccess, AlertType.Success);
      this.router.navigate([this.appRoutes.PROFILE_QUESTION], { relativeTo: this.activatedRoute.parent });
    }, (error) => this.onError(error)));
  }

  onError(error) {
    this.isSubmitting = false;
    this.onError(error);
    this.isSubmitting = false;
    this.loading$.next(false);
    this.chRef.detectChanges();
  }

}
