<div class="terms-of-service-container">
  <div class="modal-content" *isFetchingData="loading$">
    <div class="modal-header">
      <h2 class="modal-title">Our Terms of Use</h2>
      <span class="modal-sub-title">Please review our terms of use before continuing.</span>
    </div>
    <div class="modal-body">
      <div class="agreement-section">
        <h5>Terms of Use</h5>
        <div class="terms-of-use">
          <p>These terms of use are entered into by and between you and eHumanize Inc. (“<strong>Company</strong>,” “<strong>we</strong>,” or “<strong>us</strong>”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, “<strong>Terms of Use</strong>”), govern your access to and use of <a href="https://www.eHumanize.com/" target="_blank">https://www.eHumanize.com/</a>, including any content, functionality and services offered on or through us or <a href="https://www.eHumanize.com/" target="_blank">https://www.eHumanize.com/</a> (collectively, the “<strong>Service</strong>”), whether as a guest or a registered user.</p>
          <p>Please read the Terms of Use carefully before you start to use the Service. <strong>By using the Service or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <a href="https://www.ehumanize.com/privacy-policy/" target="_blank">www.eHumanize.com/privacy-policy</a>, which sets forth our ability to use, collect, and disclose your data (among other things) and is incorporated herein by reference.</strong> If you do not agree to these Terms of Use or the Privacy Policy, you must not access or use the Service. </p>
          <p>Terms specific to registered users’ engagements with the Company, such as pricing, term of engagement and your right to access the Service, and specific obligations with respect to data, will be described to you at the point of payment or enrollment. Any such terms are incorporated by reference into these Terms of Use.</p>
          <p>This Service is offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using this Service, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Service.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Changes to the Terms of Use</h5>
        <div class="terms-of-use">
          <p>We may revise and update these Terms of Use, including but not limited to the Privacy Policy. All changes to the Privacy Policy are effective immediately when we post them, and apply to all access to and use of the Service thereafter. We will make reasonable efforts to alert you of these changes or post a notice on the Service or within the application; however, you are expected to check this page from time to time so you are aware of any changes, as they are binding on you. Your continued use of the Service following the posting of revised Terms of Use means that you accept and agree to the changes. If you do not agree to the modified Terms of Use, you must stop using the Service</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Accessing the Service and Account Security</h5>
        <div class="terms-of-use">
          <p>Subject to and conditioned on your compliance with these Terms of Use, we hereby grant you a non-exclusive, non-transferable right to access and use the Service, solely in accordance with the terms and conditions set forth herein.</p>
          <p>We retain sole control over the operation, maintenance, and management of, and all access to and use of, the Service. We reserve the right to amend this Service, and withdraw any feature, component, deliverable or material we provide on the Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.</p>
          <p>You are solely responsible for all access to and use of the Service. You are responsible for both:</p>
          <ul>
            <li>Making all arrangements necessary for you to have access to the Service.</li>
            <li>Ensuring that all persons who access the Service through your internet connection are aware of these Terms of Use and comply with them.</li>
          </ul>

          <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Service or portions of it using your user name, password or other security information. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Prohibited Uses</h5>
        <div class="terms-of-use">
          <p>You may use the Service only for lawful purposes and in accordance with these Terms of Use. You may not use the Service for any purpose beyond the scope of the access granted in this Agreement. Without limiting the preceding sentence, you agree not to use the Service:</p>
          <ul>
            <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </li>
            <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
            <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with any content standards set forth in these Terms of Use.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.
            </li>
            <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
            <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm the Company or users of the Service, or expose them to liability.</li>
          </ul>

          <p>Additionally, you agree not to:</p>
          <ul>
            <li>Use the Service in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Service, including their ability to engage in real time activities through the Service.</li>
            <li>Use any robot, spider or other automatic device, process or means to access the Service for any purpose, including monitoring or copying any of the material on the Service.</li>
            <li>Use any manual process to monitor or copy any of the material on the Service, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
            <li>Use any device, software or routine that interferes with the proper working of the Service.</li>
            <li>Intentionally introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer or database connected to the Service. </li>
            <li>Attack the Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Otherwise attempt to interfere with the proper working of the Service.</li>
          </ul>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Fees</h5>
        <div class="terms-of-use">
          <p>Subscription fees and any other charges for the use of the Application will be described to you at the point of agreement. They may change from time to time. If subscription fees change, we will give you at least 30 days’ notice. If they do change, your continued use of the Application after the change indicates your agreement with the new fees and charges after the effective date of the change. Any change to fees and other charges will not be applicable to the billing period in which the change occurs. Fees for payment transactions are subject to change without notice, unless otherwise prohibited, at the discretion of our payment platform partners.</p>
          <p>Although great care is taken in the production and description of the Service, typographical, illustrative or pricing errors may occur. We reserve the right to correct errors at any time. All prices are quoted and are payable in United States’ Dollars. You are responsible for all taxes applicable to the fees and charges in any applicable jurisdiction.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Support</h5>
        <div class="terms-of-use">
          <p>This Agreement does not entitle you to any support services unless such services are expressly described to you at the point of agreement.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>User Data</h5>
        <div class="terms-of-use">
          <p>To access and use the Service or some of the resources it offers, you may be asked to provide certain data (“<strong>User Data</strong>”). You agree that all User Data are governed by our Privacy Policy, and you consent to all actions we take with respect to User Data consistent with our Privacy Policy.</p>
          <p>It is a condition of your use of the Service that all User Data are correct, current and complete. If any User Data becomes misleading or inaccurate, it is your responsibility to correct it. You understand and acknowledge that you are responsible for any User Data you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness. In making any User Contribution, you represent and warrant that does and will comply with these Terms of Use. </p>
          <p><strong><u>No Reproduction. The courses, content, and other materials delivered through the Services (collectively the “<em>Courses</em>”) as well as the format, features, teaching methodologies, and general ‘look and feel’ of the Services, which include, without limitation, the ‘4 Essentials of Engaged Learning” (collectively, the “Service Features”) are copyrighted and proprietary to eHumanize and its licensors. The Courses shall not be retransmitted, reproduced, rebroadcast, or otherwise distributed, disclosed or used in any form without the express written consent of eHumanize. Additionally, You shall not create or attempt to create (or assist any third-party in doing the same) any platform or service that shares, utilities, copies, or mimics the Service Features.Intellectual Property Rights</u></strong></p>
          <p>Other than User Data, the Service and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
          <p>These Terms of Use permit you to use the Service for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Service, except as follows:</p>
          <ul>
            <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
            <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
            <li>You may print or download one copy of a reasonable number of pages of the Service for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
            <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
          </ul>
          <p>You must not:</p>
          <ul>
            <li>Modify copies of any materials from this site.</li>
            <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
          </ul>
          <p>You must not access or use for any commercial purposes any part of the Service or any services or materials available through the Service.</p>
          <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Service in breach of the Terms of Use, your right to use the Service will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Service or any content on the Service is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Service not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5><em>Trademarks</em></h5>
        <div class="terms-of-use">
          <p>The Company name, the terms eHumanize, and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Service are the trademarks of their respective owners.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5><em>User Data</em></h5>
        <div class="terms-of-use">
          <p>Your User Data are owned by you. By providing User Data to or through the Service, you grant us and our affiliates and subsidiaries a non-exclusive, royalty-free, transferable right to use, display, perform, reproduce, distribute, publish, modify, adapt, translate, and create derivative works from such User Data as necessary to perform the Service. You represent and warrant that you own or control all rights in and to the User Data and have the right to grant the aforementioned license to us and our licensees, successors and assigns, and that the use or other transmission of such User Data does not violate these Terms and will not violate any rights of or cause injury to any person or entity.</p>
          <p>Notwithstanding anything to the contrary in these Terms of Use, we may monitor your use of the Service and collect and compile data and information related to your use of the Service to be used in an aggregate and anonymized manner, including to compile statistical and performance information related to the provision and operation of the Service (“<strong>Aggregated Statistics</strong>”). All right, title, and interest in Aggregated Statistics, and all intellectual property rights therein, belong to and are retained solely by us. You acknowledge and agree that we may compile Aggregated Statistics based on User Data. You agree that we may (i) make Aggregated Statistics publicly available in compliance with applicable law, and (ii) use Aggregated Statistics to the extent and in any manner permitted under applicable law; provided that such Aggregated Statistics do not identify you or your Confidential Information.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5><em>Feedback</em></h5>
        <div class="terms-of-use">
          <p>If you send or transmit any communications or materials to us by mail, email, telephone, or otherwise, suggesting or recommending changes to the Service or our
            intellectual property, including without limitation, new features or functionality relating thereto, or any comments, questions, suggestions, or the like
            (“<strong>Feedback</strong>”), we
            are free to use such Feedback irrespective of any other obligation or limitation between the parties that technically may govern such Feedback. </p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Termination & Suspension of Accounts</h5>
        <div class="terms-of-use">
          <p>We may monitor the conduct of both guests and registered users to the Service. We reserve the right to terminate or suspend any guest’s access to all or part of the Service for any or no reason, including without limitation, any suspected violation of these Terms of Use.</p>
          <p>The Company may terminate access to registered accounts at any time and for any reason, provided, however, that if the Company terminates access to your registered account for a reason other than your suspected violated of the Terms, then if applicable, the Company will refund the prorated portion of any fees or payments made for the remainder of the applicable period to which such fees or payment applied.</p>
          <p>Upon termination of your right to access the Service, you shall immediately discontinue any use thereof. We may, following termination, delete or retain any User Data or other information, consistently with the Privacy Policy.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Geographic Restrictions</h5>
        <div class="terms-of-use">
          <p>The owner of the Service is incorporated in the State of Delaware in the United States. We provide this Service for use only by persons located in the United States. We make no claims that the Service or any of its content is accessible or appropriate outside of the United States. Access to the Service may not be legal by certain persons or in certain countries. If you access the Service from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Reliance on Information Posted</h5>
        <div class="terms-of-use">
          <p>The information presented on or through the Service is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Service, or by anyone who may be informed of any of its contents.</p>
          <p>This Service may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. Any such statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Disclaimer of Warranties</h5>
        <div class="terms-of-use">
          <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Service will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. EXCEPT AS A RESULT OF OUR GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SERVICE LINKED TO IT.</p>
          <p>YOUR USE OF THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
          <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
          <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Limitation on Liability</h5>
        <div class="terms-of-use">
          <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY SERVICES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR SUCH OTHER SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>
          <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Governing Law and Jurisdiction</h5>
        <div class="terms-of-use">
          <p>All matters relating to the Service and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Ohio without giving effect to any choice or conflict of law provision or rule (whether of the State of Ohio or any other jurisdiction).</p>
          <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Service shall be instituted exclusively in the federal courts of the United States or the courts of the State of Ohio, in each case located in the City of Columbus and County of Franklin, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Limitation on Time to File Claims</h5>
        <div class="terms-of-use">
          <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Waiver and Severability</h5>
        <div class="terms-of-use">
          <p>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
          <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Entire Agreement</h5>
        <div class="terms-of-use">
          <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and eHumanize Inc. with respect to the Service and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Service.</p>
        </div>
      </div>
      <div class="agreement-section">
        <h5>Your Comments and Concerns</h5>
        <div class="terms-of-use">
          <p>This Service is operated by eHumanize Inc., a Delaware corporation, registered at 8 The Green, Suite A, Dover DE 19901.</p>
          <p>All other feedback, comments, requests for technical support and other communications relating to the Service should be directed to: <a href="mailto:info@eHumanize.com">info@eHumanize.com</a>.</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <span *ngIf="!currentUser?.loginAttempted">
        <input type="checkbox" id="terms" [(ngModel)]="termsOfUseAccept">
        <label for="terms">
          <span>
            <img [src]="imagesURLs.checkMark" alt="Checked Icon" />
          </span>
          <p>I have reviewed and accept the Terms of Use.</p>
        </label>
        <small *ngIf="!termsOfUseAccept && proceed" class="form-text text-danger">
          {{appMessages.acceptTermsOfUse}}
        </small>
      </span>
      <button type="button" name="submit" class="btn btn-primary cust-btn-outline" (click)="proceedToApplication()" [ngClass]="{'disabled-accept-btn': !termsOfUseAccept && !currentUser?.loginAttempted}">Continue</button>
    </div>
  </div>
</div>
