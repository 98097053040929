import { Component, Input, OnInit } from '@angular/core';
import { SidebarPanelService } from '@layout/theme/sidebar-panel/sidebar-panel.service';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { ChatParam } from './chat-panel.model';

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent extends SflBaseComponent implements OnInit {
  @Input() data: ChatParam;
  constructor(private readonly sidebarPanelService: SidebarPanelService) {
    super();
  }

  ngOnInit(): void {
  }

  sibarPanelClose(data?) {
    this.sidebarPanelService.openSidebarTemplate({ template: null, data });
  }

}
