<!-- swipper -->
<swiper [effect]="'coverflow'" [grabCursor]="true" [centeredSlides]="true" [slidesPerView]="3" [initialSlide]="3" [loop]="true"
  [autoplay]="{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter:true }"
  [navigation]="true"
  [coverflowEffect]="{
  rotate: 0,
  stretch: 0,
  depth: 200,
  modifier: 3,
  slideShadows: true
}"
 [breakpoints]="breakPoints" [pagination]="true" class="sfl-swiper">
  <ng-template swiperSlide *ngFor="let item of swiperDataContent">
    <div class="carousel-img"><img class="gift-image" [src]="item?.imageUrl" alt="cash in"></div>
    <div class="wrapper">
      <span *ngIf="item?.seasonal" class="water-mark">Seasonal</span>
      <img class="gift-image" [src]="item?.imageUrl" alt="cash in">
    </div>
    <div class="carousel-caption">
      <p [innerHTML]="item?.text"></p>
      <div class="actions">
        <button class="btn btn-save btn-all"  data-toggle="tooltip" data-placement="top" title="Choose this donation!" (click)="donateThisGift(item)" [disabled]="disabled || item?.point > usersEarnedPoints">Select</button>
      </div>
    </div>
  </ng-template>
</swiper>
<!-- swipper -->
