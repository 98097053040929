import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CashInPointItems } from '@entities/risk-station/risk-station.model';

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Navigation, Pagination,  Autoplay } from "swiper";
// install Swiper modules
SwiperCore.use([ EffectCoverflow, Navigation, Pagination, Autoplay ]);

@Component({
  selector: 'sfl-swiper-carousel',
  templateUrl: './sfl-swiper-carousel.component.html',
  styleUrls: ['./sfl-swiper-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class SflSwiperCarouselComponent implements OnInit {

  @Input() swiperDataContent: CashInPointItems[];
  @Input() usersEarnedPoints: number;
  @Input() disabled: boolean;

  @Output() donateThisGiftItem = new EventEmitter<CashInPointItems>();

  breakPoints = {
    320: {
      slidesPerView: 1
    },
    480: {
      slidesPerView: 1
    },
    640: {
      slidesPerView: 1
    },
    840: {
      slidesPerView: 3
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

  // used to play provided video from UploadedDocumentURL
  donateThisGift(giftItem: CashInPointItems) {
    this.donateThisGiftItem.next(giftItem);
  }

}
