import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * @whatitdoes it accepts string HTML content and sanitizes it to prevent any removal. i.e. inline styling
 * @howtouse  <p [innerHTML]="htmlContent | safeHTML"></p>
 *            // htmlContent = `<span style="color:red">This is red text!</span>;`
 * @brief     the DOM by defaults removes any inline style being injected to it, so we need to bypassSecurity and ask DOM to trust the HTML content being rendered using DomSanitizer service
 * @returns   :SafeHtml, safe html to be rendered
 */

@Pipe({
  name: 'safeHTML'
})
export class SafeHTMLPipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) { }

  transform(htmlToSanitize: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(htmlToSanitize);
  }
}
