import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { APP_ROUTES, AppConstants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class LogInGuard implements CanActivate {

  constructor(private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(AppConstants.authenticationToken)) {
        this.router.navigate([APP_ROUTES.DASHBOARD]);
        return false;
    }
    return true;
  }

}
