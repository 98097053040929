import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { VideoCallMetadata } from "@shared/models/video-call.model";
import { VideoListenerService } from "@shared/services/video/video-listener.service";
import { VideoService } from "@shared/services/video/video.service";
import { RespectRemindersComponent } from '../courses/respect-reminders/respect-reminders.component';
import { SflBaseComponent } from "../sfl-base/sfl-base.component";

@Component({
  selector: 'app-video-prompt',
  templateUrl: './video-prompt.component.html',
  styleUrls: ['./video-prompt.component.scss']
})
export class VideoPromptComponent extends SflBaseComponent implements OnInit {
  isNewVideoComing = false;
  call;
  newCallMsg: string;
  metadata: VideoCallMetadata;
  constructor(private readonly videoService: VideoService,
    private readonly videoListenerService: VideoListenerService,
    private readonly router: Router,
    private readonly cdf: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog) {
    super();
  }

  async ngOnInit() {
    await this.videoListenerService.initializePeer(true);
    this.subscriptionManager.add(this.videoService.incomingVideoCall.subscribe(call => {
      /**
       * this listens to an incoming call and shows the prompt.
       * this also initiates a connection when a call is recieved. this is required to show a decline prompt.
       */
      if (call) {
        this.call = call;
        this.videoService.currentCall = call;
        this.metadata = call?.options?.metadata;
        setTimeout(() => {
          this.isNewVideoComing = Boolean(this.metadata?.initiatorPeerId);
          this.cdf.detectChanges();
        }, 2000);
        this.newCallMsg = this.metadata && `Component ${this.metadata?.componentSequence} : ${this.metadata?.componentName} - ${this.metadata?.callInitiatorName} has started the session`;
        this.videoService.initializeVideoConnectionListener({ participantPeerId: this.metadata?.initiatorPeerId, options: { metadata: this.metadata }, destroy: true });
        this.videoService.playNotificationSound(true);
      }
    }));

    this.subscriptionManager.add(this.videoService.perticipantJoined.subscribe(participantJoined => {
      if (participantJoined) {
        this.isNewVideoComing = false;
        this.videoService.stopNotificationSound();
        this.cdf.detectChanges();
      }
    }));
  }

  joinCall() {
    this.ngZone.run(() => {
      // prompt respect reminder modal before proceeding to the incoming call
      let data: number;
      data = this.metadata?.componentId;
      if (data) {
        const dialogRef = this.dialog.open(RespectRemindersComponent, { data });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate([this.metadata.redirectUri], { queryParams: { caller: this.metadata.callInitiatorName } });
            this.isNewVideoComing = false;
            this.videoService.answerVideoCall(true, this.call);
          }
        });
      }

    })
  }

  declineCall() {
    this.ngZone.run(() => {
      this.isNewVideoComing = false;
      this.videoService.sendMessageToParticipant({ declined: true });
      this.videoService.answerVideoCall(false, this.call);
    });
  }
}
