
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentActions, ComponentAvailableAction, CourseService } from '@entities/course-management';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { environment } from '@environment/environment';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { APP_ROUTES } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { CourseComponent } from '@shared/models/course-component.model';
import { User } from '@shared/models/user.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import * as moment from 'moment-timezone';


@Component({
  selector: 'eh-ongoing-course-components',
  templateUrl: './ongoing-course-components.component.html',
  styleUrls: ['./ongoing-course-components.component.scss']
})
export class OnGoingCourseComponentsComponent extends SflBaseComponent implements OnInit {
  @Input() components: Array<CourseComponent>;
  @Output() onNext: EventEmitter<CourseComponent> = new EventEmitter();
  currentUser: User;
  env = environment;
  allowDocumentReview: boolean;
  componentInProgress: CourseComponent;
  actionsOfInProgressComponent: ComponentActions[] = [];
  actionsOfStepAheadComponent: ComponentActions[] = [];
  componentsActions = ComponentAvailableAction;
  constructor(private readonly accountService: MyAccountService,
    private readonly router: Router,
    private readonly courseService: CourseService,
    protected readonly layoutUtilsService: LayoutUtilsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.accountService.getCurrentUser();
  }

  ngOnChanges() {
    this.arrangeComponents();
    this.setComponentsAvailableActions();
  }

  async arrangeComponents() {
    let continueIndex = 0;
    if (!this.components || this.components.length === 0) { return }
    for (let i = 0; i < this.components.length; i++) {
      if (this.components[i].completed) {
        continue;
      }
      continueIndex = i;
      break;
    }

    this.components[continueIndex].isContinue = true;

    if (continueIndex < this.components.length - 1) {
      for (let i = continueIndex; i < this.components.length; i++) {
        this.components[i].yetToStart = true;
        this.components[i].stepAheadComponent = false;
      }
      if (await this.canUserStartLearn(this.components[continueIndex+1])) {
        this.components[continueIndex+1].isContinue = true;
        this.components[continueIndex+1].stepAheadComponent = true;
        // get available action of the next component too, as user can see the progress bar of the component on the dashboard.
        this.getStepAheadComponentsAvailableActions(this.components[continueIndex+1]);
      }
    }
  }

  /**
   * Used to set available action with component. Actions like: Learn, Practice and Apply
   */
  setComponentsAvailableActions() {
    this.componentInProgress = this.components?.find(component => component?.isContinue && !component?.completed);
    if (this.componentInProgress) {
      this.getComponentsAvailableActions();
    }
  }

  getComponentsAvailableActions() {
    this.subscriptionManager.add(this.courseService.getCourseComponentActions(this.componentInProgress?.courseComponent?.id, this.componentsActions.NONE).subscribe((actions: CourseComponent) => {
      this.actionsOfInProgressComponent = actions?.courseComponent?.componentActions;
    }))
  }
  getStepAheadComponentsAvailableActions(stepAheadComponent: CourseComponent) {
    this.subscriptionManager.add(this.courseService.getCourseComponentActions(stepAheadComponent?.courseComponent?.id, this.componentsActions.NONE).subscribe((actions: CourseComponent) => {
      this.actionsOfStepAheadComponent = actions?.courseComponent?.componentActions;
    }))
  }

  getComponentStep(component) {
    if (component.actionCompleted) {
      return this.appConstants.componentSteps[component.actionCompleted];
    }
  }

  goClickNext(component) {
    this.onNext.emit(component);
  }

  getStatusClass(component: CourseComponent, referenceComponentStep, availableComponentActions: ComponentActions[]) {
    let className = '';
    if (availableComponentActions?.length === 1) {
      className = 'width-1';
    } else if (availableComponentActions?.length === 2) {
      className = 'width-2';
    } else if (availableComponentActions?.length === 3) {
      className = 'width-3';
    }
    switch (this.getComponentStep(component)) {
      case this.appConstants.componentSteps.APPLY:
        if ([this.appConstants.componentSteps.PRACTICE, this.appConstants.componentSteps.LEARN, this.appConstants.componentSteps.APPLY].includes(referenceComponentStep)) {
          return className + ' active';
        }
        return className;
      case this.appConstants.componentSteps.PRACTICE:
        if ([this.appConstants.componentSteps.PRACTICE, this.appConstants.componentSteps.LEARN].includes(referenceComponentStep)) {
          return className + ' active';
        }
        return className;
      case this.appConstants.componentSteps.LEARN:
        if (this.appConstants.componentSteps.LEARN === referenceComponentStep) {
          return className + ' active';
        }
        return className;
      default: '';
        return className;
    }
  }

  navigateToReviewThisVideo(component: CourseComponent, index: number) {
    if (component?.progress === 0 || !component?.pendingReviews[index]?.allowDocumentReview) {
      this.layoutUtilsService.showActionNotification(`Please complete learn section first for Component ${component?.courseComponent?.sequence} - ${component?.courseComponent?.name} to review this request.`, AlertType.Error);
      return;
    }
    this.courseService.setFeedbackUpload(component?.pendingReviews[index]);
    this.router.navigate([APP_ROUTES.COURSE_COMPONENT, `${component?.courseComponent?.courseId}`, `${component?.courseComponent?.id}`, APP_ROUTES.GIVE_FEEDBACK]);
  }

  navigateToFeedbackStation(courseId: number, courseComponentId: number) {
    this.courseService.setCurrentPractice(null);
    this.router.navigate([this.appRoutes.FEEDBACK_STATION, courseId, courseComponentId]);
  }

  navigateToSchedule(courseId: number, courseComponentId: number) {
    this.router.navigate([this.appRoutes.COURSE_COMPONENT, courseId, courseComponentId, this.appRoutes.PRACTICE_WITH_ALLIES]);
  }

  getProgress(progress): number {
    return Math.round(progress);
  }

  canUserStartLearn(courseComponent: CourseComponent): Promise<boolean> {
    return new Promise(resolve => {
      this.subscriptionManager.add(this.courseService.canUserStartProvidedActionStep(courseComponent?.courseComponent?.courseId, courseComponent?.courseComponent?.id).subscribe((allowed: boolean) => {
        resolve(allowed);
      }))
    });
  }

  getTargetFontColor(targetDate: string): boolean {
    return moment(targetDate).isBefore(moment(), 'day');
  }
}
;
