<div class="confirmation-wrapper">
  <div class="header">
    <h3>{{data?.title || 'Confirmation'}}</h3>
    <mat-icon (click)="onClose()">clear</mat-icon>
  </div>
  <hr />
  <div class="content">
    <span [innerHTML]="data?.body"></span>
  </div>
  <div>
    <div class="footer">
      <button *ngIf="!data?.hideCancel" type="button" name="submit" class="btn btn-secondary" (click)="onClose()">{{data?.cancelTxt || 'No'}}</button>
      <button type="button" name="submit" class="btn btn-primary" (click)="onclickNext()">{{data?.successTxt || 'Yes'}}</button>
    </div>
  </div>
</div>
