import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrl } from '../../@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  watchNotificationCounts$: BehaviorSubject<number> = new BehaviorSubject(0);
  watchNewNotification$: BehaviorSubject<string> = new BehaviorSubject('');
  constructor(private readonly http: HttpClient) { }

  getNotificationCount(currentUserId, isNew = false) {
    const params = new HttpParams().append('recipientId', currentUserId);
    this.http.get(ApiUrl.getNotificationCounts, { params: params }).subscribe((res: any) => {
      this.watchNotificationCounts$.next(res.unreadCount);
      if (isNew) {
        this.watchNewNotification$.next('new_notification');
      }
    })
  }

  markASRead(currentUserId, id) {
    const params = new HttpParams().append('recipientId', currentUserId);
    return this.http.put(`${ApiUrl.markNotificationRead}/${id}`, null, { params: params });
  }

  markAllAsRead(currentUserId) {
    const params = new HttpParams().append('recipientId', currentUserId);
    return this.http.get(ApiUrl.markNotificationRead, { params: params });
  }

  getAllNotifications(currentUserId, page) {
    const params = new HttpParams().append('recipientId', currentUserId);
    params.append('page', page);
    return this.http.get(ApiUrl.getAllNotification, { params: params });
  }

  checkUserCanReview(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${ApiUrl.chackUserCanReview}/${id}`);
  }

  checkUserCanReviewM2M(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${ApiUrl.chackUserCanReviewM2M}/${id}`);
  }
}
