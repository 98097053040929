import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatUser, Conversation, Message } from "@entities/course-management/courses/chat/chat.model";
import { ChatService } from "@entities/course-management/courses/chat/chat.service";
import { DashboardService } from "@entities/dashboard/dashboard.service";
import { MyAccountService } from "@entities/my-account/my-account.service";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { Member } from "@shared/models/member.model";
import { User } from "@shared/models/user.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { SflBaseComponent } from "../sfl-base/sfl-base.component";

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent extends SflBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  messages: Array<Message> = [];
  temp: any; // for handling temporory data from observables.
  message: string;
  selectedConversation: Conversation;
  chatMsgSubscription: Subscription = new Subscription();
  availableAllies: Member[] = [];
  filteredAvailableAllies: Member[] = [];
  userListWaitTime = 500;
  addingAllyUserIndex: number;
  addingAllyToChat$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('sendChatMessageInput') sendChatMessageInput: ElementRef;
  @ViewChild('chatBox') chatBox: ElementRef;

  @Input() currentAppUser: User;
  @Input() selectedUserToChat: Member;
  @Input() currentUserConversations: Conversation[] = [];
  @Input() showCalendarBtn = true;
  @Input() isFromQuickChat = false;
  @Input() selectedAllies: Member[] = [];
  @Output() refreshUserChatList: EventEmitter<boolean> = new EventEmitter();
  groupCreated = false;

  constructor(private readonly chatService: ChatService,
    private readonly dashboardService: DashboardService,
    private readonly accountService: MyAccountService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    config: NgbDropdownConfig) {
    super();
    config.placement = 'bottom-right';
  }

  async ngOnInit() {
    this.getListOfMyAllies();
    this.subscriptionManager.add(this.chatService.conversation$.subscribe(conversation => {
      if (conversation) {
        this.loading$.next(true);
        this.chatService.chat = { chatId: conversation?.chatId, messages: [] };
        this.selectConversation(conversation);
      }
    }));
  }

  async ngOnChanges() {
    if (this.selectedAllies?.length && !this.groupCreated) {
      this.createAGroupChatFromAllyList(this.selectedAllies);
      this.groupCreated = true;
    }
  }

  ngAfterViewInit() {
    if (this.sendChatMessageInput) {
      this.sendChatMessageInput.nativeElement.focus();
    }
  }

  getListOfMyAllies() {
    this.subscriptionManager.add(
      this.dashboardService.getMyGroup().subscribe(allies => {
        this.availableAllies = allies;
        this.filteredAvailableAllies = allies;
      }, (error) => this.onError(error))
    );
  }

  /**
   * add a new user while adding user to chat. check if it exists on firebase, else create it.
   */
  selectConversation(conversation: Conversation) {
    if (conversation) {
      this.updateConversationLastUpdatedTime(false, true);
      this.messages = [];
      this.selectedConversation = conversation;
      this.setCurrentConversation();
      if (this.chatMsgSubscription) {
        this.chatMsgSubscription.unsubscribe();
      }
      this.chatMsgSubscription = new Subscription();
      this.subscribeToChat();
      if (this.sendChatMessageInput) {
        this.sendChatMessageInput.nativeElement.focus();
      }
    }
  }

  async updateConversationLastUpdatedTime(updateCurrentUserUpdatedTime: boolean, dontUpdateOtherUsersReadFlg: boolean, isUnread = true) {
    const currentConversations = this.chatService.currentUser.conversations.filter(convo => convo.chatId === this.chatService.chat.chatId);
    for (const currentConversation of currentConversations) {
      if (isUnread) {
        this.subscriptionManager.add(this.chatService.getUser(currentConversation.id).pipe(take(1)).subscribe(async res => {
          const otherUser: ChatUser = res[0];
          const otherUserConversations = otherUser.conversations.filter(convo => convo.chatId === this.chatService.chat.chatId);
          for (const otherUserConvo of otherUserConversations) {
            if (!dontUpdateOtherUsersReadFlg) {
              otherUserConvo.lastUpdatedAt = new Date();
              otherUserConvo.hasUnreadMsg = isUnread;
            }
            otherUserConvo.lastReceipientMsg = this.messages[this.messages?.length - 1]?.content || otherUserConvo.lastReceipientMsg;
            otherUserConvo.lastMsgSenderName = this.messages[this.messages?.length - 1]?.senderName || '';
          }
          if (otherUser.conversations) {
            this.chatService.updateConversations(otherUser.conversations, otherUser.id);
          }
        }));
      }
      if (updateCurrentUserUpdatedTime) {
        currentConversation.lastUpdatedAt = new Date();
      }
      currentConversation.hasUnreadMsg = false;
      currentConversation.lastReceipientMsg = this.messages[this.messages?.length - 1]?.content || currentConversation.lastReceipientMsg;
      currentConversation.lastMsgSenderName = this.messages[this.messages?.length - 1]?.senderName || '';
    }
    if (this.chatService.currentUser.conversations) {
      this.chatService.updateConversations(this.chatService.currentUser.conversations, this.chatService.currentUser.id);
    }
  }

  checkMessage(message: string): Promise<string> {
    return new Promise(resolve => {
      // this will handlw the undefined issue
      if (!message) {
        resolve('');
      }
      if (message.trim() === '') {
        resolve('');
      } else {
        resolve(message.trim());
      }
    })
  }

  async sendMessage() {
    if (await this.checkMessage(this.message) === '') {
      return;
    }
    let msg: Message = {
      senderId: this.chatService.currentUser.id,
      senderName: this.chatService.getLastMsgSenderName(this.chatService.currentUser),
      timestamp: new Date(),
      content: this.message,
      isRead: false,
      senderImageUrl: this.chatService.currentUser.imageUrl
    };
    this.messages.push(msg);
    this.chatService.pushNewMessage(this.messages);
    this.updateConversationLastUpdatedTime(true, false);
    this.message = '';
  }

  async refreshPage() {
    // trigger user chat list refresh;
    if (this.chatMsgSubscription) {
      this.chatMsgSubscription.unsubscribe();
    }
    this.chatMsgSubscription = new Subscription();
    this.subscribeToChat();
    this.refreshUserChatList.next(true);
  }

  subscribeToChat() {
    this.chatMsgSubscription = this.chatService.getChat(this.chatService?.chat?.chatId).subscribe(m => {
      this.temp = m;
      this.chatService.chat = this.temp[0];
      this.messages = this.chatService?.chat?.messages == undefined ? [] : this.chatService?.chat?.messages;
      this.loading$.next(false);
    });
  }

  async updateReadFlagForChat() {
    for (const message of this.messages.filter(message => !message.isRead)) {
      message.isRead = true;
    }
    if (this.chatService.chat?.chatId) {
      this.chatService.updateMessagList(this.messages);
      this.updateConversationLastUpdatedTime(false, true);
    }
  }

  isSender(message: Message) {
    return this.currentAppUser?.id === message?.senderId;
  }

  async addAllyToChat(ally: Member, selectAllyDropdown, index: number) {
    this.addingAllyUserIndex = index;
    this.addingAllyToChat$.next(true);
    const allyUser = this.chatService.getUserFromMember(ally);
    const chatUser = new ChatUser(allyUser);
    // error while creating new group
    // if (this.isCurrentChatIndividual()) {
    //   // If it is an individual chat, create a new chat
    //   await this.createNewGroupChat(chatUser, this.getChatUserFromConverstion(this.selectedConversation?.chatConversation[0]));
    // } else {
    //  If it is already a group chat, add new person to same group.
    for (const chatConversation of this.selectedConversation.chatConversation) {
      const user: ChatUser = this.getChatUserFromConverstion(chatConversation);
      await this.chatService.addNewUserToChat(this.currentUserConversations, chatUser, user, true);
    }
    await this.chatService.addNewUserToChat(this.currentUserConversations, chatUser, this.chatService.currentUser, true);
    // }
    this.loading$.next(false);
    this.refreshPage();
    selectAllyDropdown.close();
    this.addingAllyUserIndex = null;
    this.addingAllyToChat$.next(false);
  }

  // Issue (user appearing twice)
  createNewGroupChat(newAlly: ChatUser, existingOtherMember: ChatUser): Promise<void> {
    return new Promise(async resolve => {
      // add new chat with newest ally and current user. set the new chat as active
      await this.chatService.addNewUserToChat(this.chatService.currentUser.conversations, newAlly, this.chatService.currentUser);
      // add an entry of new ally with existing other member
      await this.chatService.addNewUserToChat(this.currentUserConversations, newAlly, existingOtherMember, true);
      await this.chatService.addNewUserToChat(this.currentUserConversations, this.chatService.currentUser, newAlly, true);
      await this.chatService.addNewUserToChat(this.currentUserConversations, existingOtherMember, this.chatService.currentUser, true);
      resolve();
    });
  }

  getChatUserFromConverstion(chatConversation: Conversation): ChatUser {
    return {
      id: chatConversation.id,
      email: chatConversation.email,
      firstName: chatConversation.firstName,
      lastName: chatConversation.lastName,
      imageUrl: chatConversation.imageUrl,
      nickName: chatConversation.nickName
    }
  }

  isCurrentChatIndividual() {
    return this.selectedConversation.chatConversation?.length === 1;
  }

  async addConversation(chatUser: ChatUser, otherChatUser: ChatUser) {
    await this.chatService.addConversation(chatUser, otherChatUser); //passing other user info
  }

  onSearchAlly(searchPhrase: string) {
    if (searchPhrase) {
      const phrase = searchPhrase.toLowerCase();
      this.filteredAvailableAllies = this.availableAllies.filter(ally => ally.firstName?.toLowerCase().includes(phrase) || ally.lastName.toLowerCase().includes(phrase));
    } else {
      this.filteredAvailableAllies = this.availableAllies;
    }
  }

  getChatDisplayName(conversations): string {
    const groupChats = conversations?.filter(conversation => conversation?.chatId === this.chatService?.chat?.chatId);
    const groupChatUserNames = groupChats?.length ? groupChats?.map(chat => chat?.nickName || chat?.firstName) : conversations?.map(convo => convo.nickName || convo.firstName);
    return groupChatUserNames?.join(', ');
  }

  setCurrentConversation() {
    if (this.selectedConversation) {
      this.selectedConversation.displayName = this.getChatDisplayName(this.selectedConversation.chatConversation);
    }
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
    this.chatMsgSubscription.unsubscribe();
    this.chatService.conversation$ = new BehaviorSubject(null);
  }

  navigateToNewSchedule() {
    this.router.navigate([this.appRoutes.NEW_SCHEDULE, this.selectedConversation?.id], { relativeTo: this.activatedRoute.parent });
  }

  async onChatImgError(userConversation?: Conversation) {
    if (userConversation) {
      this.accountService.getUserChatImage(userConversation?.id);
    }
  }

  async createAGroupChatFromAllyList(allyList: Member[]) {
    this.loading$.next(true);
    const allAllyUsers: User[] = allyList.map(member => this.chatService.getUserFromMember(member));
    const allTeamMembers = JSON.parse(JSON.stringify(allAllyUsers));
    for (const [index, ally] of allAllyUsers.entries()) {
      const otherTeamMembers = allTeamMembers.filter(member => member.id !== ally.id)?.map(member => new ChatUser(member));
      const allyChatUser: ChatUser = new ChatUser(ally);
      if (index > 0) {
        this.chatService.addBulkConversations(allyChatUser, otherTeamMembers);
      } else {
        await this.chatService.addBulkConversations(allyChatUser, otherTeamMembers, false);
      }
    }
    this.loading$.next(false);
    this.setCurrentConversation();
  }

}
