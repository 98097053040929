import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from '@layout/theme/base/base.component';
import { AuthGuard } from '@shared/services/auth-guard.service';
import { LogInGuard } from '@shared/services/login-guard.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module').then(m => m.AuthModule),
    canActivate: [LogInGuard]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@entities/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'courses',
        loadChildren: () => import('@entities/course-management/course-management.module').then(m => m.CourseManagementModule)
      },
      {
        path: 'my-account',
        loadChildren: () => import('@entities/my-account/my-account.module').then(m => m.MyAccountModule)
      },
      {
        path: 'feedback-station',
        loadChildren: () => import('@entities/feedback-station/feedback-station.module').then(m => m.FeedbackStationModule)
      },
      {
        path: 'risk-station',
        loadChildren: () => import('@entities/risk-station/risk-station.module').then(m => m.RiskStationModule)
      },
      {
        path: 'inbox',
        loadChildren: () => import('@entities/inbox/inbox.module').then(m => m.InboxModule)
      },
      {
        path: 'my-videos',
        loadChildren: () => import('@entities/my-videos/my-videos.module').then(m => m.MyVideosModule)
      },
      {
        path: 'my-library',
        loadChildren: () => import('@entities/my-library/my-library.module').then(m => m.MyLibraryModule)
      },
      {
        path: 'success-story',
        loadChildren: () => import('@entities/success-story/success-story.module').then(m => m.SuccessStoryModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
