import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActionList, ComponentActions, ComponentActionTyple, ComponentAvailableAction, PracticeProgresses } from '@entities/course-management/course.model';
import { CourseService } from '@entities/course-management/course.service';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { ConfirmationPromptComponent } from '@shared/components/confirmation-prompt/confirmation-prompt.component';
import { ConfirmationPrompt } from '@shared/components/confirmation-prompt/confirmation-prompt.model';
import { RespectRemindersComponent } from '@shared/components/courses/respect-reminders/respect-reminders.component';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AppMessages, APP_ROUTES } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { CourseComponent } from '@shared/models/course-component.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { Schedule, ScheduleProgressUpdateParmas, SessionAction } from './schedule.model';
import { ScheduleService } from './schedule.service';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'app-my-schedules',
  templateUrl: './my-schedules.component.html',
  styleUrls: ['./my-schedules.component.scss']
})
export class MySchedulesComponent extends SflBaseComponent implements OnInit {
  schedules: Schedule[] = [];
  componentId: number;
  sessionActionBtn = 'Start Session';
  showApplyNavigation = false;
  component: CourseComponent;
  highlightId = 0;
  isShowNextPractice = false;
  nextPractice: ActionList;
  previousPractice: ActionList;
  currentPractice: ActionList;
  actionId: number;
  hasRequiredPactice = false;
  componentAvailableActions = ComponentAvailableAction;
  componentApplyActions: ComponentActions[];
  nextButtonText = '';
  currentUser: User;

  constructor(private readonly router: Router,
    private readonly scheduleService: ScheduleService,
    protected readonly layoutUtilsService: LayoutUtilsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdf: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly courseService: CourseService,
    private readonly accountService: MyAccountService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.cloanOnInit();
    this.subscriptionManager.add(this.courseService.reloadConmponent$.subscribe((component: ActionList) => {
      if (component && component.actionType === ComponentActionTyple.M2M) {
        this.cloanOnInit();
        this.courseService.reloadConmponent(null);
      }
    }));
  }

  async cloanOnInit() {
    this.actionId = null;
    this.isShowNextPractice = false;
    this.initializeRouteParamListener();
    this.currentUser = await this.accountService.getCurrentUser();
    await this.getPracticeLists();
    await this.getComponentsApplyActions();
    this.getSchedules();
    this.subscriptionManager.add(this.courseService.getComponent().subscribe(component => {
      this.component = component;
    }));
  }

  initializeRouteParamListener() {
    this.activatedRoute.parent.params.subscribe((params: Params) => {
      this.componentId = params['componentId'];
      if (this.componentId) {
        this.subscriptionManager.add(this.courseService.getComponent().subscribe(component => {
          this.showApplyNavigation = component.progress >= 66;
        }))
        this.getPracticeLists();
      }
    })
  }

  navigateBack() {
    this.router.navigate([this.appRoutes.PRACTICE_WITH_ALLIES], { relativeTo: this.activatedRoute.parent });
  }

  getSchedules() {
    if (this.componentId && this.actionId) {
      this.subscriptionManager.add(this.scheduleService.getSchedules(this.componentId, this.actionId).subscribe(schedules => {
        if (!schedules?.length) {
          this.layoutUtilsService.showActionNotification(AppMessages.noSchedules, AlertType.Info);
          this.router.navigate([this.appRoutes.PRACTICE_WITH_ALLIES], { relativeTo: this.activatedRoute.parent });
        }
        this.schedules = schedules;
        this.checkDocumentId()
        this.loading$.next(false);
        this.cdf.detectChanges();
      }, (err) => this.onError(err)))
    } else {
      this.subscriptionManager.add(this.scheduleService.getSchedules().subscribe(schedules => {
        this.schedules = schedules;
        this.loading$.next(false);
        this.cdf.detectChanges();
      }, (err) => this.onError(err)))
    }

  }

  getCourseName(schedule: Schedule): string {
    return `Component ${schedule?.courseComponentSequence} - ${schedule?.courseComponentName}`;
  }

  startSession(session: Schedule) {
    const sessionId = session?.id;
    if (session?.startedOn) {
      this.navigateToSession(sessionId);
      return;
    }
    const sessionProgressUpdateParams: ScheduleProgressUpdateParmas = {
      sessionAction: SessionAction.START,
      sessionId
    }
    this.subscriptionManager.add(this.scheduleService.updateScheduleProgress(sessionProgressUpdateParams).subscribe(() => {
      this.navigateToSession(sessionId);
    }));
  }

  navigateToSession(sessionId) {
    if (this.componentId) {
      this.router.navigate([`${this.appRoutes.PRACTICE_M2M}`, sessionId], { relativeTo: this.activatedRoute.parent });
    } else {
      this.router.navigate([`${this.appRoutes.COURSE_COMPONENT}`, this.component?.courseComponent?.courseId, this.component?.courseComponent?.id, `${this.appRoutes.PRACTICE_M2M}`, sessionId]);
    }
  }

  async showRespectReminders(session: Schedule, courseComponentId?: number) {
    if (session?.completed || session?.cancelled) {
      return;
    }
    this.isSubmitting = true;
    if (!await this.checkIfUSerIsAllowedToStartSession(session)) {
      if (!this.component) {
        this.subscriptionManager.add(this.courseService.getLearnerCourseComponentById(session?.courseComponentId).subscribe(component => {
          this.component = component;
          this.showSessionPendingToast(session);
          this.isSubmitting = false;
          return;
        }));
      } else {
        this.showSessionPendingToast(session);
        this.isSubmitting = false;
        return;
      }
      return;
    }
    this.isSubmitting = false;
    const data: number = this.componentId ? this.componentId : courseComponentId;
    const dialogRef = this.dialog.open(RespectRemindersComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.startSession(session);
      }
    });
  }

  // returns true if both users are eligible to start session, false if any of them is yet to complete learn
  checkIfUSerIsAllowedToStartSession(session: Schedule): Promise<boolean> {
    return new Promise(resolve => {
      this.subscriptionManager.add(this.scheduleService.isUserAllowedToStartSession(session?.id).subscribe((isAllowed: boolean) => {
        resolve(isAllowed);
      }));
    })
  }

  showSessionPendingToast(session: Schedule) {
    if (this.component?.progress === 0) {
      this.layoutUtilsService.showActionNotification(`Please complete learn section first for Component ${session?.courseComponentSequence} - ${session?.courseComponentName}`, AlertType.Error);
      return;
    } else {
      this.layoutUtilsService.showActionNotification('Your Ally is yet to complete this component and practice with you.', AlertType.Error);
    }
  }

  startBtnClass(schedule: Schedule): string {
    if (schedule.completed) {
      this.sessionActionBtn = 'Completed';
      return 'btn-completed';
    } else if (schedule.cancelled) {
      this.sessionActionBtn = 'Cancelled';
      return 'btn-cancelled';
    } else if (schedule.startedOn) {
      this.sessionActionBtn = 'Join Session';
      return 'btn-progress';
    } else {
      this.sessionActionBtn = 'Start Session';
      return 'btn-save btn-primary';
    }
  }

  navigateToApply() {
    this.courseService.setCurrentPractice(null);
    if (this.componentApplyActions?.length) {
      this.router.navigate([APP_ROUTES.APPLY_CHOOSE_RISK], { relativeTo: this.activatedRoute.parent });
    } else {
      this.router.navigate([APP_ROUTES.RISK_RESULT], { relativeTo: this.activatedRoute.parent });
    }
  }

  cancelSchedule(session: Schedule) {
    const confirmationPrompt: ConfirmationPrompt = {
      body: 'Are you sure you want to cancel this Ally meeting? You won\'t be able to revert this.',
      title: 'Cancel Ally Meeting',
      successTxt: 'Cancel Ally Meeting',
      cancelTxt: 'No',
    }
    const dialogRef = this.dialog.open(ConfirmationPromptComponent, { data: confirmationPrompt });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const sessionProgressUpdateParams: ScheduleProgressUpdateParmas = {
          sessionAction: SessionAction.CANCEL,
          sessionId: session?.id
        }
        this.subscriptionManager.add(this.scheduleService.updateScheduleProgress(sessionProgressUpdateParams).subscribe(() => {
          this.loading$.next(true);
          this.getSchedules();
          this.layoutUtilsService.showActionNotification('Ally Meeting cancelled successfully', AlertType.Success);
        }));
      }
    });
  }

  rescheduleSession(session: Schedule, fromDashboard?: boolean) {
    const confirmationPrompt: ConfirmationPrompt = {
      body: 'Are you sure you want to reschedule this Ally Meeting?',
      title: 'Reschedule Confirmation',
      successTxt: 'Reschedule',
      cancelTxt: 'No',
    }
    const dialogRef = this.dialog.open(ConfirmationPromptComponent, { data: confirmationPrompt });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subscriptionManager.add(this.courseService.getLearnerCourseComponentById(session?.courseComponentId).subscribe(async component => {
          this.courseService.setComponent(component);
          this.component = component;
          this.navigateToNewSchedule(session, session?.participantList[0]?.userId, fromDashboard);
        }));
      }
    });
  }

  navigateToNewSchedule(session: Schedule, userId: number, fromDashboard?: boolean) {
    if (fromDashboard) {
      this.router.navigate([`${this.appRoutes.COURSE_COMPONENT}`, this.component?.courseComponent?.courseId, this.component?.courseComponent?.id, this.appRoutes.NEW_SCHEDULE, userId, session?.id]);
    } else {
      this.router.navigate([this.appRoutes.NEW_SCHEDULE, userId, session?.id], { relativeTo: this.activatedRoute.parent });
    }
  }

  checkDocumentId() {
    const id = localStorage.getItem('m2m_sessionId');
    if (id) {
      this.highlightId = parseInt(id);
      localStorage.removeItem('m2m_sessionId');
      setTimeout(() => {
        this.highlightId = 0;
      }, 10000);
    }
  }

  getPracticeLists(): Promise<void> {
    return new Promise(resolve => {
      if (!this.componentId) {
        resolve();
        return;
      }
      this.subscriptionManager.add(
        this.courseService.getCourseComponentActions(this.componentId, ComponentAvailableAction.PRACTICE).subscribe((componentActions: CourseComponent) => {
          const actions: ComponentActions[] = componentActions?.courseComponent?.componentActions;
          if (actions && actions[0].actionList.length > 1) {
            let count = 0;
            const practiceValue = this.courseService.getCurrentPractice();
            let actionNo: number;
            if (practiceValue) {
              actionNo = parseInt(practiceValue.split('-')[1]);
            }
            for (const [i, v] of actions[0].actionList.entries()) {
              if (v.actionType === ComponentActionTyple.M2M) {
                if ((actionNo && actionNo === v.id) || !v.actionCompleted && count === 0) {
                  this.courseService.setCurrentPractice(`${v.actionType}-${v.id}`);
                  this.previousPractice = i !== 0 ? actions[0].actionList[i - 1] : null;
                  this.nextPractice = i + 1 <= actions[0].actionList.length ? actions[0].actionList[i + 1] : null;
                  this.currentPractice = v;
                  if (this.nextPractice) {
                    this.isShowNextPractice = true;
                  }
                  this.actionId = v.id;
                  count++;
                }
                if (i < (actions[0].actionList.length - 1)) {
                  if (!actions[0].actionList[i + 1].optional) {
                    this.hasRequiredPactice = true;
                  }
                }
              }
            }
            if (!this.actionId) {
              count = 0;
              for (const [i, v] of actions[0].actionList.entries()) {
                if (v.actionType === ComponentActionTyple.M2M) {
                  if (v.actionCompleted && count === 0 && (!this.currentPractice || this.currentPractice.id !== v.id)) {
                    this.courseService.setCurrentPractice(`${v.actionType}-${v.id}`);
                    this.previousPractice = i !== 0 ? actions[0].actionList[i - 1] : null;
                    this.nextPractice = i + 1 <= actions[0].actionList.length ? actions[0].actionList[i + 1] : null;
                    this.currentPractice = v;
                    if (this.nextPractice) {
                      this.isShowNextPractice = true;
                    }
                    this.actionId = v.id;
                    count++;
                  }
                }
              }
            }
          } else {
            this.actionId = actions[0]?.actionList[0].id;
            this.currentPractice = actions[0]?.actionList[0];
          }
          resolve();
        })
      );
    })
  }

  showNextPractice() {
    if (this.currentPractice.optional) {
      this.skipPractice();
    }
    this.redirectToPage(this.nextPractice);
  }

  showPreviousPractice() {
    this.redirectToPage(this.previousPractice);
  }

  redirectToPage(practice: ActionList) {
    this.courseService.setCurrentPractice(`${practice.actionType}-${practice.id}`);
    if (practice.actionType === ComponentActionTyple.M2M) {
      this.cloanOnInit();
    } else if (practice.actionType === ComponentActionTyple.FILLABLEFIELDS) {
      this.router.navigate([this.appRoutes.PRACTICE_FILLABLE], { relativeTo: this.activatedRoute.parent });
    } else if (practice.actionType === ComponentActionTyple.VIDEO) {
      this.router.navigate([this.appRoutes.RECORD_VIDEO], { relativeTo: this.activatedRoute.parent });
    } else if (practice.actionType === ComponentActionTyple.FILEUPLOAD) {
      this.router.navigate([this.appRoutes.UPLOAD_VIDEO], { relativeTo: this.activatedRoute.parent });
    }
  }

  skipPractice() {
    this.updatePracticeProgress();
  }

  updatePracticeProgress() {
    const data: PracticeProgresses = {
      completed: true,
      courseComponentActionId: this.actionId,
      courseComponentId: this.courseService.componentId
    }
    this.subscriptionManager.add(this.courseService.updatePracticeProgresses(data).subscribe(res => {
      if (this.currentPractice) {
        this.currentPractice.actionCompleted = true;
        this.courseService.updatePractice(true);
      }
    }));
  }

  getComponentsApplyActions(): Promise<void> {
    return new Promise((resolve) => {
      if (this.componentId) {
        this.subscriptionManager.add(this.courseService.getCourseComponentActions(this.componentId, this.componentAvailableActions.APPLY).subscribe((actions: CourseComponent) => {
          this.componentApplyActions = actions?.courseComponent?.componentActions;
          this.nextButtonText = this.componentApplyActions?.length ? 'Next: Apply this skill' : 'Next' ;
          resolve();
        }));
      } else {
        resolve();
      }
    });
  }
}
