export class Member {
  public learnerId: number;
  public firstName: string;
  public profileImage?: string;
  public lastName: string;
  public teamId?: number;
  public teamName?: string;
  public groupId?: number;
  public groupName?: string;
  public chatHover?: boolean = false;
  public calenderHover?: boolean = false;
  userId: number;
  public nickName?: string;
  public email?: string;
  public onGoingCourseComponent?: AlliesOnGoingCourse;
  m2MSessionStatus?: M2MSessionStatus;
}

export interface M2MSessionStatus {
  noOfCompletedM2MSessions?: number
  noOfScheduledM2MSessions?: number
  noOfTotalM2MSessions?: number
}

export class AlliesOnGoingCourse {
    courseName?: string;
    courseSequence?: number;
    componentName?: string;
    componentSequence?: number;
}
