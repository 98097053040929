// used to define all the urls for images to be used in the application
export const IMAGE_URLS = {
  eHumanizeLogoURL: "assets/media/logos/eHumanize_Logo.svg",
  eHumanizeLogoWhite: "assets/media/logos/eHumanize_Logo_white.svg",
  eHumanizeLogoSquare: "assets/media/logos/eHumanize_Logo_Square.png",
  thumbsupIconURL: "assets/media/icons/Success.svg",
  greenCheckUrl: "assets/media/icons/Green_Check.svg",
  purpleCheckUrl: "assets/media/icons/Purple_Check.svg",
  profilePicUrl3x: "assets/media/icons/Profile.svg",
  profilePicAvatar: "assets/media/avatars/avatar-icon-male-profile-gray.jpg",
  eHumanizeLogo: "assets/media/logos/eHumanize_Logo.svg",
  dashboard: "assets/media/icons/Dashboard.svg",
  inbox: "assets/media/icons/Inbox.svg",
  library: "assets/media/icons/Library.svg",
  arrowRight: "assets/media/icons/Arrow_Right.svg",
  component: "assets/media/icons/Component.svg",
  calendar: "assets/media/icons/Calendar.svg",
  calendarLight: "assets/media/icons/Calendar_Light.svg",
  quickReply: "assets/media/icons/Quick_Reply.svg",
  checkMark: "assets/media/icons/checkmark.svg",
  arrowNext: "assets/media/icons/Arrow_Next.svg",
  arrowPrevious: "assets/media/icons/Arrow_Prev.svg",
  reviewRetry: "assets/media/icons/Review_Retry.svg",
  chatIcon: "assets/media/icons/Chat.svg",
  chatIconLight: "assets/media/icons/Chat_White.svg",
  myTeam: "assets/media/icons/MyTeam.svg",
  reachoutToAlly: "assets/media/avatars/Ally.png",
  stop: "assets/media/icons/Pause.svg",
  record: "assets/media/icons/Record.svg",
  pause: "assets/media/icons/Pause-1.svg",
  alexProfileImage: "assets/media/avatars/Alex.svg",
  videoIconDark: "assets/media/icons/Video_Dark.svg",
  timeIconDark: "assets/media/icons/Time_Dark.svg",
  userIconDark: "assets/media/icons/User_Dark.svg",
  cancelIcon: "assets/media/icons/Cancel.svg",
  videoIcon: "assets/media/icons/Video.svg",
  fileUpload: "assets/media/icons/File_Upload.svg",
  fileEmpty: "assets/media/icons/File_Empty.svg",
  chatInActiveIcon: "assets/media/icons/Chat_Inactive.svg",
  notificationVideoIcon: "assets/media/icons/Inbox_Video.svg",
  notificationCalendarIcon: "assets/media/icons/Inbox_Calendar.svg",
  notificationTeamIcon: "assets/media/icons/Inbox_Team.svg",
  notificationFeedback: "assets/media/icons/Feedback.svg",
  notificationCoutse: "assets/media/icons/Courses_learn.svg",
  successAward: "assets/media/icons/trophy-award.svg",
  single_golden_egg: "assets/media/images/single_egg.png",
  dual_golden_egg: "assets/media/images/dual_egg.png",
  three_golden_egg: "assets/media/images/three_egg.png",
  empty_egg_nest: "assets/media/images/empty_nest.png",
}
