<div class="d-flex flex-column-fluid flex-center mt-lg-0">
  <div class="container availability-container">
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <h5>My Availability</h5>
          <h6>On this screen you can select blocks of time that are the best times for you to do Member to Member sessions.</h6>
        </div>
        <div class="card-content" *isFetchingData="loading$">
          <form class="basic-container" [formGroup]="myAvailabilityForm">
            <div formArrayName="availabilities">
              <div *ngFor="let avail of availabilitiesFormArr.controls; let i = index">
                <div class="book-availability" [ngClass]="{'unavailable-block': !avail.get('availableDuringThisDayTime')?.value}" [formGroupName]="i">
                  <div formArrayName="days">
                    <div class="header">
                      <span class="title">Select week day(s)</span>
                    </div>
                    <div class="ico-close">
                      <span (click)="deleteAvailability(i)">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      </span>
                    </div>
                    <div class="days-wrapper">
                      <div class="list-days">
                        <ng-container *ngFor="let day of avail.get('availableDays')?.value; let j = index">
                          <input [id]="i+''+j" type="checkbox" [checked]="day?.selected" [value]="day?.day" [name]="'check'+i" class="availability-checkbox"
                            (change)="onSelectingDay($event, j, avail.get('days'), day, 'availableDays')" />
                          <label class="days-of-week" [ngClass]="day?.selected ? 'active' : ''" [for]="i+''+j">{{day?.abbr}}</label>
                        </ng-container>
                      </div>
                      <div class="errors" *ngIf="avail.get('days')?.invalid">
                        <span class="danger">Please select your availability day.</span>
                      </div>
                    </div>
                  </div>
                  <div formArrayName="timeSlots">
                    <div class="time-range">
                      <div class="header">
                        <span class="title">Select time slot: (e.g. 10:00AM to 02:00PM) <button class="btn btn-save btn-all" (click)="addTimeSlotFormGroup(avail.get('timeSlots'))">Add
                            Time Slot</button> </span>
                      </div>
                      <ng-container *ngFor="let availableTimes of avail.get('timeSlots')?.controls; let p = index">
                        <div class="time-picker-wrapper">
                          <div class="time-picker">
                            <div class="counter">{{p+1}}.</div>
                            <div class="from-time">
                              <label>From</label>
                              <ngb-timepicker meridian="on" [minuteStep]="30" size="small" [readonlyInputs]="true" [formControl]="availableTimes?.get('fromTime')"></ngb-timepicker>
                            </div>
                            <div class="to-time">
                              <label>To</label>
                              <ngb-timepicker meridian="on" [minuteStep]="30" size="small" [readonlyInputs]="true" [formControl]="availableTimes?.get('toTime')"></ngb-timepicker>
                            </div>
                            <div class="add-another">
                              <fa-icon class="plus-icon" [icon]="['fas', 'plus']" (click)="addTimeSlotFormGroup(avail.get('timeSlots'))"></fa-icon>
                              <fa-icon class="minus-icon" [icon]="['fas', 'minus']" (click)="deleteTimeSlot(avail.get('timeSlots'), p)"></fa-icon>
                            </div>
                          </div>
                          <div class="errors" *ngIf="availableTimes?.invalid">
                            <span class="danger">Please select valid From and To time.</span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="isAvailable mt-2">
                    <mat-slide-toggle color="primary" [formControl]="avail.get('availableDuringThisDayTime')">{{avail.get('availableDuringThisDayTime')?.value ? 'Preferred' :
                      'Unavailable'}} </mat-slide-toggle>
                    <div>
                      <small>{{avail.get('availableDuringThisDayTime')?.value ? 'Reserved for eHumanize - Please send me a meeting request to block this time on my calendar.'
                        :'Not Available - Please do not let people schedule during these times.'}}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="add-new-availability">
          <button class="btn btn-save btn-all" (click)="addAvailabilities(true)">Add Availability</button>
          <button class="btn btn-save btn-all btn-purple" (click)="addAvailabilities(false)">Add Un-Availability</button>
        </div>
        <div class="actions mt-5" *ngIf="myAvailabilityForm?.get('availabilities').length">
          <button class="btn btn-save btn-all" [disabled]="myAvailabilityForm?.invalid || isSubmitting" [isSubmitting]="isSubmitting" (click)="submit()">Save Your Preferences</button>
        </div>
      </div>
    </div>
  </div>
</div>
