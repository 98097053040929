// Angular
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { User } from '@shared/models/user.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { MyAccountService } from './my-account.service';

@Component({
  selector: 'eh-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyAccountComponent extends SflBaseComponent implements OnInit {
  profilePicForm: FormGroup;
  currentUser: User;
  imageChangedEvent: any = '';
  croppedImage: Blob;
  startImageCropping: boolean;
  fileInput;
  @ViewChild('profilePictureFileInput') profilePictureFileInput: ElementRef;
  constructor(
    private readonly fb: FormBuilder,
    private readonly myAccountService: MyAccountService,
    private readonly chRef: ChangeDetectorRef,
    protected readonly layoutUtilsService: LayoutUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initLoginForm();
    this.getProfile();
    this.subscriptionManager.add(this.myAccountService.getCurrentUserSubject().subscribe(res => {
      this.currentUser = res;
    }));
  }

  isActiveTab(event) {
    return event.isActive;
  }

  initLoginForm() {
    this.profilePicForm = this.fb.group({
      profilePic: new FormControl('')
    });
  }

  getProfile() {
    this.subscriptionManager.add(this.myAccountService.getProfile().subscribe(userData => {
      this.currentUser = userData;
      this.myAccountService.setCurrentUser(userData);
      this.loading$.next(false);
    }, (error) => this.onError(error)));
  }

  onError(error) {
    this.onError(error);
    this.isSubmitting = false;
    this.loading$.next(false);
    this.chRef.detectChanges();
  }

  addProfileImage() {
    this.loading$.next(true);
    var fileName = this.imageChangedEvent.target.files[0].name;
    var file = new File([this.croppedImage], fileName);
    const profilePicForm = new FormData();
    profilePicForm.append('file', file);
    this.subscriptionManager.add(
      this.myAccountService.uploadProfilePic(profilePicForm).subscribe(
        (res) => {
          if (res) {
            this.currentUser.imageUrl = res;
            this.myAccountService.setCurrentUser(this.currentUser);
            this.myAccountService.updateUserOnFirebase(this.currentUser);
            this.loading$.next(false);
            this.resetFileInput();
          }
        }, (error) => this.onError(error)
      )
    );
    this.startImageCropping = false;
  }

  getProfileImage() {
    return (this.currentUser && this.currentUser.imageUrl) ? this.currentUser.imageUrl : this.imagesURLs.profilePicAvatar;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = base64ToFile(event?.base64);
  }

  //desc: Method to upload profile picture
  addUpdateProfileImage(event: any) {
    this.fileInput = event;
    if (event.target.files && event.target.files[0]) {
      this.imageChangedEvent = event;
      this.startImageCropping = true;
    } else {
      this.startImageCropping = false;
    }
  }

  cancelImageUpload(): void {
    this.startImageCropping = false;
    this.resetFileInput();
  }

  // if user uploads same image again the we need to clear the input so the change event won't get confused with same file.
  resetFileInput() {
    this.profilePictureFileInput.nativeElement.value = "";
  }
}
