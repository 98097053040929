/**d
 * @author Ashish Kumar
 */

export const APP_ROUTES = {
  LOGIN: '/auth/login',
  DASHBOARD: '/entities/dashboard',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGOUT: '/logout',
  MY_ACCOUNT: '/my-account',
  EDIT_PROFILE: 'edit-profile',
  PROFILE_QUESTION: 'profile-questions',
  SET_AVAILABILITY: 'availability',
  CHANGE_PASSWORD: 'change-password',
  COURSE_COMPONENT: '/courses',
  COMPONENT_QUIZ: 'quiz',
  LEARN_VIDEO: 'learn',
  PRACTICE_WITH_ALLIES: 'practice-with-allies',
  SCHEDULE: 'schedule',
  PRACTICE_M2M: 'practice',
  PRACTICE_FILLABLE: 'practice-fillable',
  MYFEEDBACK: 'my-feedback',
  RATINGS: 'ratings',
  RECORD_VIDEO: 'record-video',
  REVIEW_FEEDBACK: 'review-feedback',
  GIVE_FEEDBACK: 'give-feedback',
  UPLOAD_VIDEO: 'upload-video',
  APPLY_CHOOSE_RISK: 'choose-risk',
  FEEDBACK_REQUESTS: 'feedback-requests',
  RISK_DETAIL: 'risk-detail',
  CHAT: 'chat',
  RISK_RESULT: 'result',
  NEW_SCHEDULE: 'new-schedule',
  FEEDBACK_STATION: 'feedback-station/courses',
  RISK_STATION: 'risk-station',
  CASH_IN_POINTS: 'cash-in-points',
  INBOX: 'inbox',
  MY_VIDEOS: 'my-videos/courses',
  MY_LIBRARY: 'my-library',
  RISK_DRAFT: 'risk-draft',
  SUCCESS_STORY: 'success-story',
  NEW_SUCCESS_STORY: 'new',
  SHARED_SUCCESS_STORY_WITH_ME: 'shared-with-me',
  MY_STORIES: 'my-stories',
  STORY_SUBMITTED: 'submitted'
};
