<div class="start-course-instaructions">
  <div class="header">
    <h2>Respect Reminders</h2>
    <mat-icon (click)="onClose()">clear</mat-icon>
  </div>
  <hr />
  <div class="content" *isFetchingData="loading$">
    <ng-container *ngFor="let respectReminder of respectReminders">
      <h3 [innerHTML]="respectReminder?.text | safeHTML"></h3>
      <div class="custom-control custom-checkbox cust-checkbox" *ngFor="let reminder of respectReminder?.childList">
        <input type="checkbox" (change)="reminder.isChecked=!reminder?.isChecked" [checked]="reminder?.isChecked" class="custom-control-input" id="{{reminder?.text}}">
        <label class="custom-control-label" for="{{reminder?.text}}" [innerHTML]="reminder?.text | safeHTML"></label>
      </div>
    </ng-container>
  </div>
  <div>
    <div class="footer">
      <button type="button" name="submit" class="btn btn-primary cust-btn-outline" (click)="onclickNext()" [disabled]="!isAllChecked()">I'M ALL IN</button>
    </div>
  </div>
</div>
