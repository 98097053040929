/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Directive, Input, ViewContainerRef, TemplateRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';
import { SflLoadingComponent } from '@shared/components/sfl-loading/sfl-loading.component';

/**
 * @whatitdoes it injects the loader component dynamically based on the api calls.
 * it works on BehaviourSubject
 * @howtouse <div *isFetchingData="loading$"></div>
 */
@Directive({
  selector: '[isFetchingData]'
})
export class SflIsFetchingDirective implements OnDestroy {
  visible = false;
  loadingSubscription: Subscription = new Subscription();

  constructor(private readonly viewContainerRef: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly componentFactoryResolver: ComponentFactoryResolver) { }

  @Input()
  set isFetchingData(isFetching$: BehaviorSubject<boolean>) {
    this.loadingSubscription = isFetching$.subscribe(res => this.toggleLoader(res));
  }

  private toggleLoader(isFetching: boolean) {
    if (isFetching) {
      const loaderComponent = this.componentFactoryResolver.resolveComponentFactory(SflLoadingComponent);
      this.viewContainerRef.createComponent(loaderComponent);
    } else {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.template);
    }
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
