// Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { DashboardService } from '@entities/dashboard/dashboard.service';
import { InboxService } from '@entities/inbox/inbox.service';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { TermsOfUseComponent } from '@shared/components/terms-of-use/terms-of-use.component';
import { Course } from '@shared/models/course.model';
import { User } from '@shared/models/user.model';


@Component({
  selector: 'eh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends SflBaseComponent implements OnInit {
  isCollapsed = true;
  currentUser: User = new User();
  myOngoingCourses: Array<Course>;
  notificationCount: number;
  constructor(private readonly authService: AuthService,
    private readonly accountService: MyAccountService,
    private readonly dashboardService: DashboardService,
    private readonly router: Router,
    private readonly inboxService: InboxService,
    private readonly dialog: MatDialog) {
    super();
  }

  /**
   * On init
   */
  async ngOnInit() {
    this.getUserProfile();
    this.getCurrentUser();
    const user: User = await this.accountService.getCurrentUser()
    this.inboxService.getNotificationCount(user.id);
    this.inboxService.watchNotificationCounts$.subscribe((count: number) => {
      this.notificationCount = count;
    })
  }

  getUserProfile() {
    this.subscriptionManager.add(this.accountService.getProfile().subscribe(res => {
      this.currentUser = res;
      this.accountService.setCurrentUser(res);
    }));
  }

  getCurrentUser() {
    this.subscriptionManager.add(this.accountService.getCurrentUserSubject().subscribe(res => {
      this.currentUser = res;
    }));
  }

  initiateTakeATour() {
    this.collapseMenu();
    this.dashboardService.setWatchTour(true);
    this.router.navigate([this.appRoutes.DASHBOARD]);
  }

  openTermsOfUse() {
    this.collapseMenu();
    const dialogRef = this.dialog.open(TermsOfUseComponent, {disableClose: true });
    dialogRef.afterClosed().subscribe(() => {});
  }

  logout() {
    this.collapseMenu();
    this.authService.logout();
  }

  collapseMenu() {
    this.isCollapsed = true;
  }
}
