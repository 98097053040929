import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidebarParams } from './sidebar.model';

@Injectable({ providedIn: 'root' })
export class SidebarPanelService {
  constructor() { }

  private sidebarTemplateSubject: BehaviorSubject<SidebarParams<any>> = new BehaviorSubject(null);
  sidebarTemplate$: Observable<SidebarParams<any>> = this.sidebarTemplateSubject.asObservable();

  openSidebarTemplate(sidebarParams: SidebarParams<any>) {
    this.sidebarTemplateSubject.next(sidebarParams);
  }
}
