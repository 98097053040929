<div class="d-flex flex-column-fluid flex-center mt-lg-0">
  <div class="login-form login-signin container">
    <div class="eh-login-box card">
      <div class="card-body">
        <form class="form" [formGroup]="editProfileForm" autocomplete="off" novalidate="novalidate" (ngSubmit)="onSubmit()" *isFetchingData="loading$">
          <div class="form-group">
            <label class="form-label">Email</label>
            <input type="text" formControlName="email" class="form-control custom" placeholder="Email" readonly />
          </div>
          <div class="form-group">
            <label class="form-label">First Name</label>
            <input type="text" formControlName="firstname" class="form-control custom" placeholder="Enter first name" required />
            <app-form-error [validation]="'required'" [form]="editProfileForm" [controlName]="'firstname'" [fieldLabel]="'First Name'"></app-form-error>
            <app-form-error [validation]="'minlength'" [form]="editProfileForm" [controlName]="'firstname'" [fieldLabel]="'First Name'"></app-form-error>
            <app-form-error [validation]="'maxlength'" [form]="editProfileForm" [controlName]="'firstname'" [fieldLabel]="'First Name'"></app-form-error>
          </div>
          <div class="form-group">
            <label class="form-label">Last Name</label>
            <input type="text" formControlName="lastname" class="form-control custom" placeholder="Enter last name" />
          </div>
          <div class="form-group">
            <label class="form-label">Name as you’d like it to appear</label>
            <input type="text" formControlName="nickname" class="form-control custom" placeholder="Enter nick name" />
          </div>
          <div class="form-group">
            <label class="form-label">Phone</label>
            <input type="text" id="tel" [mask]="PhoneNumberMask" formControlName="phone" maxlength="14"  class="form-control custom" placeholder="Enter phone number" />
            <app-form-error [validation]="'pattern'" [form]="editProfileForm" [controlName]="'phone'" [fieldLabel]="'Enter Valid Phone Number'"></app-form-error>
          </div>
          <div class="form-group">
            <label class="form-label">Current Employer + Position</label>
            <input type="text" formControlName="position" class="form-control custom" placeholder="Enter position" />
          </div>
          <div class="form-group">
            <label class="form-label">Time Zone</label>
            <mat-select class="form-control custom" name="selected" formControlName="timeZoneId">
              <mat-option *ngFor="let timeZone of availableTimeZones" [value]="timeZone?.id">{{timeZone?.text}}</mat-option>
            </mat-select>
          </div>
          <div class="form-group">
            <div class="float-left profile-ques-hint">
              <p>Now it's time to complete your personalized profile questions. This will help your Allies to reach out to you.</p>
            </div>
            <div class="float-right">
              <button type="submit" name="submit" class="btn btn-primary btn-all cust-btn" [isSubmitting]="isSubmitting">CONTINUE</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
