import { Component, OnInit } from '@angular/core';
import { ChatUser, Conversation } from '@entities/course-management/courses/chat/chat.model';
import { ChatService } from '@entities/course-management/courses/chat/chat.service';
import { DashboardService } from '@entities/dashboard/dashboard.service';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { SidebarTemplate } from '@layout/theme/sidebar-panel/sidebar-options.config';
import { SidebarPanelService } from '@layout/theme/sidebar-panel/sidebar-panel.service';
import { SidebarParams } from '@layout/theme/sidebar-panel/sidebar.model';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { ChatParam } from '@shared/components/sidebar-panel/chat-panel/chat-panel.model';
import { Member } from '@shared/models/member.model';
import { ProfileSidePanel, User } from '@shared/models/user.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'eh-my-community',
  templateUrl: './my-community.component.html',
  styleUrls: ['./my-community.component.scss']
})
export class MyCommunityComponent extends SflBaseComponent implements OnInit {

  currentUserConversations: Conversation[] = [];
  loadingChat$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  currentAppUser: User;
  sidebarParams: SidebarParams<ChatParam>;
  myTeam: Array<Member>;
  myGroup: Array<Member>;
  loadingMyTeam: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loadingMyGroup: BehaviorSubject<boolean> = new BehaviorSubject(true);
  userSidebarParams: SidebarParams<ProfileSidePanel>;

  constructor(private readonly sidebarPanelService: SidebarPanelService,
    private readonly chatService: ChatService,
    private readonly accountService: MyAccountService,
    private readonly dashboardService: DashboardService) {
    super();
  }

  ngOnInit(): void {
    this.sidebarParams = {
      template: SidebarTemplate.CHAT
    }
    this.getCurrentUser();
    this.getMyTeamAndGroup();
  }

  async openChat(conversation: Conversation) {
    this.sidebarParams.data.selectedUserToChat = null;
    this.sidebarParams.data.selectedAlliesToChat = [];
    this.sidebarPanelService.openSidebarTemplate(this.sidebarParams);
    this.chatService.conversation$.next(conversation);
  }

  async getCurrentUser() {
    this.currentAppUser = await this.accountService.getCurrentUser();
    await this.getCurrentUserConversations();
    this.loadingChat$.next(false);
  }

  getMyTeamAndGroup() {
    this.subscriptionManager.add(
      this.dashboardService.getMyGroup().subscribe(res => {
        this.myGroup = res;
        for (const user of this.myGroup) {
          this.chatService.createUser(new ChatUser(this.chatService.getUserFromMember(user)));
        }
        this.loadingMyGroup.next(false);
      }, (error) => {
        this.loadingMyGroup.next(false);
        this.onError(error)
      })
    );
    this.subscriptionManager.add(
      this.dashboardService.getMyTeam().subscribe(res => {
        this.myTeam = res;
        for (const user of this.myTeam) {
          this.chatService.createUser(new ChatUser(this.chatService.getUserFromMember(user)));
        }
        this.loadingMyTeam.next(false);
      }, (error) => { this.loadingMyTeam.next(false); this.onError(error) })
    );
  }

  getCurrentUserConversations(): Promise<void> {
    return new Promise(resolve => {
      this.subscriptionManager.add(this.chatService.getCurrentUserFromFS(this.currentAppUser.id).subscribe(userResponse => {
        this.chatService.currentUser = userResponse[0];
        this.setCurrentConversationsWithSorting(this.chatService.currentUser?.conversations);
        resolve();
      }));
    });
  }

  setCurrentConversationsWithSorting(conversations: Conversation[]) {
    this.currentUserConversations = this.chatService.setCurrentConversationsWithSorting(conversations);
    this.sidebarParams.data = {
      ...this.sidebarParams.data,
      currentAppUser: this.currentAppUser,
      currentUserConversations: this.currentUserConversations
    };
    this.loadingChat$.next(false);
  }

  get unreadMsgCount() {
    let count = 0;
    if (this.currentUserConversations) {
      const unreadChats = this.currentUserConversations.filter(convo => convo.hasUnreadMsg && convo?.lastReceipientMsg?.length);
      count = unreadChats.length;
    }
    return count;
  }

  openUserChat(chatWithUser: Member) {
    this.sidebarParams.data.selectedAlliesToChat = [];
    this.sidebarParams.data.selectedUserToChat = chatWithUser;
    this.sidebarPanelService.openSidebarTemplate(this.sidebarParams);
  }

  viewUserProfile(user: User) {
    this.userSidebarParams = {
      template: SidebarTemplate.USER_PROFILE,
      data: {
        currentAppUser: user
      }
    }
    this.sidebarPanelService.openSidebarTemplate(this.userSidebarParams);
  }

  getInitials(nameString: string): string {
    if (nameString) {
      const fullName = nameString.split(' ');
      let initials = '';
      if (fullName.length > 1) {
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        return initials.toUpperCase();
      }
      initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    }
    return '';
  }

  async onChatImgError(userConversation?: Conversation) {
    if (userConversation) {
      this.accountService.getUserChatImage(userConversation?.id);
    }
  }

  isShowChat(conversations: Conversation[]) {
    if (conversations.length) {
      let hasChat = false;
      for (const i of conversations) {
        if (i.lastReceipientMsg) {
          hasChat = true;
          break;
        }
      }
      return hasChat;
    }
    return false;
  }

  startGroupChat(group: Member[]) {
    this.sidebarParams.data.selectedAlliesToChat = [...group, this.currentMember];
    this.sidebarPanelService.openSidebarTemplate(this.sidebarParams);
  }

  startTeamChat(team: Member[]) {
    this.sidebarParams.data.selectedAlliesToChat = [...team, this.currentMember];
    this.sidebarPanelService.openSidebarTemplate(this.sidebarParams);
  }

  get currentMember(): Member {
    return {
      firstName: this.currentAppUser.firstName,
      lastName: this.currentAppUser.lastName,
      userId: this.currentAppUser.id,
      learnerId: this.currentAppUser.id,
      email: this.currentAppUser.email,
      nickName: this.currentAppUser.nickName
    }
  }
}
