import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { APP_ROUTES } from '@shared/constants';
import { Member } from '@shared/models/member.model';
import { User } from '@shared/models/user.model';
import { Util } from '@shared/services/utils';
import { BehaviorSubject } from 'rxjs';
import { ChatUser, Conversation } from './chat.model';
import { ChatService } from './chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [NgbDropdownConfig]
})
export class ChatComponent extends SflBaseComponent implements OnInit, OnDestroy {
  selectedAllyUser: User = new User();
  currentAppUser: User;

  loadingChatUsers$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  selectedConversation: Conversation;
  currentUserConversations: Conversation[] = [];
  emitRefreshedChat = false;
  addingAllyUserIndex: number;
  finalGroupTeamChatMembers: Member[] = [];

  @Input() selectedUserAlly: Member = new Member();
  @Input() userList:boolean = true;
  @Input() selectedAllies: Member[] = [];
  @Input() showUserList = true;
  @Input() isFromQuickChat = false;

  constructor(private readonly chatService: ChatService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountService: MyAccountService) {
    super();
  }

  toggle() {
    this.userList = !this.userList;
  }

  async ngOnInit() {
    this.currentAppUser = await this.accountService.getCurrentUser();
    const paramsMap = this.activatedRoute.snapshot.paramMap;
    let userId;
    if (paramsMap.has('allyId')) {
      userId = Number(paramsMap.get('allyId'));
    } else if (this.selectedUserAlly && this.selectedUserAlly?.userId) {
      userId = this.selectedUserAlly.userId;
    }
    if (userId) {
      this.selectedAllyUser = await this.accountService.getProfile(userId).toPromise();
      const chatId = this.activatedRoute.snapshot.queryParamMap.get('c');
      await this.getSelectedAllyDetails(chatId);
    } else {
      await this.getSelectedAllyDetails();
    }
  }

  getChatUserFromConverstion(chatConversation: Conversation): ChatUser {
    return {
      id: chatConversation.id,
      email: chatConversation.email,
      firstName: chatConversation.firstName,
      lastName: chatConversation.lastName,
      imageUrl: chatConversation.imageUrl,
      nickName: chatConversation.nickName
    }
  }

  async getSelectedAllyDetails(chatId?: string) {
    this.loading$.next(true);

    if (!this.currentUserConversations?.length) {
      await this.getCurrentUserConversations();
    }
    await this.setCurrentConversation(chatId);
    if (this.selectedAllyUser?.id) {
      await this.chatService.addNewUserToChat(this.currentUserConversations, new ChatUser(this.selectedAllyUser), this.chatService.currentUser);
    }
    this.emitRefreshedChat = true;
    this.loading$.next(false);
    this.chatService.conversation$.next(this.selectedConversation);
  }

  async setCurrentConversation(chatId) {
    if (chatId) {
      this.chatService.chat = {
        chatId: chatId,
        messages: []
      };
      this.selectedConversation = this.selectedConversation || this.currentUserConversations.find(convo => convo.chatId === this.chatService.chat.chatId);
    } else {
      // preselect individual chat only if chatId not present in url params.
      // need to check if it's single chat or group chat request based on the conversation
      let conversation: Conversation;
      if (this.selectedAllies?.length) {
        conversation = this.getGroupTeamChatConversation();
      } else {
        conversation = this.currentUserConversations.find(convo => convo.id === this.selectedAllyUser.id && convo.chatConversation.length < 2);
      }
      this.chatService.chat = {
        chatId: conversation?.chatId,
        messages: []
      };
      this.selectedConversation = conversation;
    }
    if (this.selectedConversation) {
      this.selectedConversation.displayName = this.getChatDisplayName(this.selectedConversation.chatConversation);
    }
  }

  getGroupTeamChatConversation(): Conversation {
    const selectedAllyEmails = this.selectedAllies.map(ally => ally.email).filter(email => email !== this.currentAppUser?.email)?.sort();
    const groupedConversations: Map<string, Conversation[]> = Util.groupBy(this.currentUserConversations, convos => convos?.chatId);
    let selectedConversation: Conversation;
    for (const [chatId, conversation] of groupedConversations.entries()) {
      const converstationUserEmails = conversation[0]?.chatConversation.map(convo => convo.email)?.sort();
      if (Util.arrayEqual(converstationUserEmails, selectedAllyEmails)) {
        selectedConversation = this.currentUserConversations.find(convo => convo.chatId === chatId);
        this.selectedAllies = [];
        break;
      }
    }
    if (!selectedConversation) {
      this.finalGroupTeamChatMembers = this.selectedAllies;
    }
    return selectedConversation;
  }

  getChatDisplayName(conversations): string {
    const groupChats = conversations?.filter(conversation => conversation.chatId === this.chatService.chat.chatId);
    const groupChatUserNames = groupChats?.length ? groupChats?.map(chat => chat.nickName || chat.firstName) : conversations.map(convo => convo.nickName || convo.firstName);
    return groupChatUserNames?.join(', ');
  }

  getCurrentUserConversations(): Promise<void> {
    return new Promise(resolve => {
      this.subscriptionManager.add(this.chatService.getCurrentUserFromFS(this.currentAppUser.id).subscribe(userResponse => {
        this.chatService.currentUser = userResponse[0];
        this.setCurrentConversationsWithSorting(this.chatService.currentUser?.conversations);
        if (this.emitRefreshedChat) {
          this.chatService.conversation$.next(this.selectedConversation);
          this.emitRefreshedChat = false;
        }
        resolve();
      }));
    });
  }

  setCurrentConversationsWithSorting(conversations: Conversation[]) {
    this.currentUserConversations = this.chatService.setCurrentConversationsWithSorting(conversations);
    this.loadingChatUsers$.next(false);
    if (this.chatService?.chat?.chatId) {
      this.selectedConversation = null;
      this.setCurrentConversation(this.chatService.chat.chatId);
    }
  }

  /**
   * add a new user while adding user to chat. check if it exists on firebase, else create it.
   */
  selectConversation(conversation: Conversation) {
    this.chatService.chat.chatId = conversation.chatId;
    this.selectedConversation = conversation;
    this.chatService.conversation$.next(this.selectedConversation);
    this.refreshPage();
  }

  navigateToMySchedules() {
    this.router.navigate([this.appRoutes.SCHEDULE], { relativeTo: this.activatedRoute.parent });
  }

  navigateToPracticeWithAllies() {
    this.router.navigate([this.appRoutes.PRACTICE_WITH_ALLIES], { relativeTo: this.activatedRoute.parent });
  }

  ngOnDestroy() {
    this.chatService.clearData();
    this.currentUserConversations = [];
    this.selectedConversation = null;
    this.subscriptionManager.unsubscribe();
    this.selectedUserAlly = null;
    this.selectedAllies = [];
    this.chatService.conversation$ = new BehaviorSubject(null);
  }

  async refreshPage() {
    if (this.chatService.chat.chatId) {
      this.router.navigate([APP_ROUTES.CHAT, this.selectedConversation?.id || this.selectedAllyUser?.id], { relativeTo: this.activatedRoute.parent, queryParams: { c: this.chatService.chat.chatId } });
    }
  }

  async onRefreshChat() {
    this.emitRefreshedChat = true;
    this.refreshPage();
  }

  async onChatImgError(userConversation?: Conversation) {
    if (userConversation) {
      this.accountService.getUserChatImage(userConversation?.id);
    }
  }

}
