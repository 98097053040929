export enum Practice_Submit_Types {
  RECORD = 'RECORD',
  UPLOAD = 'UPLOAD'
}

export enum Practice_Types {
  MEMBER_TO_MEMBER = 'MEMBER_TO_MEMBER',
  VIDEO_SUBMIT = 'VIDEO_SUBMIT'
}

export enum Practice_Actions {
  MEMBER_TO_MEMBER = 'MEMBER_TO_MEMBER',
  FILLABLE_FIELDS = 'FILLABLE_FIELDS',
  VIDEO_RECORD = 'VIDEO_RECORD',
  FILE_UPLOAD = 'FILE_UPLOAD'
}
