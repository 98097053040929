/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

export class AppConstants {
  public static readonly authenticationToken: string = "authenticationToken";
  public static readonly fcmKey: string = "fcm";
  public static readonly userKey: string = "user";
  public static readonly requiredMinLength: number = 3;
  public static readonly requiredMaxLength: number = 320;
  public static readonly passwordMinLength: number = 6;
  public static readonly passwordMaxLength: number = 50;
  public static readonly pointHoverOnGraph: number = 0.1;
  public static readonly pageSize: number = 10;
  public static readonly number: number = 1000;
  public static readonly minuteNumber: number = 60;
  public static readonly hoursNumber: number = 60;
  public static readonly dayNumber: number = 24;
  public static readonly secondNumber: number = 45;
  public static readonly MonthNumber: number = 30.416;
  public static readonly yearsNumber: number = 365;
  public static readonly duration: number = 7000;
  public static readonly _undoButtonDuration: number = 3000;
  public static readonly animateValue: number = 800;
  public static readonly setTimeOut: 300;
  public static readonly passwordStrengthPattern: string = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=*()!-]).{6,50}$"; //at least one number, uppercase one lowercase and one special character from [@#$%^&+=*()!-]
  public static readonly contactNumberPattern: string = "[0-9]{3}-[0-9]{3}-[0-9]{4}"; //Format: 123-456-7890
  public static readonly componentSteps = {
    LEARN: {
      step: 1,
      display: 'LEARN',
    },
    PRACTICE: {
      step: 2,
      display: 'PRACTICE',
    },
    APPLY: {
      step: 3,
      display: 'APPLY',
    }
  }
  public static readonly scheduleDateFormatWithZoneDateTime = 'MM/DD/yyyy, hh:mm a';
  public static readonly scheduleDateFormatWithTZ = 'MM/DD/yyyy, hh:mm a';
  public static readonly dateFormatWithTZ = 'MM/DD/yyyy';
  public static readonly recordingStatuses = {
    start: 'Start',
    recording: 'Recording',
    pause: 'Paused Recording',
    resume: 'Resume Recording',
    stop: 'Stop Recording',
    complete: 'Recording Completed',
    playing: 'Playing Recording'
  };
  public static readonly NOTIFICATION_SOUND = 'assets/media/audio/call_notification.mp3';
  public static readonly ALLOWED_FILE_TYPES = ['xls', 'xlsx', 'docx', 'doc', 'txt', 'pdf', 'png', 'jpg', 'jpeg', 'eps', 'mp4', 'webm'];
  public static readonly DEFAULT_TIME_ZONE = 'America/New_York';
  public static readonly MOMENT_DATE_FORMAT = 'MM/dd/yyyy';
  public static readonly MOMENT_TIME_FORMAT = 'hh:mm A';
  public static readonly MOMENT_DATE_TIME_FORMAT = 'M/D/YY, hh:mm A';
  public static readonly USER_ONLINE_TIMER = 60000; // 1 minute
  public static readonly END_OF_COMPONENT_MUSIC = 'assets/media/audio/End_of_Component_Music_Take_1.mp3';
  public static readonly HOUR_MINUTES = 'H:mm';
}
