import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDown, faPlus, faMinus, faCheck, faTimes, faArrowRight, faAngleRight, faAngleLeft, faUserFriends, faMicrophoneSlash, faMicrophone, faArrowLeft, faRecordVinyl, faPause, faBookReader, faStop, faPlay, faDownload, faGlobeAmericas, faAngleUp, faCircle, faCheckCircle, faPencilAlt, faInfoCircle, faBalanceScale, faEgg, faFileAlt, faGraduationCap, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar, faClock, faTimesCircle, faComment, faEnvelope, faCalendar, faHourglass, faAddressCard, faPaperPlane, faPlayCircle, faBell, faCommentAlt } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontAwesomeLibModule {
  constructor(readonly faLibrary: FaIconLibrary) {
    faLibrary.addIcons(faTimes, faPlus, faMinus, fasStar, farStar, faCheck, faAngleDown, faArrowRight,
      faAngleRight, faAngleLeft, faClock, faUserFriends, faTimesCircle, faMicrophoneSlash,
      faMicrophone, faArrowLeft, faRecordVinyl, faPause, faBookReader, faStop, faPlay, faDownload,
      faComment, faGlobeAmericas, faAngleUp, faEnvelope, faCalendar, faHourglass, faAddressCard, faPaperPlane, faPlayCircle, faBell, faCommentAlt , faCircle, faCheckCircle, faPencilAlt, faInfoCircle, faBalanceScale, faEgg, faFileAlt, faGraduationCap, faTrophy );
  }
}

