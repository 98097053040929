// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatModule } from '@shared/chat.module';
import { UserProfileSidebarModule } from '@shared/components/user-profile-sidebar/user-profile-sidebar.module';
import { VideoPromptComponent } from '@shared/components/video-prompt/video-prompt.component';
import { FontAwesomeLibModule } from '@shared/fontawesome-lib.module';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { BaseComponent } from './base/base.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarPanelComponent } from './sidebar-panel/sidebar-panel.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';


@NgModule({
  declarations: [
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    VideoPromptComponent,
    SidebarPanelComponent,
    SubHeaderComponent
  ],
  exports: [
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    SubHeaderComponent
  ],
  providers: [
    GuidedTourService
  ],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    FontAwesomeLibModule,
    ChatModule,
    GuidedTourModule,
    MatMenuModule,
    MatCardModule,
    UserProfileSidebarModule
  ]
})
export class ThemeModule {
}
