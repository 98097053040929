import { AppConstants } from "@shared/constants";
import * as moment from 'moment-timezone';

export class Util {

  static GetPeerId(id): string {
    let hostname = window.location.host;
    hostname = hostname.replace(/\:/g, '_');
    hostname = hostname.replace(/\./g, '_');
    return `${hostname}_${id}`
  }

  static groupBy(list, keyGetter) {
    const map = new Map();
    list?.forEach((item) => {
      const key = keyGetter(item);
      const collection = map?.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection?.push(item);
      }
    });
    return map;
  }

  static documentToDomainObject = _ => {
    const object = _.payload.doc.data();
    object.id = _.payload.doc.id;
    return object;
  }

  public static DateInUserZone(date, changeTime?: boolean) {
    let timeZone = localStorage.getItem('timezone');
    timeZone = timeZone || AppConstants.DEFAULT_TIME_ZONE;
    return moment(date).tz(timeZone, changeTime);
  }

  public static arrayEqual(a, b) {
    return (a.length == b.length) && a.every(function (element, index) {
      return element === b[index];
    })
  }

}
