import { Component, Input, OnInit } from '@angular/core';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { SidebarPanelService } from '@layout/theme/sidebar-panel/sidebar-panel.service';
import { User, UserProfile, UserRatings } from '@shared/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

@Component({
  selector: 'app-user-profile-sidebar',
  templateUrl: './user-profile-sidebar.component.html',
  styleUrls: ['./user-profile-sidebar.component.scss']
})
export class UserProfileSidebarComponent extends SflBaseComponent implements OnInit {

  @Input() user: User;
  userProfile: UserProfile;
  userRatings: UserRatings;
  loadingCourseDetail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  activeTab = 1;
  showSkillsRatings = false;
  constructor(private readonly sidebarPanelService: SidebarPanelService, private readonly accountService: MyAccountService) {
    super();
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.subscriptionManager.add(
      this.accountService.getSelectedUserProfile(this.user.userId).subscribe((res: UserProfile) => {
        this.userProfile = res;
        this.loading$.next(false);
      })
    )
    this.getUserRatings();
  }

  getUserRatings() {
    this.loadingCourseDetail$.next(true);
    this.subscriptionManager.add(
      this.accountService.getSelectedUserRatings(this.user.userId).subscribe((res: UserRatings) => {
        this.userRatings = res;
        this.loadingCourseDetail$.next(false);
      })
    );
  }

  sibarPanelClose(data?) {
    this.sidebarPanelService.openSidebarTemplate({ template: null, data });
  }

}
