/**
 * @sunflowerlab
 * @author Prashant Gameti
 */

import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

/**
 * @whatitdoes it adds * for required fields in the form
 * @howtouse  <label class="form-label">Email</label>
 *            <input type="email" class="form-control custom" required placeholder="Enter Your Email" formControlName="email" />
 * @brief     we are using required selector directly to reduce the efforts and we can directly make use of required attribute
 */

@Directive({
  selector: '[required]'
})
export class SflAddAsteriskDirective implements OnInit{

  constructor(
    private readonly renderer: Renderer2,
    private readonly  el: ElementRef
  ) { }

  // Basically in our directive, we need to look up to the parent tag of our selector which is [required],
  // after finding the immediate parent we will find the Label element then append <span class="required-asterisk">*</span>
  ngOnInit() {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    if (parent.getElementsByTagName('LABEL').length && !parent.getElementsByClassName('required-asterisk').length) {
      parent.getElementsByTagName('LABEL')[0].innerHTML += '<span class="required-asterisk">*</span>';
    }
  }

}
