<header>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <a class="navbar-brand" href="javascript:;" routerLink="appRoutes.DASHBOARD"><img alt="Logo" src="{{imagesURLs?.eHumanizeLogo}}"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ehumanizeheader" aria-expanded="false" aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarTogglerDemo02">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="ehumanizeheader" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" (click)="collapseMenu()" [routerLink]="[appRoutes.INBOX]"><fa-icon class="nav-bell" [icon]="['far', 'bell']"></fa-icon>
              <span class="inbox_no" *ngIf="notificationCount > 0">{{notificationCount}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="collapseMenu()" routerLink="appRoutes.DASHBOARD">
              <img alt="Dashboard" src="{{imagesURLs?.dashboard}}">
              <span class="link">Dashboard</span>
            </a>
          </li>
          <li class="nav-item success-story-bar">
            <a class="nav-link trophy" (click)="collapseMenu()" [routerLink]="[appRoutes.SUCCESS_STORY, appRoutes.NEW_SUCCESS_STORY]">
              <img alt="Dashboard" src="{{imagesURLs?.successAward}}">
              <span class="link">Share Your Success - 500 <span class="lower-case">pts.</span></span>
            </a>
          </li>
          <li ngbDropdown class="nav-item nav-avatar">
            <a class="nav-link tour-step-update-proflie" ngbDropdownToggle><img alt="User Profile" [src]="currentUser?.imageUrl" (error)="onImgError($event)" alt="profile">
              <div class="link user-name" id="schedule" data-toggle="tooltip" data-placement="top" [title]="currentUser?.nickName ? currentUser?.nickName : currentUser?.firstName">{{currentUser?.nickName ? currentUser?.nickName : currentUser?.firstName}}</div> <img id="arrow-down" src="assets/media/icons/Arrow_Down.svg" alt=""></a>
            <div ngbDropdownMenu class="dropdown-items">
              <a ngbDropdownItem (click)="collapseMenu()" [routerLink]="[appRoutes.MY_VIDEOS]"><img id="icon" class="iconwidth" src="assets/media/icons/Video.svg" alt=""> My Videos</a>
              <a ngbDropdownItem (click)="collapseMenu()" [routerLink]="[appRoutes.FEEDBACK_STATION]"><img id="icon" src="assets/media/icons/Ratings.svg" alt=""> My Ratings</a>
              <a ngbDropdownItem (click)="collapseMenu()" [routerLink]="[appRoutes.MY_ACCOUNT]"><img id="icon" src="assets/media/icons/Account.svg" alt=""> My Account</a>
              <a ngbDropdownItem (click)="initiateTakeATour()"><fa-icon class="tour-ico" [icon]="['far', 'address-card']"></fa-icon> Take a Tour</a>
              <a ngbDropdownItem (click)="collapseMenu()" [routerLink]="[appRoutes.MY_LIBRARY]"><img id="icon" alt="Library" src="{{imagesURLs?.library}}"> Library</a>
              <a ngbDropdownItem (click)="openTermsOfUse()"><fa-icon class="terms-ico" [icon]="['fas', 'balance-scale']"></fa-icon> Terms of Use</a>
              <a ngbDropdownItem (click)="logout()"><img id="icon" src="assets/media/icons/Quick_Reply.svg" alt=""> Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
