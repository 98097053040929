export const environment = {
  production: false,
  serverUrl: 'https://api.staging.ehumanize.tech/api',
  enableErrorReporting: true,
  sentryKey: 'https://f7a028ec2afd489facbf929b58221bdf@sentry.thesunflowerlab.com/31',
  enableAutoUpdateCheck: true,
  socketUrl: 'wss://api.staging.ehumanize.tech/socket',
  socketLogs: false,
  googleTagManagerCode: 'GTM-M3L3PFC',
  hotjarTrackingCode: 'INSERT_HJ_CODE',
  firebase: {
    apiKey: "AIzaSyB7wbqBECUs6WIYlKIks_t5nB4ZPi0FlOk",
    authDomain: "ehumanize-staging.firebaseapp.com",
    projectId: "ehumanize-staging",
    storageBucket: "ehumanize-staging.appspot.com",
    messagingSenderId: "770224420487",
    appId: "1:770224420487:web:178e2d5dd1d88314346a22",
    measurementId: "G-7TTSHZ1739"
  },
  peerJsConfig: {
    host: 'peerjs.ehumanize.com',
    secure: true,
    port: 443,
    path: 'myapp',
    debug: 3,
    iceTransportPolicy: 'relay',
    config: {
      'iceServers': [
        {
          url: 'stun:turn.ehumanize.com:3478',
          credential: 'ehumanize123',
          username: 'ehumanize'
        },
        {
          url: 'turn:turn.ehumanize.com:3478',
          credential: 'ehumanize123',
          username: 'ehumanize'
        }
      ]
    }
  },
  sequentialComponent: false
};
