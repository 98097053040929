<div class="d-flex flex-column-fluid flex-center mt-lg-0">
  <div class="login-form login-signin container">
    <div class="eh-login-box card">
      <div class="card-body">
        <div class="form-group">
          <label class="form-label">Select Profile Questions:
            <span class="info">
              <fa-icon placement="right" [ngbTooltip]="appMessages.profileQuestionHelpText"
                [icon]="['fas', 'info-circle']"></fa-icon>
            </span>
          </label>
          <span *ngIf="!newSelected?.length && !retrieving" class="helper-text">{{appMessages.profileQuestionHelpText}}</span>
          <mat-select multiple class="form-control custom" name="selected" [(ngModel)]="newSelected" (selectionChange)="prepareProfileQuestions($event.value)">
            <mat-option *ngFor="let question of questions" [value]="question">{{question?.question}}</mat-option>
          </mat-select>
        </div>

        <form #applyRiskForm="ngForm" (ngSubmit)="saveProfileQuestions()" *isFetchingData="loading$">
          <ng-container *ngFor="let selected of preSelected">
            <div *ngFor="let question of getFormArrayByQuestionId(selected?.userProfileQuestionId); trackBy:trackById">
              <div class="form-group fade-profile-question">
                <label class="form-label" for="subquestion1" [innerHTML]="question.get('question')?.value | safeHTML"></label>
                <input type="text" class="form-control custom risk-answer" placeholder="" [formControl]="question.get('answer')" />
              </div>
            </div>
          </ng-container>
          <div class="validation-error" *ngIf="minAnsRequiredError">
            Please answer at least four questions!
          </div>
          <div class="form-group">
            <button type="submit" name="submit" class="btn btn-primary btn-all cust-btn float-right" [disabled]="isSubmitting" [isSubmitting]="isSubmitting">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
