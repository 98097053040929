export interface AvailableTimeZones {
  id: number,
  value: string,
  abbr: string,
  offset: number,
  isdst: boolean,
  text: string,
  utc: string
}

export interface WeekDays {
  abbr: string,
  day: string,
  selected: boolean
}

export class Availability {
  available: boolean;
  day: string;
  timeFrom: string;
  timeTo: string;
  slotGroupId: number;
}

export class AvailabilityForm {
  day: string[];
  timeSlots: TimeSlots[] = [];
  availableDuringThisDayTime: boolean;
  availableDays: WeekDays[];
  slotGroupId: number;
}

export interface TimeSlots {
  fromTime: {
    hour: number,
    minute: number
  },
  toTime: {
    hour: number,
    minute: number
  }
}
