/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppConstants, AppMessages, APP_ROUTES, FormValidationMessages } from '@shared/constants';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormGroup } from '@angular/forms';
import { IMAGE_URLS } from '@shared/constants/image-url.constants';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { Practice_Actions, Practice_Submit_Types, Practice_Types } from '@shared/models/practice-submit-type-enum';
import { CourseComponentSteps } from '@entities/course-management';

@Component({
  selector: 'app-sfl-base',
  template: ''
})
export class SflBaseComponent implements OnDestroy {
  subscriptionManager: Subscription = new Subscription();
  isSubmitting = false;
  appRoutes = APP_ROUTES;
  appMessages = AppMessages;
  formValidationMessages = FormValidationMessages;
  imagesURLs = IMAGE_URLS;
  appConstants = AppConstants;
  practiceSubmitTypes = Practice_Submit_Types;
  practiceActionsTypes = Practice_Actions;
  practiceTypes = Practice_Types;
  @Input() loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  paginationConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    showFirstLastButtons: true
  }
  componentSteps = CourseComponentSteps;
  protected layoutUtilsService: LayoutUtilsService;
  constructor() { }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  /**
 * Checking control validation
 *
 * @param controlName: string => Equals to formControlName
 * @param validationType: string => Equals to valitors name
 */
  isControlHasError(form: FormGroup, controlName: string, validationType: string): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  onError(error) {
    this.loading$.next(false);
    this.isSubmitting = false;
    if (this.layoutUtilsService) {
      if (error && error.error && error.error.message) {
        this.layoutUtilsService.showActionNotification(error.error.message, AlertType.Error);
      }
    }
  }

  onImgError(event) {
    event.target.src = this.imagesURLs?.profilePicAvatar;
  }

  // used to trackby id
  trackById(anArray): number {
    return anArray?.id;
  }

}
