import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { RespectReminderResponse } from '@shared/components/courses/respect-reminders/respect-reminder.model';
import { ApiUrl, AppConstants } from '@shared/constants';
import { BaseCourseComponentDetail, CourseComponent, LearnVideoProgress } from '@shared/models/course-component.model';
import { Member } from '@shared/models/member.model';
import { QuizEnforcementMessage } from '@shared/models/quiz-enforcement-message.model';
import { QuizQuestion } from '@shared/models/quiz-question.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';
import { ActionList, AlliesRequestedFeedback, ComponentResult, FeedbackRequest, GiveVideoFeedback, PracticeProgresses, PracticeVideos, PracticeVideosInsturctions, RequestedFeedbacks, RiskEncouregement, UserProgress } from './course.model';
import { GiveGoldenEggPoints, MyFeedback, MyRespectAndSkillFeedback, SubmitRating } from './courses/ratings/give-feedback-rating.model';
import { UploadProgress } from './courses/upload-video/upload-video.model';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  componentId: number;
  private component$: BehaviorSubject<CourseComponent> = new BehaviorSubject(null);
  private feedbackUpload$: BehaviorSubject<RequestedFeedbacks> = new BehaviorSubject(null);
  private learnVideoCompleted$: Subject<boolean> = new Subject();
  leeanVideoProgress: LearnVideoProgress;
  componentStep = AppConstants.componentSteps.LEARN;
  practice$: BehaviorSubject<string> = new BehaviorSubject(null);
  practiceUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  reloadConmponent$: BehaviorSubject<ActionList> = new BehaviorSubject(null);
  preRiskModalShow = false;

  constructor(private readonly http: HttpClient, private readonly accountService: MyAccountService) { }

  getLearnReEnforcementMessages(componentId): Observable<Array<QuizEnforcementMessage>> {
    return this.http.get<Array<QuizEnforcementMessage>>(ApiUrl.learnReEnforcementMessages.replace(':id', componentId));
  }

  getLearnQuiz(componentId): Observable<Array<QuizQuestion>> {
    return this.http.get<Array<QuizQuestion>>(ApiUrl.quizByComponentId + '/' + componentId);
  }

  getLearnerCourseComponentById(componentId): Observable<CourseComponent> {
    return this.http.get<CourseComponent>(ApiUrl.learnerCourseComponent + '/' + componentId);
  }

  getComponentDetailsById(componentId): Observable<BaseCourseComponentDetail> {
    return this.http.get<BaseCourseComponentDetail>(ApiUrl.courseComponent + '/' + componentId);
  }

  saveLearnerQuiz(quizData): Observable<HttpResponse<string>> {
    return this.http.post<HttpResponse<string>>(ApiUrl.saveLearnerQuiz, quizData);
  }

  updateLearnerVideoProgress(leeanVideoProgress: LearnVideoProgress, learnerComponentId: number): Observable<HttpResponse<string>> {
    return this.http.put<HttpResponse<string>>('/learner-course-components' + '/' + learnerComponentId + ApiUrl.updateVideoProgress, leeanVideoProgress);
  }

  setComponent(component: CourseComponent) {
    this.component$.next(component);
  }

  getComponent(): Observable<CourseComponent> {
    return this.component$.getValue() ? this.component$.asObservable() : of(null);
  }

  getCourseRespectReminders(componentId?: number): Observable<RespectReminderResponse[]> {
    return this.http.get<RespectReminderResponse[]>(`${ApiUrl.respectReminders}${componentId ? `/${componentId}` : ''}`);
  }

  getFeedbackTemplate(componentId: number, actionId: number): Observable<MyFeedback[]> {
    return this.http.get<MyFeedback[]>(`${ApiUrl.feedbackForAllyTemplate}${componentId}/action/${actionId}`);
  }

  submitAllyFeedback(feedback?: SubmitRating[]): Observable<HttpResponse<string>> {
    return this.http.post<HttpResponse<string>>(ApiUrl.submitAllyFeedback, feedback);
  }

  uploadVideo(practiceVideo: FormData, fileUploadIndex: number, fileUploadProgresses: UploadProgress[]): Observable<PracticeVideos> {
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });
    const req = new HttpRequest('POST', ApiUrl.uploadPracticeVideo, practiceVideo, { reportProgress: true, headers });
    return this.http.request<any>(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(event.loaded / event.total * 100);
          fileUploadProgresses[fileUploadIndex].progress$.next(progress);
          fileUploadProgresses[fileUploadIndex].isResolved = false;
        }
        if (event.type === HttpEventType.Response) {
          fileUploadProgresses[fileUploadIndex].isResolved = true;
        }
      }),
      skipWhile(event => !(event instanceof HttpResponse)),
      map(response => response['body'])
    );
  }
  getUploadVideosForComponent(componentId: number, actionId?: number): Observable<PracticeVideos[]> {
    const action = actionId ? `/action/${actionId}` : '';
    return this.http.get<PracticeVideos[]>(`${ApiUrl.getUploadVideosForComponent}${componentId}${action}`);
  }
  getRecordVideoInstructionsForComponent(componentId: number, actionId: number): Observable<PracticeVideosInsturctions[]> {
    return this.http.get<PracticeVideosInsturctions[]>(`${ApiUrl.getUploadVideosInstuctions}${componentId}/action/${actionId}`);
  }
  deleteUploadedVideoByPracticeDocumentId(practiceDocumentId: number): Observable<PracticeVideos[]> {
    return this.http.delete<PracticeVideos[]>(`${ApiUrl.practiceDocumentById}${practiceDocumentId}`);
  }

  getMyAllies(courseId?: number): Observable<Array<Member>> {
    if (courseId) {
      const params = new HttpParams().append('courseId', courseId.toString());
      return this.http.get<Array<Member>>(ApiUrl.myGroup, {params: params});
    }
    return this.http.get<Array<Member>>(ApiUrl.myGroup);
  }

  practiceVideoFeedbackRequest(feedbackRequest?: FeedbackRequest[]): Observable<FeedbackRequest> {
    return this.http.post<FeedbackRequest>(ApiUrl.practiceVideoFeedbackRequest, feedbackRequest);
  }

  getMyRespectAndSkillFeedback(componentId: number, actionId: number, practiceSessionId?: number): Observable<MyRespectAndSkillFeedback> {
    return this.http.get<MyRespectAndSkillFeedback>(`${ApiUrl.getMyRespectAndSkillsFeedback}/${componentId}/action/${actionId}/practice-sessions/${practiceSessionId}/ratings`);
  }

  getUserProgress(learnerComponentId: number): Observable<UserProgress> {
    return this.http.get<UserProgress>(`${ApiUrl.userProgress}/${learnerComponentId}`);
  }

  async setUserProgress(userProgress: UserProgress): Promise<UserProgress> {
    userProgress = {
      ...userProgress,
      userId: (await this.accountService.getCurrentUser()).id
    }
    return this.http.post<UserProgress>(ApiUrl.userProgress, userProgress).toPromise();
  }

  getRequestedFeedbackToMe(componentId: number): Observable<RequestedFeedbacks[]> {
    return this.http.get<RequestedFeedbacks[]>(`${ApiUrl.getRequestedFeedback}/${componentId}`);
  }
  getListOfAlliesRequestedForReview(practiceDocumentId: number): Observable<AlliesRequestedFeedback[]> {
    return this.http.get<AlliesRequestedFeedback[]>(`${ApiUrl.getListOfAlliesRequestedForFeedback}/${practiceDocumentId}`);
  }
  getPracticeDocumentById(practiceDocumentId: number): Observable<PracticeVideos> {
    return this.http.get<PracticeVideos>(`${ApiUrl.practiceDocumentById}${practiceDocumentId}`);
  }

  submitFeedbackRequestRatingFeedback(videoRating, practiceDocumentReviewId: number): Observable<void> {
    return this.http.post<void>(`${ApiUrl.submitRequestedRatingFeedback}/${practiceDocumentReviewId}/submit`, videoRating);
  }

  submitFeedbackRequestCommentFeedback(videoCommentFeedback): Observable<GiveVideoFeedback[]> {
    return this.http.post<GiveVideoFeedback[]>(`${ApiUrl.submitRequestedFeedback}`, videoCommentFeedback);
  }

  setFeedbackUpload(component: RequestedFeedbacks) {
    this.feedbackUpload$.next(component);
  }

  getFeedbackUpload(): Observable<RequestedFeedbacks> {
    return this.feedbackUpload$.asObservable();
  }

  setLearnVideoCompleted(completed: boolean) {
    this.learnVideoCompleted$.next(completed);
  }
  getLearnVideoCompleted(): Observable<boolean> {
    return this.learnVideoCompleted$.asObservable();
  }

  getComponentResult(componentId: number): Observable<ComponentResult> {
    return this.http.get<ComponentResult>(`${ApiUrl.courseComponent}/${componentId}/points-earned`);
  }

  /**
   *
   * @param componentId
   * @param riskType
   * @returns Risk encouregement message of RiskEncouregement type based on the provided risk type in the param. Risk Type can be "PRE_RISK" or "POST_RISK"
   */
  getRiskEncouregementForComponent(componentId: number, riskType: string): Observable<RiskEncouregement[]> {
    return this.http.get<RiskEncouregement[]>(`${ApiUrl.getRiskEncouregementForComponent}/${componentId}/risk-type/${riskType}`);
  }

  getCourseComponentActions(componentId: number, action: string): Observable<CourseComponent> {
    return this.http.get<CourseComponent>(`${ApiUrl.getCourseComponentActions}/${componentId}/action/${action}`);
  }

  setCurrentPractice(practice) {
    this.practice$.next(practice);
  }

  getCurrentPractice() {
    return this.practice$.value;
  }

  updatePracticeProgresses(data: PracticeProgresses) {
    return this.http.post(ApiUrl.learnerPracticeProgresses, data);
  }

  updatePractice(isUpdate: boolean) {
    this.practiceUpdate$.next(isUpdate);
  }

  reloadConmponent(component: ActionList) {
    this.reloadConmponent$.next(component);
  }

  getPreRiskModalStatus() {
    return this.preRiskModalShow;
  }

  setPreRiskModalStatus(isShow = true) {
    this.preRiskModalShow = isShow;
  }

  canUserStartProvidedActionStep(courseId: number, componentId: number, actionName?: string): Observable<boolean> {
    if (actionName) {
      const params = new HttpParams().append('action', actionName);
      return this.http.get<boolean>(`${ApiUrl.canUserStartComponent}/${courseId}/component/${componentId}`, { params: params });
    }
    return this.http.get<boolean>(`${ApiUrl.canUserStartComponent}/${courseId}/component/${componentId}`);
  }

  getUsersNextComponent(courseId: number, sequence: number): Observable<CourseComponent> {
    return this.http.get<CourseComponent>(`${ApiUrl.getNextORPreviousComponentDetails}${courseId}/component-sequence/${sequence}`);
  }

  getCountOfGivenGoldenEggPoints(courseId: number): Observable<number> {
    return this.http.get<number>(`${ApiUrl.getCountOfGivenGoldenEggPoints}${courseId}`);
  }

  giveAllyGoldenEggPoints(givenGoldenPoints: GiveGoldenEggPoints): Observable<GiveGoldenEggPoints> {
    return this.http.post<GiveGoldenEggPoints>(ApiUrl.giveAllyGoldenEggPoints, givenGoldenPoints);
  }
}
