import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment-timezone';
import { AppConstants } from '@shared/constants/app.constant';
import { TimezoneNoticeService } from '@shared/components/timezone-notice/timezone-notice.service';
import { DateToUsersTimezonePipe } from '@shared/pipes/date-to-users-timezone.pipe';
@Pipe({
  name: 'chatdate'
})
export class ChatDatePipe implements PipeTransform {
  constructor(private readonly timeZoneNoticeService: TimezoneNoticeService, private readonly dateToUsersTimeZone: DateToUsersTimezonePipe) {
    this.timeZoneNoticeService.timezoneChanged$.subscribe(res => {
      // localStorage.setItem('timezone', res);
    });
  }
  transform(chatTimeStamp: any): string {
    try {
      const chatDate = chatTimeStamp.toDate();
      if (!moment(chatDate).diff(new Date(), 'days')) {
        // chatDate is today's date, so return only hour and time. Shows: 2:57 PM
        return this.dateToUsersTimeZone.transform(chatDate, AppConstants.MOMENT_TIME_FORMAT);
      }
      // chat was before today, shows: 6/28/21, 01:19 PM
      return this.dateToUsersTimeZone.transform(chatDate, AppConstants.MOMENT_DATE_TIME_FORMAT);
    } catch (e) {
      // this is to avoid time conversion error printing in console.
    }
  }
}
