import { User } from "@shared/models/user.model";

export interface Chat {
  chatId: any,
  messages: Array<Message>
}

export interface Message {
  senderId: number,
  senderName: string,
  senderImageUrl: string,
  content: string,
  timestamp?: Date,
  isRead: boolean
}

export interface UserConvo {
  uid: string,
  name: string,
  chatId: string,
  timestamp?: Date
}

export class ChatUser {
  conversations?: Array<Conversation>;
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string;
  displayChatName?: string;
  nickName?: string;

  constructor(appUser?: User) {
    this.firstName = appUser?.firstName;
    this.lastName = appUser?.lastName;
    this.email = appUser?.email;
    this.imageUrl = appUser?.imageUrl;
    this.id = appUser?.id;
    this.conversations = [];
    this.nickName = appUser?.nickName || '';
  }
}

export interface Conversation {
  chatId: string,
  firstName: string,
  lastName: string,
  email: string,
  imageUrl: string,
  id?: number,
  displayName?: string,
  displayImageUrls?: string[],
  chatConversation?: Conversation[],
  lastUpdatedAt?: any,
  hasUnreadMsg?: boolean,
  lastReceipientMsg?: string
  nickName?: string,
  lastMsgSenderName?: string
}
