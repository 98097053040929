import { Injectable } from '@angular/core';
import { M2MPracticeService } from '@entities/course-management/courses/practice-m2m/m2m-practice.service';
import { environment } from '@environment/environment';
import { ApiUrl } from '@shared/constants';
import { RxStompService } from '@stomp/ng2-stompjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Message } from 'stompjs';

@Injectable({ providedIn: 'root' })
export class SocketUtilsService {
  public socketSubscription: Subscription = new Subscription();
  public m2mFeedbackSubmissionSubscription: Subscription = new Subscription();
  public currentOnlineUsers$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  constructor(private readonly rxStompService: RxStompService,
    private readonly m2mPracticeService: M2MPracticeService) { }

  listenToOtherUsersAvailability() {
    this.socketSubscription = this.rxStompService.watch(ApiUrl.userStatusSocket).subscribe((message: Message) => {
      if (environment.socketLogs) {
        console.log('Socket Data: ', JSON.parse(message.body));
      }
      this.currentOnlineUsers$.next(JSON.parse(message.body));
    });
  }

  listenToM2MFeedbackSubmissionByAlly(sessionId: number, userId: number) {
    this.m2mFeedbackSubmissionSubscription = this.rxStompService.watch(`/practice-session/submit-for/${sessionId}/${userId}`).subscribe((message: Message) => {
      if (environment.socketLogs) {
        console.log('Socket Data: ', JSON.parse(message.body));
      }
      this.m2mPracticeService.setFeedbackSubmittedForInstructionByAlly(JSON.parse(message.body));
    });
  }
}
