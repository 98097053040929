// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SharedModule } from '@shared/shared.module';
import { MyAccountComponent } from './my-account.component';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ThemeModule } from '@layout/theme/theme.module';
import { ProfileQuestionsComponent } from './profile-questions/profile-questions.component';
import { MatSelectModule } from '@angular/material/select';
import { SetAvailabilityComponent } from './set-availability/set-availability.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
@NgModule({
  declarations: [
    MyAccountComponent,
    ChangePasswordComponent,
    EditProfileComponent,
    ProfileQuestionsComponent,
    SetAvailabilityComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyAccountRoutingModule,
    SharedModule,
    RouterModule,
    ImageCropperModule,
    ThemeModule,
    MatSelectModule,
    MatSlideToggleModule
  ],
})
export class MyAccountModule {
}
