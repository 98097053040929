import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MyAccountService } from '@entities/my-account/my-account.service';
import { ApiUrl, AppConstants, APP_ROUTES } from '@shared/constants';
import { MessagingService } from '@shared/services/messaging.service';
import { SocketUtilsService } from '@shared/services/socket-utils.service';
import { UserAvailabilityService } from '@shared/services/user-availability.service';
import { VideoService } from '@shared/services/video/video.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly videoService: VideoService,
    private readonly accountService: MyAccountService,
    private readonly messagingService: MessagingService,
    private readonly userAvailabilityService: UserAvailabilityService,
    private readonly socketUtilService: SocketUtilsService
  ) { }

  // check the user's authentication
  login(credentials): Observable<string> {
    const data = {
      email: credentials.email,
      password: credentials.password
    };
    return this.http
      .post(ApiUrl.login, data)
      .pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp) {
      const bearerToken = resp.id_token;
      if (bearerToken && window.localStorage) {
        this.setAuthTokens(resp);
        this.userAvailabilityService.changeUserAvailabilityStatus();
        return bearerToken;
      }
    }
  }

  async logout() {
    if (localStorage.getItem(AppConstants.fcmKey) && localStorage.getItem(AppConstants.authenticationToken)) {
      await this.messagingService.unregisterToken((await this.accountService.getCurrentUser())?.id).toPromise();
      localStorage.removeItem(AppConstants.fcmKey);
    }
    localStorage.removeItem(AppConstants.authenticationToken);
    this.videoService.loggedOut = true;
    this.videoService.performVideoCleanup({ destroy: true });
    this.accountService.setCurrentUser(null);
    this.userAvailabilityService.refreshUserAvailabilitySubscription.unsubscribe();
    this.userAvailabilityService.refreshUserAvailabilitySubscription = new Subscription();
    this.socketUtilService.socketSubscription.unsubscribe();
    this.router.navigateByUrl(APP_ROUTES.LOGIN);
  }

  // used to set the auth token
  setAuthTokens(user) {
    localStorage.setItem(AppConstants.authenticationToken, user.id_token);
  }

  // used to initiate the forgot password, user will get an email to reset their password
  forgotPasswordInit(email: string) {
    return this.http.post<void>(ApiUrl.forgetPassword.init, { email });
  }

  // takes key provided in email and new password
  forgotPasswordCompletes(key: string, password: string) {
    return this.http.post<void>(ApiUrl.forgetPassword.finish, { key: key, newPassword: password });
  }
}
