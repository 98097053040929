import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { HttpClient } from '@angular/common/http';
import { ApiUrl, AppConstants } from '@shared/constants';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private readonly angularFireDB: AngularFireDatabase,
    private readonly angularFireAuth: AngularFireAuth,
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly http: HttpClient) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data);
        this.setUserToken(userId, token);
      })
  }

  setUserToken(recipientId, token) {
    if (recipientId && token) {
      this.unregisterToken(recipientId).subscribe(() => {
        localStorage.setItem(AppConstants.fcmKey, token);
        this.http.post(`${ApiUrl.firebaseToken}/register`, { recipientId, token }).subscribe();
      })
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
  }

  unregisterToken(recipientId): Observable<{ message: string }> {
    const fcmToken = localStorage.getItem(AppConstants.fcmKey);
    if (fcmToken) {
      return this.http.post<{ message: string }>(`${ApiUrl.firebaseToken}/unregister`, { recipientId, token: fcmToken });
    }
    return of({ message: 'No Token' });
  }
}
