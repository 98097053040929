import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseService } from '@entities/course-management';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { RespectReminderResponse } from './respect-reminder.model';

@Component({
  selector: 'eh-respect-reminders',
  templateUrl: './respect-reminders.component.html',
  styleUrls: ['./respect-reminders.component.scss']
})
export class RespectRemindersComponent extends SflBaseComponent implements OnInit {
  respectReminders: RespectReminderResponse[] = [];
  constructor(
    public readonly dialogRef: MatDialogRef<RespectRemindersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private readonly courseService: CourseService,
    protected readonly layoutUtilsService: LayoutUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCourseRespectReminders();
    this.subscriptionManager.add(
      this.courseService.getLearnerCourseComponentById(this.data).subscribe(async component => {
        //After landing to the component we have component data over here.
        this.courseService.setComponent(component);
      }));
  }

  onclickNext() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close();
  }

  isAllChecked() {
    if (!this.respectReminders) { return }
    for (const respectReminder of this.respectReminders) {
      const unCheckedRespectReminder = respectReminder?.childList?.find(reminder => !reminder.isChecked);
      if (unCheckedRespectReminder) {
        return false;
      }
    }
    return true;
  }

  getCourseRespectReminders() {
    this.subscriptionManager.add(this.courseService.getCourseRespectReminders().subscribe(res => {
      this.respectReminders = res;
      this.loading$.next(false);
    }, (error) => this.onError(error)))
  }

  onError(error) {
    this.onError(error);
    this.loading$.next(false);
  }

}
