<div class="sub-header-wrapper dashboard-bg">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-12 col-sm-12">
        <div class="sub-header-content">
          <div class="dashboard-content">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="wizard">
    <div class="wizard-header">
      <span class="wizard-category" routerLink="./{{appRoutes.EDIT_PROFILE}}" routerLinkActive="active" #EDIT_PROFILE_TAB="routerLinkActive">
        <p>
          <img alt="Profile" src="{{imagesURLs[isActiveTab(EDIT_PROFILE_TAB) ? 'greenCheckUrl' : 'purpleCheckUrl']}}" />
          <span>
            My Profile
          </span>
        </p>
      </span>
      <span class="wizard-category" routerLink="./{{appRoutes.PROFILE_QUESTION}}" routerLinkActive="active" #PROFILE_QUESTION_TAB="routerLinkActive">
        <p>
          <img alt="Profile" src="{{imagesURLs[isActiveTab(PROFILE_QUESTION_TAB) ? 'greenCheckUrl' : 'purpleCheckUrl']}}" />
          <span>
            Profile Questions
          </span>
        </p>
      </span>
      <div class="wizard-category" routerLink="./{{appRoutes.SET_AVAILABILITY}}" routerLinkActive="active" #SET_AVAILABILITY_TAB="routerLinkActive">
        <p>
          <img alt="Password" src="{{imagesURLs[isActiveTab(SET_AVAILABILITY_TAB) ? 'greenCheckUrl' : 'purpleCheckUrl']}}" />
          <span>Availability</span>
        </p>
      </div>
      <div class="wizard-category" routerLink="./{{appRoutes.CHANGE_PASSWORD}}" routerLinkActive="active" #CHANGE_PASSWORD_TAB="routerLinkActive">
        <p>
          <img alt="Password" src="{{imagesURLs[isActiveTab(CHANGE_PASSWORD_TAB) ? 'greenCheckUrl' : 'purpleCheckUrl']}}" />
          <span>Change Password</span>
        </p>
      </div>
    </div>
    <div class="wizard-content">
      <div class="row">
        <div class="col-md-4 t-50">
          <div class="user-picture">
            <div class="profilepic-edit">
              <div class="imagePreview" *isFetchingData="loading$">
                <img [src]="getProfileImage()" (error)="onImgError($event)" alt="user-img" class="" width="150" />
                <a class="placeholder cursor-pointer" tooltip="Update Profile Picture" *ngIf="isActiveTab(EDIT_PROFILE_TAB)" (click)="profilePictureFileInput.click()">
                  <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
                </a>
              </div>
              <input type="file" style="display: none" id="profilePictureFileInput" accept="image/x-png,image/gif,image/jpeg" (change)="addUpdateProfileImage($event)"
                #profilePictureFileInput />
            </div>
            <div class="text-center">
              <small class="text-danger" *ngIf="isActiveTab(EDIT_PROFILE_TAB) && !currentUser?.imageUrl">Uploading your photo will help others connect with you.<br>Please upload a photo now.</small>
            </div>
          </div>

          <div id="myModal" class="crop-modal" *ngIf="startImageCropping">
            <div class="crop-modal-content">
              <div class="crop-modal-header">
                <span class="close" (click)="cancelImageUpload()">&times;</span>
                <h4 class="mt-2">Crop Image & Upload</h4>
              </div>
              <div class="modal-body">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="128" [roundCropper]="true" format="png"
                  (imageCropped)="imageCropped($event)"></image-cropper>
              </div>
              <div class="crop-modal-footer col-12 text-right">
                <button class="btn btn-secondary" (click)="cancelImageUpload()">Cancel</button>
                <button class="btn btn-primary" (click)="addProfileImage()">Upload</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 card-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
