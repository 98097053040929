/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
export class AppMessages {
  public static readonly passwordChange: string = 'You have successfully changed your secret.';
  public static readonly updateProfileSuccess: string = 'Profile has been saved successfully.';
  public static readonly nextButton_finishVideo: string = "Before you start practicing, please watch the complete video.";
  public static readonly nextButtonFinishVideoApply: string = "Before you apply, please watch the complete video.";
  public static readonly watchCompleteLearnVideo: string = "Please watch learn video before you proceed further.";
  public static readonly nextButton_finishQuiz: string = "Before you start practicing, please finish the quiz.";
  public static readonly saveQuiz_giveAllAnswer: string = "Please answer all of the questions";
  public static readonly selectAllReEnforcementMessages: string = "Please check all before proceeding to quiz!";
  public static readonly submitFeedbackWarning: string = "Please provide all the ratings to submit the feedback";
  public static readonly requestReviewFeedbackMaximum: string = "You can send review request up to 4 Allies only!";
  public static readonly requestReviewFeedbackRequire: string = "Please select at least 2 Allies to submit review request!";
  public static readonly videoReviewRequestSuccessful: string = "Your recorded video has been submitted successfully for review!";
  public static readonly documentReviewRequestSuccessful: string = "Your uploaded document has been submitted successfully for review!";
  public static readonly videoAndMicPermissionRequire: string = "Permission Denied. Please provide video and audio permissions";
  public static readonly cameraBusyError: string = "Video isn't working. We couldn't use your webcam for video. Your camera is already in use at other place.";
  public static readonly cameraStreamError: string = "Video isn't working. We couldn't use your webcam for video. Please try again by refreshing/ reloading the page.";
  public static readonly practiceDocumentDeleted: string = "Practice document deleted successfully";
  public static readonly feedbackAlreadySubmittedWarning: string = "You already have submitted your feedback for this schedule!";
  public static readonly preventDeleteOfDocument: string = "Can't delete, you have requested feedback for this video!";
  public static readonly cantDeleteFeedbackRequested: string = "Can't delete, you have requested feedback for this document!";
  public static readonly provideAllTheRatingFeedback: string = "Please provide all the required ratings.";
  public static readonly feedbackSubmittedSuccess: string = "Your feedback has been submitted successfully!";
  public static readonly videoUploadedSuccessfully: string = "Your video has been uploaded successfully. Request feedback now!";
  public static readonly documentUploadedSuccessfully: string = "Your document(s) has been uploaded successfully. Request feedback now!";
  public static readonly fileTypeNotSupported: string = "The file type is not supported!";
  public static readonly requestReviewBeforeApply: string = "Please submit a review request before proceeding to apply!";
  public static readonly requestReviewBeforeNext: string = "Please submit a review request before you proceed further!";
  public static readonly fillAndSubmitBeforeApply: string = "Please fill and submit the form before proceeding to apply!";
  public static readonly fillAndSubmitBeforeNext: string = "Please fill and submit the form before you proceed further!";
  public static readonly typeSomething: string = "Please type something in the comment box!";
  public static readonly componentNotCompleted: string = "The selected component is not yet completed!";
  public static readonly practiceNotCompleted: string = "Practice for selected component is not yet completed!";
  public static readonly noPracticeForComponent: string = "No practice available for this component!";
  public static readonly submitVideoReviewRequestSubTitle: string = "Submit your recorded video to at least 2 Allies to earn 50 points. Earn 50 points each for submitting your video to a 3rd Ally and 4th Ally!";
  public static readonly submitVideoReviewRequestSubTitleOptional: string = "Submit your recorded video to at least 2 Allies to earn 200 points. You can submit your video to maximum 4 Allies!";
  public static readonly submitDocumentReviewRequestSubTitle: string = "Submit your uploaded document to at least 2 Allies to earn 50 points. Earn 50 points each for submitting your document to a 3rd Ally and 4th Ally!";
  public static readonly submitDocumentReviewRequestSubTitleOptional: string = "Submit your uploaded document to at least 2 Allies to earn 200 points. You can submit your document to maximum 4 Allies!";
  public static readonly noFeedbackForComponent: string = "No feedback yet!";
  public static readonly retrievingFeedback: string = "Retrieving Feedback for selected component...";
  public static readonly selectComponentToViewFeedback: string = "Select a component to view your feedback";
  public static readonly noSchedules: string = "You don't have any schedules yet, please schedule a session with your Ally!";
  public static readonly fillablePracticeWarning: string = "Please check validation error(s) and re-submit the form.";
  public static readonly canNotStartLearnYet: string = "You can not start this component until you finish previous components in sequence.";
  public static readonly profileQuestionHelpText: string = "Select minimum four questions you would like to show to your Ally when they see your profile.";
  public static readonly acceptTermsOfUse: string = "Please review and accept Terms of Use before you continue!";
  public static readonly applyDraftSavedSuccess: string = "Your entries have been saved";
  public static readonly applySubmitSuccess: string = "Your risk has been successfully submitted.";
  public static readonly noCourseAvailableToViewThisPage: string = "You have no course available to view this page.";
  public static readonly completePreviousApply: string = "Please complete Apply of previous component before you start Applying for this component.";
  public static readonly previousComponentIsPending: string = "Please complete previous component before you start Applying for this component.";
  public static readonly goldenEggPointsHint: string = `Award your Ally <strong>500 Golden Egg points</strong> if s/he took EXTRA risks today (remember - you have only 3 Golden Eggs to award during this course).`;
  public static readonly noGoldenEggPointsRemain: string = "You have no Golden Egg Points to reward it to your Ally.";
  public static readonly successVideoUploadedSuccessfully: string = "You have successfully submitted your Success!";
  public static readonly passwordSuggestion: string = `
    <ul><li>Password must be 6 to 50 characters long</li>
    <li>Password must have at least one uppercase & one lowercase character</li>
    <li>Password must have at least one number and one special character from @#$%^&+=*()!-</li></ul>`;
    public static readonly itemDonatedSuccessfully: string = "Through risk-taking, you have successfully donated a gift to Hope House!";
    public static readonly availabilitySavedSuccess: string = "Your availability preferences has been saved successfully.";
}

export class FormValidationMessages {
  public static readonly INPUT_REQUIRE: string = 'Please type your answer.';
  public static readonly SELECT_OPTION: string = 'Please select an option.';
  public static readonly SELECT_RELEVANT_OPTIONS: string = 'Please select relevant options.';
  public static readonly SELECT_ALL_OPTION: string = 'Please select all the options.';
}
