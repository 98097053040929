import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionNotificationComponent } from '@shared/components/action-natification/action-notification.component';
import { AppConstants } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';


@Injectable()
export class LayoutUtilsService {
	/**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
  constructor(private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog) { }

	/**
	 * Showing (Mat-Snackbar) Notification
	 *
	 */
  showActionNotification(
    _message: string,
    _type: AlertType = AlertType.Success,
    _duration: number = AppConstants.duration,
    _showCloseButton = true,
    _verticalPosition: 'top' | 'bottom' = 'bottom',
    _horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'right'
  ) {
    const _data = {
      message: _message,
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      verticalPosition: _verticalPosition,
      horizontalPosition: _horizontalPosition,
      type: _type,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition,
      horizontalPosition: _horizontalPosition,
      panelClass: ['alert', this.getActionClass(_type)]
    });
  }


  getActionClass(type: AlertType): string {
    switch (type) {
      case AlertType.Success:
        return 'alert-primary';
      case AlertType.Warning:
        return 'alert-warning';
      case AlertType.Info:
        return 'alert-info';
      case AlertType.Error:
        return 'alert-danger';
      default:
        return 'alert-info';
    }
  }
}
