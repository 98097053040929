import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { SocketUtilsService } from "@shared/services/socket-utils.service";

/**
 * @howtouse <button userAvailability [userId]="userId"></div>
 */

@Directive({
  selector: '[userAvailability]'
})
export class UserAvailabilityDirective implements AfterViewInit {
  onlineUsers: number[] = [];
  @Input() userId: number;
  constructor(private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly socketUtilService: SocketUtilsService) {
  }

  ngAfterViewInit() {
    this.socketUtilService.currentOnlineUsers$.subscribe(users => {
      this.onlineUsers = users;
      this.isAvailable();
    });
  }

  isAvailable() {
    if (this.onlineUsers.includes(this.userId)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'online');
      this.renderer.removeClass(this.elementRef.nativeElement, 'away');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'online');
      this.renderer.addClass(this.elementRef.nativeElement, 'away');
    }
  }

}
