/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
import { CommonModule, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MySchedulesComponent } from '@entities/course-management/courses/my-schedules/my-schedules.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PasswordEyeDirective, SflIsFetchingDirective, SflShowSpinnerDirective } from '@shared/directives';
import { NgxMaskModule } from 'ngx-mask';
import { SwiperModule } from 'swiper/angular';
import { ActionNotificationComponent } from './components/action-natification/action-notification.component';
import { ConfirmationPromptComponent } from "./components/confirmation-prompt/confirmation-prompt.component";
import { CourseObjectivesComponent } from './components/courses/course-objectives/course-objectives.component';
import { MyCommunityComponent } from './components/courses/my-community/my-community.component';
import { OnGoingCourseComponentsComponent } from "./components/courses/my-components/ongoing-course-components.component";
import { MyScheduleComponent } from './components/courses/my-schedule/my-schedule.component';
import { OngoingCoursesComponent } from './components/courses/ongoing-courses/ongoing-courses.component';
import { RespectRemindersComponent } from "./components/courses/respect-reminders/respect-reminders.component";
import { FormErrorComponent } from './components/form-error/form-error.component';
import { SflBaseComponent } from "./components/sfl-base/sfl-base.component";
import { SflLoadingComponent } from './components/sfl-loading/sfl-loading.component';
import { SflSwiperCarouselComponent } from './components/sfl-swiper-carousel/sfl-swiper-carousel.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { TimezoneNoticeComponent } from "./components/timezone-notice/timezone-notice.component";
import { SflAddAsteriskDirective } from './directives/add-asterisk.directive';
import { CommingSoonDirective } from './directives/comming-soon.directive';
import { FileDragNDropDirective } from './directives/file-drag-ndrop.directive';
import { UserAvailabilityDirective } from "./directives/user-availability.directive";
import { FontAwesomeLibModule } from './fontawesome-lib.module';
import { ChatDatePipe } from "./pipes/chat-date.pipe";
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DateToUsersTimezonePipe } from './pipes/date-to-users-timezone.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { InterceptorService } from "./services";
import { LayoutUtilsService } from './services/layout-utils.service';


const COMPONENTS = [
  ActionNotificationComponent,
  OngoingCoursesComponent,
  OnGoingCourseComponentsComponent,
  MyCommunityComponent,
  MyScheduleComponent,
  FormErrorComponent,
  CourseObjectivesComponent,
  RespectRemindersComponent,
  ConfirmationPromptComponent,
  TimezoneNoticeComponent,
  MySchedulesComponent,
  TermsOfUseComponent,
  SflSwiperCarouselComponent
];

const PIPES = [
  DateAgoPipe,
  ChatDatePipe,
  SafeHTMLPipe,
  DateToUsersTimezonePipe
];

const DIRECTIVES = [
  PasswordEyeDirective,
  SflIsFetchingDirective,
  SflShowSpinnerDirective,
  SflAddAsteriskDirective,
  CommingSoonDirective,
  FileDragNDropDirective,
  UserAvailabilityDirective
];

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  FontAwesomeLibModule,
  MatSnackBarModule,
  MatIconModule,
  MatDialogModule,
  NgbModule,
  MatMenuModule,
  MatExpansionModule,
  SwiperModule
];

const SERVICES = [
  LayoutUtilsService
];

@NgModule({
  imports: [FormsModule, NgxMaskModule.forRoot(), ...MODULES],
  exports: [
    FormsModule,
    NgxMaskModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...MODULES,
    ...PIPES
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES, SflBaseComponent, SflLoadingComponent],
  providers: [
    DatePipe,
    DateToUsersTimezonePipe,
    ...SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class SharedModule { }
