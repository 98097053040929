import { Component, OnInit } from '@angular/core';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { SidebarTemplate } from './sidebar-options.config';
import { SidebarPanelService } from './sidebar-panel.service';

@Component({
  selector: 'app-sidebar-panel',
  templateUrl: './sidebar-panel.component.html',
  styleUrls: ['./sidebar-panel.component.scss']
})
export class SidebarPanelComponent extends SflBaseComponent implements OnInit {
  currentSidebarTemplate: SidebarTemplate;
  sidebarTemplateOptions = SidebarTemplate;
  panelData: any;
  constructor(private readonly sidebarPanelService: SidebarPanelService) {
    super();
  }

  ngOnInit(): void {
    this.intializeSidebarParamListener();
  }

  intializeSidebarParamListener() {
    this.subscriptionManager.add(
      this.sidebarPanelService.sidebarTemplate$.subscribe(res => {
        this.currentSidebarTemplate = res?.template;
        this.panelData = res?.data;
      }));
  }

  get showSidePanel(): boolean {
    return this.currentSidebarTemplate === 0 || this.currentSidebarTemplate === 1;
  }

}
