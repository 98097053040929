import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrl } from "@shared/constants";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CourseM2MFeedbackQuestion, CourseM2MFeedbackQuestionAnswer, CourseM2MInstructionCard, FeedbackDetailsAndTypes, FeedbackSubmittedForInstruction } from "./practice-m2m.model";

@Injectable({ providedIn: 'root' })
export class M2MPracticeService {

  private currentInstructionCard$: BehaviorSubject<CourseM2MInstructionCard> = new BehaviorSubject(null);
  currentInstructionCard: Observable<CourseM2MInstructionCard> = this.currentInstructionCard$.asObservable();

  private instructionCards$: BehaviorSubject<CourseM2MInstructionCard[]> = new BehaviorSubject([]);
  instructionCards: Observable<CourseM2MInstructionCard[]> = this.instructionCards$.asObservable();

  private feedbackSubmittedForInstructionByAlly$: Subject<FeedbackSubmittedForInstruction> = new Subject();
  feedbackSubmittedForInstructionByAlly: Observable<FeedbackSubmittedForInstruction> = this.feedbackSubmittedForInstructionByAlly$.asObservable();

  constructor(private readonly http: HttpClient) { }

  getM2MFeedbacks(componentId: number, actionId: number): Observable<CourseM2MFeedbackQuestion[]> {
    return this.http.get<CourseM2MFeedbackQuestion[]>(`${ApiUrl.m2mFeedback}/${componentId}/action/${actionId}`);
  }

  submitM2MFeedbackAnswers(feedbackAnswers: CourseM2MFeedbackQuestionAnswer[]): Observable<CourseM2MFeedbackQuestionAnswer[]> {
    return this.http.post<CourseM2MFeedbackQuestionAnswer[]>(ApiUrl.m2mFeedbackSubmit, feedbackAnswers);
  }

  getM2MInstructionCards(sessionId: number): Observable<CourseM2MInstructionCard[]> {
    return this.http.get<CourseM2MInstructionCard[]>(`${ApiUrl.m2mInstructions}/${sessionId}`);
  }

  setCurrentInstructionCard(instructionCard: CourseM2MInstructionCard) {
    this.currentInstructionCard$.next(instructionCard);
  }

  setInstructionCards(instructionCards: CourseM2MInstructionCard[]) {
    this.instructionCards$.next(instructionCards);
  }

  setFeedbackSubmittedForInstructionByAlly(feedbackSubmittedResponse: FeedbackSubmittedForInstruction) {
    this.feedbackSubmittedForInstructionByAlly$.next(feedbackSubmittedResponse);
  }

  getFeedbackTypesAndDetails(): Observable<FeedbackDetailsAndTypes[]> {
    return this.http.get<FeedbackDetailsAndTypes[]>(ApiUrl.feedbackSubmitTypes);
  }
}
