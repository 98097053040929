// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'eh-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
  
  constructor() {

  }

  /**
   * On init
   */
  ngOnInit(): void {
    
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {

  }

}
